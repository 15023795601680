import { IBaseAction } from '../type';
import { IPagedRequest, IPagedResult } from '../../models';
import { ICoinPackage } from '../../models/coin-package';

import {
  LOAD_COIN_PACKAGES,
  LOAD_COIN_PACKAGES_FAILED,
  SET_COIN_PACKAGES,
} from './actionTypes';

export function setCoinPackages(
  coinPackages: IPagedResult<ICoinPackage>,
): IBaseAction<IPagedResult<ICoinPackage>> {
  return {
    type: SET_COIN_PACKAGES,
    payload: coinPackages,
  };
}

export function loadCoinPackages(
  request: IPagedRequest,
): IBaseAction<IPagedRequest> {
  return {
    type: LOAD_COIN_PACKAGES,
    payload: request,
  };
}

export function loadCoinPackagesFailed(): IBaseAction<any> {
  return {
    type: LOAD_COIN_PACKAGES_FAILED,
    payload: null,
  };
}
