import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// reactstrap
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap';

// Import menuDropdown
import { toast } from 'react-toastify';

import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

// Import Logos
import logo from '../../assets/images/favicon.png';

// Redux Store
import { toggleRightSidebar } from '../../store/actions';
import {
  findCustomers,
  getProfilePictureUrl,
} from '../../services/customer.service';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isMegaMenu: false,
      isProfile: false,
      isSearchModalOpen: false,
      searchTerm: undefined,
      searchResults: [],
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }

  async findCustomer() {
    this.toggleSearch();

    try {
      const customers = await findCustomers(
        this.state.searchTerm,
        false,
        this.props.token,
      );

      this.setState({
        searchResults: customers,
        isSearchModalOpen: true,
      });
    } catch (err) {
      toast.error('Fehler beim Suchen des Kunden.');
    }

    this.toggleSearch();
  }

  goToCustomer(customer) {
    this.props.history.push(`/customers/${customer.UserId}`);
    this.setState({
      searchResults: [],
      searchTerm: '',
      isSearchModalOpen: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isSearchModalOpen}
          toggle={() => this.setState({ isSearchModalOpen: false })}
          centered={true}
          backdrop={true}
        >
          <ModalHeader
            toggle={() => this.setState({ isSearchModalOpen: false })}
          >
            Suchergebnisse
          </ModalHeader>
          <ModalBody>
            {this.state.searchResults.length <= 0
              ? `Keine Kunden mit dem Suchbegriff ${this.state.searchTerm} gefunden`
              : ''}
            {this.state.searchResults.map((res) => (
              <Card
                key={res.UserId}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.goToCustomer(res);
                }}
              >
                <CardBody>
                  <div className={'d-flex flex-wrap flex-fill'}>
                    <div>
                      <img src={getProfilePictureUrl(res.UserId)} width={20} />
                    </div>
                    <div className={'ml-2'}>
                      {res.Username} ({res.Email})
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </ModalBody>
        </Modal>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="20" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="20" />
                  </span>
                </Link>
              </div>

              <Button
                color="none"
                type="button"
                size="sm"
                onClick={this.toggleMenu}
                className="px-3 font-size-24 d-lg-none header-item"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <i className="ri-menu-2-line align-middle"></i>
              </Button>

              <Form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder={'Kunde suchen ...'}
                    value={this.state.searchTerm}
                    onChange={(e) =>
                      this.setState({
                        searchTerm: e.currentTarget.value,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        this.findCustomer(e.currentTarget.value);
                        e.preventDefault();
                        return false;
                      }
                    }}
                  />
                  {this.state.isSearch ? (
                    <span>
                      <Spinner size={'sm'} />
                    </span>
                  ) : (
                    <span className="ri-search-line"></span>
                  )}
                </div>
              </Form>
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <Button
                  color="none"
                  type="button"
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                  className="header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                >
                  <i className="ri-search-line"></i>
                </Button>
                <div
                  className={
                    this.state.isSearch
                      ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
                      : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
                  }
                  aria-labelledby="page-header-search-dropdown"
                  style={{ left: '0 !important', right: '0 !important' }}
                >
                  <Input
                    type="text"
                    className="form-control"
                    placeholder={'Kunde suchen ...'}
                    value={this.state.searchTerm}
                    onChange={(e) =>
                      this.setState({
                        searchTerm: e.currentTarget.value,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        this.findCustomer(e.currentTarget.value);
                        e.preventDefault();
                        return false;
                      }
                    }}
                  />
                </div>
              </div>

              <div className="dropdown d-none d-lg-inline-block ml-1">
                <Button
                  type="button"
                  color="none"
                  onClick={this.toggleFullscreen}
                  className="header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="ri-fullscreen-line"></i>
                </Button>
              </div>

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  const { token } = state.Login;
  return { layoutType, token };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withRouter(Header),
);
