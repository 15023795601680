import React, { useEffect, useState } from 'react';
import { GridApiRef, GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';

import LoadingButton from '../../../components/Common/LoadingButton';
import { createAgency } from '../../../services/agency.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadAgencies } from '../../../store/agency/actions';

interface IAddAgency {
  name: string;
  minMessageCharCount: number | null;
}

export default function AgencyTableToolbar() {
  const token = useAppSelector((state) => state.Login.token);
  const dispatch = useAppDispatch();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const initialValues: IAddAgency = {
    name: '',
    minMessageCharCount: 0,
  };

  const formik = useFormik({
    initialValues,
    onReset: (values) => {
      values.name = '';
      values.minMessageCharCount = null;
    },
    onSubmit: async (values) => {
      await createAgency(values.name, values.minMessageCharCount!, token!);

      dispatch(
        loadAgencies({
          size: 20,
          page: 0,
        }),
      );

      setIsAddModalOpen(false);
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name || values.name === '') {
        errors.name = 'Bitte geben Sie einen Namen ein';
      }

      if (values.minMessageCharCount === null) {
        errors.minMessageCharCount = 'Bitte geben Sie eine Anzahl ein';
      }

      return errors;
    },
  });

  return (
    <>
      <Modal
        isOpen={isAddModalOpen}
        centered={true}
        toggle={() =>
          !isSubmittingForm
            ? setIsAddModalOpen(!isAddModalOpen)
            : setIsAddModalOpen(isAddModalOpen)
        }
      >
        <ModalHeader>
          <h4>Agentur hinzufügen</h4>
        </ModalHeader>
        <ModalBody>
          <FormikProvider value={formik}>
            <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label>Name</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.name && formik.touched.name
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'name'}
                  name={'name'}
                  placeholder={'Name eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className={'invalid-feedback'}>{formik.errors.name}</div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Mindestanzahl Zeichen (pro Nachricht)</Label>
                <input
                  type={'number'}
                  className={`form-control${
                    formik.errors.minMessageCharCount &&
                    formik.touched.minMessageCharCount
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'minMessageCharCount'}
                  name={'minMessageCharCount'}
                  placeholder={'Anzahl eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.minMessageCharCount ?? ''}
                />
                {formik.errors.minMessageCharCount &&
                formik.touched.minMessageCharCount ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.minMessageCharCount}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <LoadingButton
                isLoading={formik.isSubmitting}
                caption={'Hinzufügen'}
                isSubmit={true}
              />
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
      <GridToolbarContainer>
        <div className={'p-2'}>
          <Button color={'primary'} onClick={() => setIsAddModalOpen(true)}>
            Neue Agentur
          </Button>
        </div>
      </GridToolbarContainer>
    </>
  );
}
