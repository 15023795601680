import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';

import { getCustomerVisitedProfiles } from '../../../services/customer.service';
import { useAppSelector } from '../../../store/hooks';
import { ICustomerVisit } from '../../../models/customer';
import CustomerName from '../../../components/Customer/CustomerName';

interface ICustomerVisitedProfilesOptions {
  userId: number | undefined;
}

export default function CustomerVisitedProfiles(
  options: ICustomerVisitedProfilesOptions,
) {
  const [isLoadingVisitedProfiles, setIsLoadingVisitedProfiles] =
    useState(false);
  const [visitedProfiles, setVisitedProfiles] = useState<ICustomerVisit[]>([]);

  const token = useAppSelector((state) => state.Login.token);
  const history = useHistory();

  useEffect(() => {
    if (!options.userId) return;

    setIsLoadingVisitedProfiles(true);

    const cancelToken = axios.CancelToken.source();

    const loadVisitedProfiles = async () => {
      const profiles = await getCustomerVisitedProfiles(
        options.userId!,
        token!,
        cancelToken.token,
      );

      console.log(profiles);
      setVisitedProfiles(profiles);
    };

    loadVisitedProfiles();

    setIsLoadingVisitedProfiles(false);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [options.userId]);

  if (isLoadingVisitedProfiles) {
    return (
      <>
        <Card>
          <CardHeader>Besuchte Profile</CardHeader>
          <CardBody>
            <Spinner />
          </CardBody>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>Letzten 20 besuchten Profile</CardHeader>
        <CardBody>
          <Scrollbars style={{ height: 300 }}>
            {visitedProfiles.length <= 0 ? 'Keine Profile besucht' : ''}
            {visitedProfiles
              ?.filter((x) => visitedProfiles)
              .map((profile) => (
                <Card className={'d-flex flex-fill'} key={profile.UserVisitId}>
                  <CardBody className={'d-flex flex-fill align-items-center'}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(`/customers/${profile.VisitorUserId}`)
                      }
                    >
                      <CustomerName userId={profile.VisitorUserId} />
                    </div>
                    <div className={'ml-auto'}>
                      <Button
                        color={'primary'}
                        onClick={() =>
                          history.push(
                            `/send-message/${profile.UserId}/${profile.VisitorUserId}`,
                          )
                        }
                      >
                        Konversation starten
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              ))}
          </Scrollbars>
        </CardBody>
      </Card>
    </>
  );
}
