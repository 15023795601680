import { IBaseAction } from '../type';

import {
  CLOSE_CONFIRMATION_URL_GENERATOR,
  OPEN_CONFIRMATION_URL_GENERATOR,
} from './actionTypes';

interface IToolsState {
  isConfirmationUrlGeneratorModalOpen: boolean;
}

const initialState: IToolsState = {
  isConfirmationUrlGeneratorModalOpen: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const tools = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case OPEN_CONFIRMATION_URL_GENERATOR:
      state = {
        ...state,
        isConfirmationUrlGeneratorModalOpen: true,
      };
      break;
    case CLOSE_CONFIRMATION_URL_GENERATOR:
      state = {
        ...state,
        isConfirmationUrlGeneratorModalOpen: false,
      };
      break;
    default:
      break;
  }
  return state;
};

export default tools;
