import React, { CSSProperties, useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';

import { IMessage } from '../../../models/message';
import { useAppSelector } from '../../../store/hooks';
import { getPairedMessageFrom } from '../../../services/message.service';

import LatestMessageContent from './LatestMessageContent';

interface ILatestMessageRow {
  message: IMessage;
}

export default function LatestMessageRow(options: ILatestMessageRow) {
  const token = useAppSelector((state) => state.Login.token);
  const [messagePair, setMessagePair] = useState<IMessage | null>();
  const [isLoadingMessagePair, setIsLoadingMessagePair] = useState(false);

  const userBubble = {
    backgroundColor: 'lightblue',
    borderRadius: '20px',
    padding: '1em',
  } as CSSProperties;

  const pairBubble = {
    backgroundColor: 'lightpink',
    borderRadius: '20px',
    padding: '1em',
  } as CSSProperties;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadPair = async (token: string) => {
      setIsLoadingMessagePair(true);
      try {
        const msg = await getPairedMessageFrom(
          options.message.MessageId,
          token,
          cancelToken.token,
        );

        setMessagePair(msg);
        setIsLoadingMessagePair(false);
      } catch (e) {
        console.error(e);
      }
    };

    loadPair(token!);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [options.message]);

  return (
    <div className={'d-flex flex-row flex-wrap justify-content-between mt-2'}>
      <div className={'col-md-6 col-12'}>
        <div style={userBubble}>
          <LatestMessageContent message={options.message} />
        </div>
      </div>
      <div className={'col-md-6 col-12 mt-2 mt-md-0'}>
        <div style={pairBubble}>
          {isLoadingMessagePair ? (
            <Spinner />
          ) : messagePair ? (
            <LatestMessageContent message={messagePair} />
          ) : (
            'keine Nachricht'
          )}
        </div>
      </div>
    </div>
  );
}
