import axios, { CancelToken } from 'axios';

import { IPagedResult } from '../models';
import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IPaymentMethod } from '../models/payment-methods';

export async function getPaymentMethods(
  page: number,
  size: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<IPagedResult<IPaymentMethod>> {
  return (
    await axios.get(
      `${environment.API_URL}/paymentmethods/`,
      getAxiosOptions(token, {
        cancelToken,
        params: {
          page,
          size,
        },
      }),
    )
  ).data;
}

export async function activatePaymentMethod(
  paymentMethodId: number,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/paymentmethods/${paymentMethodId}/activate`,
    {},
    getAxiosOptions(token),
  );
}

export async function deactivatePaymentMethod(
  paymentMethodId: number,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/paymentmethods/${paymentMethodId}/deactivate`,
    {},
    getAxiosOptions(token),
  );
}

export async function updatePaymentMethod(
  paymentMethodId: number,
  name: string,
  description: string,
  token: string,
): Promise<IPaymentMethod> {
  return (
    await axios.put<IPaymentMethod>(
      `${environment.API_URL}/paymentmethods/${paymentMethodId}`,
      {
        name,
        description,
      },
      getAxiosOptions(token),
    )
  ).data;
}
