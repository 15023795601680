import { Button } from 'reactstrap';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store';
import { IPagedCustomerRequest, IPagedResult } from '../../../models';
import { ICustomer } from '../../../models/customer';
import { IBaseAction } from '../../../store/type';
import swissFlag from '../../../assets/images/flags/switzerland.svg';
import germanyFlag from '../../../assets/images/flags/germany.svg';
import austriaFlag from '../../../assets/images/flags/austria.svg';
import { deleteUser, restoreUser } from '../../../services/customer.service';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';

import CustomerSearch from './CustomerSearch';
import CustomerEdit from './CustomerEdit';

interface ICustomerTableOptions {
  loadingSelector: (state: RootState) => boolean;
  customersSelector: (state: RootState) => IPagedResult<ICustomer>;
  loadFunction: IBaseAction<IPagedCustomerRequest>;
}

export default function CustomerTable(options: ICustomerTableOptions) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const history = useHistory();

  const [editCustomer, setEditCustomer] = useState<ICustomer>();

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const loading = useAppSelector(options.loadingSelector);
  const customers = useAppSelector(options.customersSelector);

  const onDeleteCustomer = (customer: ICustomer) => {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Kunde löschen',
      isOpen: true,
      content: `Wollen Sie den Kunden ${customer.Username} wirklich als gelöscht markieren?`,
      confirmCallback: async () => {
        await deleteUser(customer.UserId, token!);
        toast.success('Der Kunde wurde erfolgreich als gelöscht markiert.');
        reload();
      },
    });
  };

  const onRestoreCustomer = (customer: ICustomer) => {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Kunde wiederherstellen',
      isOpen: true,
      content: `Wollen Sie den Kunden ${customer.Username} wirklich wiederherstellen?`,
      confirmCallback: async () => {
        await restoreUser(customer.UserId, token!);
        toast.success('Der Kunde wurde erfolgreich wiederhergestellt.');
        reload();
      },
    });
  };

  const reload = () => {
    dispatch({
      ...options.loadFunction,
      payload: {
        page,
        size: pageSize,
        username: searchTerm,
      },
    });
  };

  useEffect(() => {
    reload();
  }, [dispatch, page, pageSize, searchTerm]);

  const columns: GridColDef[] = [
    {
      field: 'UserId',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'Username',
      headerName: 'Benutzername',
      flex: 1,
    },
    {
      field: 'Firstname',
      headerName: 'Vorname',
      flex: 1,
    },
    {
      field: 'Lastname',
      headerName: 'Nachname',
      flex: 1,
    },
    {
      field: 'Gender',
      headerName: 'Geschlecht',
      width: 150,
      renderCell: (cell) => {
        if (cell.value === 'male') {
          return <i className={'mdi mdi-gender-male'} />;
        }

        return <i className={'mdi mdi-gender-female'} />;
      },
    },
    {
      field: 'City',
      headerName: 'Stadt',
      flex: 1,
    },
    {
      field: 'Country',
      headerName: 'Land',
      width: 110,
      renderCell: (cell) => {
        if (cell.value === 'DE') {
          return (
            <img src={germanyFlag} height={20} alt={'Deutschland Fahne'} />
          );
        }

        if (cell.value === 'AT') {
          return <img src={austriaFlag} height={20} alt={'Österreich Fahne'} />;
        }

        if (cell.value === 'CH') {
          return <img src={swissFlag} height={20} alt={'Schweiz Fahne'} />;
        }
        return <>Unbekannt</>;
      },
    },
    {
      field: 'BirthDate',
      headerName: 'Alter',
      width: 110,
      renderCell: (cell) => {
        const date = moment(cell.value as string);

        return moment().diff(date, 'years');
      },
    },
    {
      field: 'DateCreate',
      headerName: 'Registriert am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'DateChange',
      headerName: 'zuletzt geändert am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (cell) => {
        const customer = cell.row as ICustomer;

        const customerDetailUrl = `/customers/${customer.UserId}`;

        if (customer.IsDeleted) {
          return (
            <div className={'d-flex flex-row'}>
              <Button
                color={'primary'}
                className={'ml-2 btn-sm btn-rounded'}
                title={'Detail'}
                onClick={() => history.push(customerDetailUrl)}
              >
                <i className={'ri-search-line'} />
              </Button>

              <Button
                color={'info'}
                className={'ml-2 btn-sm btn-rounded'}
                title={'Bearbeiten'}
                onClick={() => {
                  setEditCustomer(cell.row as ICustomer);
                }}
              >
                <i className={'ri-edit-2-line'} />
              </Button>

              <Button
                color={'warning'}
                className={'ml-2 btn-sm btn-rounded'}
                title={'Wiederherstellen'}
                onClick={() => onRestoreCustomer(cell.row as ICustomer)}
              >
                <i className={'ri-restart-line'} />
              </Button>
            </div>
          );
        }

        return (
          <div className={'d-flex flex-row'}>
            <Button
              color={'primary'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Detail'}
              onClick={() => history.push(customerDetailUrl)}
            >
              <i className={'ri-search-line'} />
            </Button>

            <Button
              color={'info'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Bearbeiten'}
              onClick={() => {
                setEditCustomer(cell.row as ICustomer);
              }}
            >
              <i className={'ri-edit-2-line'} />
            </Button>

            <Button
              color={'danger'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Löschen'}
              onClick={() => onDeleteCustomer(cell.row as ICustomer)}
            >
              <i className={'ri-delete-bin-2-line'} />
            </Button>
          </div>
        );
      },
    },
  ];

  if (editCustomer) {
    return (
      <CustomerEdit
        customer={editCustomer}
        close={() => {
          setEditCustomer(undefined);
          reload();
        }}
      />
    );
  }

  return (
    <>
      <ConfirmDialog
        title={confirmDialog.title}
        content={confirmDialog.content}
        isOpen={confirmDialog.isOpen}
        close={confirmDialog.close}
        confirmCallback={confirmDialog.confirmCallback}
        cancelCallback={confirmDialog.cancelCallback}
      />
      <CustomerSearch
        onSearch={(searchTerm) => {
          setSearchTerm(searchTerm);
        }}
      />

      <DataGrid
        rows={customers.Data}
        columns={columns}
        pageSize={pageSize}
        page={page}
        pagination={true}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        getRowId={(row) => row.UserId}
        autoHeight={true}
        rowCount={customers.Total}
        paginationMode={'server'}
        disableSelectionOnClick
        loading={loading}
      />
    </>
  );
}
