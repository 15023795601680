import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppDispatch } from '../../store/hooks';
import Box from '../../components/Common/Box';
import {
  loadIncomingMessagesCount,
  loadOutgoingMessagesCount,
  loadRevenue,
} from '../../store/overview/statistic/actions';
import {
  loadActiveDialogs,
  loadFinishedDialogs,
  loadTotalCoins,
} from '../../store/animation/actions';
import ActiveDialogs from '../../components/Dialog/ActiveDialogs';
import DoubleValueBox from '../../components/Common/DoubleValueBox';

export default function AnimationDialog() {
  const [breadcrumbItems] = useState([
    { title: 'Animation', link: '#' },
    { title: 'Dialogübersicht', link: '#' },
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadFinishedDialogs());
    dispatch(loadTotalCoins());
    dispatch(loadIncomingMessagesCount());
    dispatch(loadOutgoingMessagesCount());
    dispatch(loadRevenue());
    dispatch(loadActiveDialogs());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dialogübersicht"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Beendete Dialoge'}
                type={'number'}
                loadingSelector={(state) =>
                  state.Animation.isLoadingFinishedDialogs
                }
                selector={(state) => state.Animation.finishedDialogs}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Coins im Umlauf'}
                type={'number'}
                loadingSelector={(state) => state.Animation.isLoadingTotalCoins}
                selector={(state) => state.Animation.totalCoins}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <DoubleValueBox
                title={'INs / OUTs '}
                selector={(state) => state.Statistic.incomingMessages}
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingIncomingMessages
                }
                selector2={(state) => state.Statistic.outgoingMessages}
                loadingSelector2={(state) =>
                  state.Statistic.loadingOutgoingMessages
                }
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Umsatz heute'}
                type={'currency'}
                loadingSelector={(state) =>
                  state.Statistic.loadingTodaysRevenue
                }
                selector={(state) => state.Statistic.todaysRevenue}
              />
            </div>
          </Row>

          <Row>
            <div className={'col-12'}>
              <ActiveDialogs />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
