import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import SendMassMessage from '../../components/Tools/SendMassMessage';
import LastMassMessages from '../../components/Tools/LastMassMessages';

export default function MassMessage() {
  const [breadcrumbItems] = useState([
    { title: 'Tools', link: '#' },
    { title: 'Massennachricht', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Massennachricht"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-12'}>
              <SendMassMessage />
            </div>
            <div className={'col-12'}>
              <LastMassMessages />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
