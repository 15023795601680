import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

import { useAppSelector } from '../../../store/hooks';
import { getMessagePictureUrl } from '../../../services/message.service';

interface IActiveDialogPictureOptions {
  messageId: number;
}

export default function ActiveDialogPicture(opt: IActiveDialogPictureOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const [pictureUrl, setPictureUrl] = useState<string>();

  useEffect(() => {
    let cancel = false;

    const loadImage = async function () {
      const imageUrl = await getMessagePictureUrl(opt.messageId, token!);

      if (cancel) return;
      setPictureUrl(imageUrl);
    };

    loadImage();

    return () => {
      cancel = true;
    };
  }, [opt.messageId, token]);

  return (
    <>
      {pictureUrl ? (
        <img src={pictureUrl} height={80} alt={'Dialog Bild'} />
      ) : (
        <Spinner size={'sm'} />
      )}
    </>
  );
}
