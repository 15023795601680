export const SET_REVENUE = 'overview/statistic/setRevenue';
export const LOAD_REVENUE = 'overview/statistic/loadRevenue';
export const LOAD_REVENUE_FAILED = 'overview/statistic/loadRevenueFailed';

export const SET_TODAY_SENT_MESSAGES = 'overview/statistic/setTodaySentMessage';
export const LOAD_TODAY_SENT_MESSAGES =
  'overview/statistic/loadTodaySentMessage';
export const LOAD_TODAY_SENT_MESSAGES_FAILED =
  'overview/statistic/loadTodaySentMessageFailed';

export const SET_ONLINE_CUSTOMERS_COUNT =
  'overview/statistic/setOnlineCustomersCount';
export const LOAD_ONLINE_CUSTOMERS_COUNT =
  'overview/statistic/loadOnlineCustomersCount';
export const LOAD_ONLINE_CUSTOMERS_COUNT_FAILED =
  'overview/statistic/loadOnlineCustomersCountFailed';

export const SET_TODAYS_REVENUE = 'overview/statistic/setTodaysRevenue';
export const LOAD_TODAYS_REVENUE = 'overview/statistic/loadTodaysRevenue';
export const LOAD_TODAYS_REVENUE_FAILED =
  'overview/statistic/loadTodayRevenueFailed';

export const SET_INCOMING_MESSAGES = 'overview/statistic/setIncomingMessage';
export const LOAD_INCOMING_MESSAGES = 'overview/statistic/loadIncomingMessages';
export const LOAD_INCOMING_MESSAGES_FAILED =
  'overview/statistic/loadIncomingMessagesFailed';

export const SET_OUTGOING_MESSAGES = 'overview/statistic/setOutgoingMessage';
export const LOAD_OUTGOING_MESSAGES = 'overview/statistic/loadOutgoingMessages';
export const LOAD_OUTGOING_MESSAGES_FAILED =
  'overview/statistic/loadOutgoingMessagesFailed';

export const SET_NEW_CUSTOMERS_COUNT =
  'overview/statistic/setNewCustomersCount';
export const LOAD_NEW_CUSTOMERS_COUNT =
  'overview/statistic/loadNewCustomersCount';
export const LOAD_NEW_CUSTOMERS_COUNT_FAILED =
  'overview/statistic/loadNewCustomersCountFailed';

export const SET_PENDING_ASA_DIALOGS_COUNT =
  'overview/statistic/setPendingASADialogsCount';
export const LOAD_PENDING_ASA_DIALOGS_COUNT =
  'overview/statistic/loadPendingASADialogsCount';
export const LOAD_PENDING_ASA_DIALOGS_COUNT_FAILED =
  'overview/statistic/loadPendingASADialogsCountFailed';

export const SET_PENDING_NEWCUSTOMER_DIALOGS_COUNT =
  'overview/statistic/setPendingNewCustomerDialogsCount';
export const LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT =
  'overview/statistic/loadPendingNewCustomerDialogsCount';
export const LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT_FAILED =
  'overview/statistic/loadPendingNewCustomerDialogsCountFailed';

export const SET_PENDING_NORMAL_DIALOGS_COUNT =
  'overview/statistic/setPendingNormalDialogsCount';
export const LOAD_PENDING_NORMAL_DIALOGS_COUNT =
  'overview/statistic/loadPendingNormalDialogsCount';
export const LOAD_PENDING_NORMAL_DIALOGS_COUNT_FAILED =
  'overview/statistic/loadPendingNormalCountFailed';

export const LOAD_STATISTIC = 'overview/statistic/loadStatistic';
