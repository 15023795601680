import React, { useEffect, useState } from 'react';
import { setIn } from 'formik';

import {
  getInsTodayPerProfile,
  getInsTotalPerProfile,
  getOutsTodayPerProfile,
} from '../../../services/moderator.service';
import { useAppSelector } from '../../../store/hooks';
import {
  getInsTodayPerProfileWithoutModerator,
  getInsTotalPerProfileWithoutModerator,
} from '../../../services/statistic.service';

interface IActiveDialogINOptions {
  ModeratorId: number | null;
  FakeId: number;
  UserId: number;
}

export default function ActiveDialogIN(opt: IActiveDialogINOptions) {
  const token = useAppSelector((state) => state.Login.token);
  const [ins, setIns] = useState({
    total: 0,
    today: 0,
  });

  useEffect(() => {
    let cancel = false;

    const loadInsOuts = async function () {
      let todaysIns = 0;
      let totalIns = 0;

      if (!opt.ModeratorId) {
        todaysIns = await getInsTodayPerProfileWithoutModerator(
          opt.FakeId,
          opt.UserId,
          token!,
        );
        totalIns = await getInsTotalPerProfileWithoutModerator(
          opt.FakeId,
          opt.UserId,
          token!,
        );
      } else {
        todaysIns = await getInsTodayPerProfile(
          opt.ModeratorId,
          opt.FakeId,
          opt.UserId,
          token!,
        );
        totalIns = await getInsTotalPerProfile(
          opt.ModeratorId,
          opt.FakeId,
          opt.UserId,
          token!,
        );
      }

      if (cancel) return;
      setIns({
        today: todaysIns,
        total: totalIns,
      });
    };

    loadInsOuts();

    return () => {
      cancel = true;
    };
  }, []);

  return (
    <>
      {ins.total} ({ins.today})
    </>
  );
}
