import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';

import { useAppSelector } from '../../store/hooks';
import {
  getPresent,
  getPresentPictureUrl,
} from '../../services/present.service';

interface IPresentOptions {
  presentId: number;
  height?: number;
}

export default function PresentPicture(opt: IPresentOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const [presentUrl, setPresentUrl] = useState<string>();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadPresentImage = async function (presentId: number) {
      const imageUrl = await getPresentPictureUrl(
        presentId,
        token!,
        cancelToken.token,
      );

      setPresentUrl(imageUrl);
    };

    loadPresentImage(opt.presentId);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [opt.presentId, token]);

  if (presentUrl) {
    return (
      <img src={presentUrl} height={opt.height ?? 80} alt={'Geschenk Bild'} />
    );
  }

  return <Spinner size={'sm'} />;
}
