import { IBaseAction } from '../type';
import { IAgency } from '../../models/agency';
import { IPagedRequest, IPagedResult } from '../../models';

import {
  LOAD_AGENCIES,
  LOAD_AGENCIES_FAILED,
  SET_AGENCIES,
} from './actionTypes';

export function setAgencies(
  agencies: IPagedResult<IAgency>,
): IBaseAction<IPagedResult<IAgency>> {
  return {
    type: SET_AGENCIES,
    payload: agencies,
  };
}

export function loadAgencies(
  request: IPagedRequest,
): IBaseAction<IPagedRequest> {
  return {
    type: LOAD_AGENCIES,
    payload: request,
  };
}

export function loadAgenciesFailed(): IBaseAction<any> {
  return {
    type: LOAD_AGENCIES_FAILED,
    payload: null,
  };
}
