import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';

import { IMessage } from '../../../models/message';
import { useAppSelector } from '../../../store/hooks';
import { getMessages } from '../../../services/message.service';
import { INotification } from '../../../models/notification';
import { getLatestGreetings } from '../../../services/notification.service';

interface IActiveDialogGreetingsOptions {
  userId: number | undefined;
  fakeId: number | undefined;
}

export default function ActiveDialogGreetings(
  opt: IActiveDialogGreetingsOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoading, setIsLoading] = useState(false);
  const [greetings, setGreetings] = useState<INotification[]>([]);

  useEffect(() => {
    let cancel = false;

    if (!opt.userId || !opt.fakeId) {
      setGreetings([]);
      return;
    }

    const loadGreetings = async () => {
      setIsLoading(true);

      const greetings = await getLatestGreetings(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;

      setGreetings(greetings);
      setIsLoading(false);
    };

    loadGreetings();

    return () => {
      cancel = true;
    };
  }, [opt.userId, opt.fakeId, token]);

  return (
    <LoadingOverlay spinner active={isLoading}>
      <div className={'d-flex flex-column justify-content-stretch mt-2'}>
        {greetings.map((greeting) => (
          <div key={greeting.NotificationId}>
            <div className={'d-flex flex-row mt-2'}>
              <div className={'d-flex flex-column flex-grow-1'}>
                <strong>
                  {greeting.FromUserId === opt.fakeId ? 'Wir' : 'Kunde'}
                </strong>
                <small>{greeting.Message}</small>
              </div>
              <div className={'ml-auto'}>
                <small>
                  {moment(greeting.DateCreate).format(
                    'dddd, DD.MMMM YYYY HH:mm',
                  )}
                </small>
              </div>
            </div>
            <hr className={'w-100'} />
          </div>
        ))}
      </div>
    </LoadingOverlay>
  );
}
