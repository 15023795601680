import { ICustomer, IOnlineCustomer } from '../../models/customer';
import { IBaseAction } from '../type';

import {
  LOAD_ONLINE_CUSTOMERS,
  LOAD_ONLINE_CUSTOMERS_FAILED,
  SET_ONLINE_CUSTOMERS,
} from './actionTypes';

export const setOnlineCustomer = (
  data: IOnlineCustomer[],
): IBaseAction<IOnlineCustomer[]> => ({
  type: SET_ONLINE_CUSTOMERS,
  payload: data,
});

export const loadOnlineCustomer = (): IBaseAction<any> => ({
  type: LOAD_ONLINE_CUSTOMERS,
  payload: null,
});

export const loadOnlineCustomerFailed = (): IBaseAction<any> => ({
  type: LOAD_ONLINE_CUSTOMERS_FAILED,
  payload: null,
});
