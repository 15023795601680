import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppDispatch } from '../../store/hooks';
import { loadPresents } from '../../store/present/actions';

import PresentTable from './components/PresentTable';

export default function PresentOverview() {
  const [breadcrumbItems] = useState([
    { title: 'Geschenke', link: '#' },
    { title: 'Übersicht', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="Geschenke" breadcrumbItems={breadcrumbItems} />

          <Row>
            <div className={'col-12'}>
              <PresentTable />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
