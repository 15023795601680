import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import {
  getActiveDialogs,
  getFinishedDialogsCount,
  getInsToday,
  getNewCustomersToday,
  getOpenDialogs,
  getPendingDialogsCount,
  getTotalCoins,
} from '../../services/animation.service';
import { IActiveDialog } from '../../models/animation';

import {
  loadActiveDialogsFailed,
  loadFinishedDialogsFailed,
  loadInsTodayFailed,
  loadNewCustomersTodayFailed,
  loadOpenDialogsFailed,
  loadPendingDialogsFailed,
  loadTotalCoinsFailed,
  setActiveDialogs,
  setFinishedDialogs,
  setInsToday,
  setNewCustomersToday,
  setOpenDialogs,
  setPendingDialogs,
  setTotalCoins,
} from './actions';
import {
  LOAD_ACTIVE_DIALOGS,
  LOAD_FINISHED_DIALOGS,
  LOAD_INS_TODAY,
  LOAD_NEW_CUSTOMER_TODAY,
  LOAD_OPEN_DIALOGS,
  LOAD_PENDING_DIALOGS,
  LOAD_TOTAL_COINS,
} from './actionTypes';

function* loadFinishedDialogs(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getFinishedDialogsCount, token);

    yield put(setFinishedDialogs(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadFinishedDialogsFailed());
  }
}

function* loadPendingDialogs(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getPendingDialogsCount, token);

    yield put(setPendingDialogs(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadPendingDialogsFailed());
  }
}

function* loadTotalCoins(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getTotalCoins, token);

    yield put(setTotalCoins(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadTotalCoinsFailed());
  }
}

function* loadInsToday(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getInsToday, token);

    yield put(setInsToday(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadInsTodayFailed());
  }
}

function* loadActiveDialogs(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IActiveDialog[] = yield call(getActiveDialogs, token);

    yield put(setActiveDialogs(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadActiveDialogsFailed());
  }
}

function* loadOpenDialogs(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IActiveDialog[] = yield call(getOpenDialogs, token);

    yield put(setOpenDialogs(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadOpenDialogsFailed());
  }
}

function* loadNewCustomersToday(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getNewCustomersToday, token);

    yield put(setNewCustomersToday(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadNewCustomersTodayFailed());
  }
}

export function* watchLoadFinishedDialogs() {
  yield takeEvery<IBaseAction<any>>(LOAD_FINISHED_DIALOGS, loadFinishedDialogs);
}

export function* watchLoadUnfinishedDialogs() {
  yield takeEvery<IBaseAction<any>>(LOAD_PENDING_DIALOGS, loadPendingDialogs);
}

export function* watchLoadTotalCoins() {
  yield takeEvery<IBaseAction<any>>(LOAD_TOTAL_COINS, loadTotalCoins);
}

export function* watchLoadInsToday() {
  yield takeEvery<IBaseAction<any>>(LOAD_INS_TODAY, loadInsToday);
}

export function* watchLoadNewCustomersToday() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_NEW_CUSTOMER_TODAY,
    loadNewCustomersToday,
  );
}

export function* watchLoadActiveDialogs() {
  yield takeEvery<IBaseAction<any>>(LOAD_ACTIVE_DIALOGS, loadActiveDialogs);
}

export function* watchLoadOpenDialogs() {
  yield takeEvery<IBaseAction<any>>(LOAD_OPEN_DIALOGS, loadOpenDialogs);
}

function* animationSaga() {
  yield all([
    fork(watchLoadFinishedDialogs),
    fork(watchLoadUnfinishedDialogs),
    fork(watchLoadTotalCoins),
    fork(watchLoadInsToday),
    fork(watchLoadNewCustomersToday),
    fork(watchLoadActiveDialogs),
    fork(watchLoadOpenDialogs),
  ]);
}

export default animationSaga;
