import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';

import { useAppSelector } from '../../store/hooks';
import { getCoinPackagePictureUrl } from '../../services/coin-package.service';

interface ICoinPackageOptions {
  coinPackageId: number;
}

export default function CoinPackageImage(opt: ICoinPackageOptions) {
  const token = useAppSelector((state) => state.Login.token);
  const [coinPackageImageUrl, setCoinPackageImageUrl] = useState<string>();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadCoinPackageImage = async function (
      coinPackageId: number,
      token: string,
    ) {
      try {
        const imageUrl = await getCoinPackagePictureUrl(
          coinPackageId,
          token,
          cancelToken.token,
        );

        setCoinPackageImageUrl(imageUrl);
      } catch (e) {}
    };

    loadCoinPackageImage(opt.coinPackageId, token!);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  if (!coinPackageImageUrl) {
    return <Spinner size={'sm'} />;
  }
  return <img src={coinPackageImageUrl} width={50} />;
}
