import { IBaseAction } from '../type';
import { IPagedRequest, IPagedResult } from '../../models';
import { ISupport } from '../../models/support';

import { LOAD_TICKETS, LOAD_TICKETS_FAILED, SET_TICKETS } from './actionTypes';

export function setTickets(
  tickets: IPagedResult<ISupport>,
): IBaseAction<IPagedResult<ISupport>> {
  return {
    type: SET_TICKETS,
    payload: tickets,
  };
}

export function loadTickets(
  request: IPagedRequest,
): IBaseAction<IPagedRequest> {
  return {
    type: LOAD_TICKETS,
    payload: request,
  };
}

export function loadTicketsFailed(): IBaseAction<any> {
  return {
    type: LOAD_TICKETS_FAILED,
    payload: null,
  };
}
