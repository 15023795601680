import { IBaseAction } from '../type';

import {
  CLOSE_CONFIRMATION_URL_GENERATOR,
  OPEN_CONFIRMATION_URL_GENERATOR,
} from './actionTypes';

export function openConfirmationUrlGenerator(): IBaseAction<any> {
  return {
    type: OPEN_CONFIRMATION_URL_GENERATOR,
    payload: null,
  };
}

export function closeConfirmationUrlGenerator(): IBaseAction<any> {
  return {
    type: CLOSE_CONFIRMATION_URL_GENERATOR,
    payload: null,
  };
}
