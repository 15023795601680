import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import axios, { CancelToken } from 'axios';
import { useDropzone } from 'react-dropzone';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ICustomerPicture } from '../../models/customer';
import {
  getCustomerPictures,
  uploadUserPicture,
} from '../../services/customer.service';
import { useAppSelector } from '../../store/hooks';

import CustomerPictures from './components/CustomerPictures';
import CustomerPictureUpload from './components/CustomerPictureUpload';

type CustomerEditPicturesParams = {
  userId?: string;
};

interface IImagePreview {
  name: string;
  url: string;
  formattedSize: string;
}

export default function CustomerEditPictures() {
  const { userId: userIdStr } = useParams<CustomerEditPicturesParams>();

  const userId = useMemo(() => parseInt(userIdStr!), [userIdStr]);

  const [breadcrumbItems] = useState([
    { title: 'Kunde', link: '#' },
    { title: 'Bilder', link: '#' },
  ]);

  const [reloadPictures, setReloadPictures] = useState(false);

  const onUploadFinished = () => {
    setReloadPictures(!reloadPictures);
  };

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bilder" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col sm={12}>
              {userId ? (
                <CustomerPictures
                  userId={userId}
                  toggleReload={reloadPictures}
                  hideEdit={true}
                />
              ) : null}
            </Col>
            <Col sm={12}>
              <CustomerPictureUpload
                userId={userId}
                onUploadFinished={onUploadFinished}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
