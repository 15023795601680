import { ICustomer, IOnlineCustomer } from '../../models/customer';
import { IBaseAction } from '../type';

import {
  LOAD_ONLINE_CUSTOMERS,
  LOAD_ONLINE_CUSTOMERS_FAILED,
  SET_ONLINE_CUSTOMERS,
} from './actionTypes';

interface IOnlineCustomerState {
  customer: IOnlineCustomer[];
  isLoadingCustomer: boolean;
}

const initialState: IOnlineCustomerState = {
  customer: [],
  isLoadingCustomer: false,
};

const OnlineCustomer = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_ONLINE_CUSTOMERS:
      state = {
        ...state,
        customer: action.payload,
        isLoadingCustomer: false,
      };
      break;
    case LOAD_ONLINE_CUSTOMERS:
      state = {
        ...state,
        isLoadingCustomer: true,
      };
      break;
    case LOAD_ONLINE_CUSTOMERS_FAILED:
      state = {
        ...state,
        isLoadingCustomer: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default OnlineCustomer;
