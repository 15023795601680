import React, { useState } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import LoadingButton from '../../../components/Common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createPresent } from '../../../services/present.service';
import { loadPresents } from '../../../store/present/actions';

interface IAddPresent {
  name: string;
  description: string;
  isPremium: boolean;
  costs: number;
}

export default function PresentTableToolbar() {
  const token = useAppSelector((state) => state.Login.token);

  const dispatch = useAppDispatch();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [addPresentImage, setAddPresentImage] = useState<File>();

  const initialValues: IAddPresent = {
    name: '',
    description: '',
    isPremium: false,
    costs: 0,
  };

  const formik = useFormik({
    initialValues,
    onReset: (values) => {
      values.name = initialValues.name;
      values.description = initialValues.description;
      values.isPremium = initialValues.isPremium;
      values.costs = initialValues.costs;
    },
    onSubmit: async (values) => {
      if (!addPresentImage) return;

      await createPresent(
        values.name,
        values.description,
        addPresentImage,
        values.costs,
        values.isPremium,
        token!,
      );

      toast.success('Das Geschenk wurde erfolgreich hinzugefügt.');
      dispatch(
        loadPresents({
          size: 10,
          page: 0,
          searchTerm: '',
        }),
      );

      setIsAddModalOpen(false);
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name || values.name === '') {
        errors.name = 'Bitte geben Sie einen Namen ein';
      }

      if (!values.description || values.description === '') {
        errors.description = 'Bitte geben Sie eine Beschreibung ein';
      }

      if (!values.costs) {
        errors.costs = 'Bitte geben Sie einen Preis an';
      }

      return errors;
    },
  });

  return (
    <>
      <Modal
        isOpen={isAddModalOpen}
        centered={true}
        toggle={() =>
          !isSubmittingForm
            ? setIsAddModalOpen(!isAddModalOpen)
            : setIsAddModalOpen(isAddModalOpen)
        }
      >
        <ModalHeader>
          <h4>Geschenk hinzufügen</h4>
        </ModalHeader>
        <ModalBody>
          <FormikProvider value={formik}>
            <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label>Name</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.name && formik.touched.name
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'name'}
                  name={'name'}
                  placeholder={'Namen eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className={'invalid-feedback'}>{formik.errors.name}</div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Beschreibung</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.description && formik.touched.description
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'description'}
                  name={'description'}
                  placeholder={'Beschreibung eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.description}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Bild</Label>
                <input
                  type={'file'}
                  className={'form-control'}
                  id={'file'}
                  accept={'image/*'}
                  name={'file'}
                  placeholder={'Bild auswählen'}
                  onChange={(ev) => {
                    if (
                      ev.currentTarget &&
                      ev.currentTarget.files &&
                      ev.currentTarget.files.length > 0
                    ) {
                      setAddPresentImage(ev.currentTarget.files[0]);
                    } else {
                      setAddPresentImage(undefined);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>

              <FormGroup>
                <Label>Preis</Label>
                <input
                  type={'number'}
                  className={`form-control${
                    formik.errors.costs && formik.touched.costs
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'costs'}
                  name={'costs'}
                  placeholder={'Preis eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.costs}
                />
                {formik.errors.costs && formik.touched.costs ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.costs}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup className={'form-check'}>
                <input
                  type={'checkbox'}
                  className={`form-check-input${
                    formik.errors.isPremium && formik.touched.isPremium
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'isPremium'}
                  name={'isPremium'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.isPremium && formik.touched.isPremium ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.isPremium}
                  </div>
                ) : (
                  <></>
                )}
                <Label className={'form-check-label'}>Premium</Label>
              </FormGroup>

              <div className={'d-flex'}>
                <div className={'p-2'}>
                  <LoadingButton
                    isLoading={formik.isSubmitting}
                    caption={'Hinzufügen'}
                    disabled={!formik.isValid || !addPresentImage}
                    isSubmit={true}
                  />
                </div>
                <div className={'p-2'}>
                  <Button
                    color={'danger'}
                    onClick={() => setIsAddModalOpen(false)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
      <GridToolbarContainer>
        <div className={'p-2'}>
          <Button color={'primary'} onClick={() => setIsAddModalOpen(true)}>
            Neues Geschenk
          </Button>
        </div>
      </GridToolbarContainer>
    </>
  );
}
