import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { Link, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loadOnlineCustomer } from '../../store/online-customer/actions';
import { getProfilePictureUrl } from '../../services/customer.service';
import RefreshButton from '../Common/RefreshButton';

export default function OnlineCustomer() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onlineCustomer = useAppSelector(
    (state) => state.OnlineCustomer.customer,
  );
  const isLoading = useAppSelector(
    (state) => state.OnlineCustomer.isLoadingCustomer,
  );

  useEffect(() => {
    dispatch(loadOnlineCustomer());
  }, [dispatch]);

  return (
    <>
      <Card>
        <LoadingOverlay spinner active={isLoading}>
          <CardHeader className={'d-flex flex-row align-items-center'}>
            <div className={'mr-auto'}>
              <h4>Online Kunden</h4>
            </div>
            <div className={'ml-auto'}>
              <RefreshButton
                refreshAction={() => dispatch(loadOnlineCustomer())}
              />
            </div>
          </CardHeader>
          <CardBody>
            <div className={'table-responsive w-100 mt-3'}>
              <table className={'table'}>
                <thead>
                  <tr>
                    <th>Profilbild</th>
                    <th>Kunde</th>
                    <th>Gesendete Nachrichten</th>
                    <th>FlirtCoins</th>
                    <th>Host</th>
                  </tr>
                </thead>
                <tbody>
                  {onlineCustomer.map((customer) => (
                    <tr key={customer.UserId}>
                      <td>
                        <div
                          style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            height: '60px',
                            width: '60px',
                            borderRadius: '50%',
                            backgroundImage: `url(${getProfilePictureUrl(
                              customer.UserId,
                            )})`,
                          }}
                        />
                      </td>
                      <td>
                        <Link to={`/customers/${customer.UserId}`}>
                          {customer.Username}
                        </Link>
                      </td>
                      <td>{customer.SentMessagesCount}</td>
                      <td>{customer.FlirtCoins}</td>
                      <td>{customer.Host}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </LoadingOverlay>
      </Card>
    </>
  );
}
