import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import DoubleIPUser from '../../components/Tools/DoubleIPUser';

export default function IPChecker() {
  const [breadcrumbItems] = useState([
    { title: 'Tools', link: '#' },
    { title: 'IP-Checker', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="IP-Checker" breadcrumbItems={breadcrumbItems} />

          <Row>
            <div className={'col-12'}>
              <DoubleIPUser />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
