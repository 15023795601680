import React from 'react';

import swissFlag from '../../assets/images/flags/switzerland.svg';
import germanyFlag from '../../assets/images/flags/germany.svg';
import austriaFlag from '../../assets/images/flags/austria.svg';

interface IFlagImageOptions {
  country: string;
}

export default function FlagImage(options: IFlagImageOptions) {
  let imageSrc = germanyFlag;

  if (options.country === 'AT') {
    imageSrc = austriaFlag;
  }

  if (options.country === 'CH') {
    imageSrc = swissFlag;
  }

  return <img src={imageSrc} height={20} />;
}
