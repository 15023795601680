import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadAgencies } from '../../../store/agency/actions';
import { IAgency } from '../../../models/agency';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';
import { deleteAgency } from '../../../services/agency.service';
import { deleteModerator } from '../../../services/moderator.service';
import { loadModerators } from '../../../store/moderator/actions';

import AgencyEditModal from './AgencyEditModal';
import AgencyTableToolbar from './AgencyTableToolbar';

export default function AgencyTable() {
  console.log('Is new rendered');

  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const token = useAppSelector((state) => state.Login.token);

  const loading = useAppSelector((state) => state.Agency.isLoading);
  const agencies = useAppSelector((state) => state.Agency.agencies);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editAgency, setEditAgency] = useState<IAgency>();

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const onEditClick = function (row: IAgency) {
    setIsEditModalOpen(true);
    setEditAgency(row);
  };

  const onDeleteClick = function (row: IAgency) {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Löschen',
      content: 'Wollen Sie die Agentur wirklich löschen?',
      confirmCallback: async () => {
        await deleteAgency(row.AgencyId, token!);
        dispatch(
          loadAgencies({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'AgencyId',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'MinMessageCharCount',
      headerName: 'Mindestanzahl Zeichen (pro Nachricht)',
      flex: 1,
    },
    {
      field: 'DateCreate',
      headerName: 'Erstellt Am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      flex: 1,
      renderCell: (cell) => (
        <div className={'d-flex flex-row'}>
          <Button
            color={'info'}
            className={'ml-2 btn-sm btn-rounded'}
            onClick={() => onEditClick(cell.row as IAgency)}
          >
            <i className={'ri-edit-2-line'} />
          </Button>

          <Button
            color={'danger'}
            className={'ml-2 btn-sm btn-rounded'}
            onClick={() => onDeleteClick(cell.row as IAgency)}
          >
            <i className={'ri-delete-bin-2-line'} />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      loadAgencies({
        page,
        size: pageSize,
      }),
    );
  }, [pageSize, page]);

  return (
    <Card>
      <CardBody>
        <DataGrid
          rows={agencies.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.AgencyId}
          autoHeight={true}
          rowCount={agencies.Total}
          paginationMode={'server'}
          components={{
            Toolbar: AgencyTableToolbar,
          }}
          disableSelectionOnClick
          loading={loading}
        />
      </CardBody>

      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
      <AgencyEditModal
        isOpen={isEditModalOpen}
        agency={editAgency}
        close={() => setIsEditModalOpen(false)}
      />
    </Card>
  );
}
