import { IBaseAction } from '../type';
import { IModerator } from '../../models/moderator';
import { IPagedResult } from '../../models';

import {
  LOAD_MODERATORS,
  LOAD_MODERATORS_FAILED,
  LOAD_ONLINE_MODERATORS,
  LOAD_ONLINE_MODERATORS_FAILED,
  SET_MODERATORS,
  SET_ONLINE_MODERATORS,
} from './actionTypes';

interface IModeratorState {
  online: IModerator[];
  moderators: IPagedResult<IModerator>;
  isLoading: boolean;
  isLoadingOnline: boolean;
}

const initialState: IModeratorState = {
  online: [],
  moderators: {
    Total: 0,
    Data: [],
  },
  isLoading: false,
  isLoadingOnline: false,
};

const Moderator = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_MODERATORS:
      state = {
        ...state,
        moderators: action.payload,
        isLoading: false,
      };
      break;
    case LOAD_MODERATORS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOAD_MODERATORS_FAILED:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case SET_ONLINE_MODERATORS:
      state = {
        ...state,
        online: action.payload,
        isLoadingOnline: false,
      };
      break;
    case LOAD_ONLINE_MODERATORS:
      state = {
        ...state,
        isLoadingOnline: true,
      };
      break;
    case LOAD_ONLINE_MODERATORS_FAILED:
      state = {
        ...state,
        isLoadingOnline: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default Moderator;
