import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppSelector } from '../../store/hooks';
import {
  loadConfirmedCustomers,
  loadDeletedCustomers,
  loadModeratedCustomers,
  loadUnconfirmedCustomers,
} from '../../store/customer/actions';

import CustomerTable from './components/CustomerTable';

export default function CustomerOverview() {
  const [breadcrumbItems] = useState([
    { title: 'Kunden', link: '#' },
    { title: 'Übersicht', link: '#' },
  ]);

  const totalConfirmed = useAppSelector(
    (state) => state.Customer.confirmedCustomer.Total,
  );
  const totalUnconfirmed = useAppSelector(
    (state) => state.Customer.unconfirmedCustomer.Total,
  );
  const totalDeleted = useAppSelector(
    (state) => state.Customer.deletedCustomer.Total,
  );
  const totalModerated = useAppSelector(
    (state) => state.Customer.moderatedCustomer.Total,
  );

  const [activeTab, setActiveTab] = useState('confirmed');

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kunden" breadcrumbItems={breadcrumbItems} />

          <Row>
            <div className={'col-12'}>
              <Card>
                <CardBody>
                  <Nav pills>
                    <NavItem>
                      <NavLink
                        onClick={() => setActiveTab('confirmed')}
                        className={classnames({
                          active: activeTab === 'confirmed',
                        })}
                      >
                        Mitglieder{' '}
                        <span className={'badge badge-light ml-2'}>
                          {totalConfirmed}
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        onClick={() => setActiveTab('unconfirmed')}
                        className={classnames({
                          active: activeTab === 'unconfirmed',
                        })}
                      >
                        unbestätigte Mitglieder{' '}
                        <span className={'badge badge-light ml-2'}>
                          {totalUnconfirmed}
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        onClick={() => setActiveTab('deleted')}
                        className={classnames({
                          active: activeTab === 'deleted',
                        })}
                      >
                        gelöschte Mitglieder{' '}
                        <span className={'badge badge-light ml-2'}>
                          {totalDeleted}
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        onClick={() => setActiveTab('moderated')}
                        className={classnames({
                          active: activeTab === 'moderated',
                        })}
                      >
                        Profile{' '}
                        <span className={'badge badge-light ml-2'}>
                          {totalModerated}
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className={'mt-3'}>
                    <TabPane tabId={'confirmed'}>
                      {activeTab === 'confirmed' ? (
                        <>
                          <CustomerTable
                            loadingSelector={(state) =>
                              state.Customer.isLoadingConfirmed
                            }
                            customersSelector={(state) =>
                              state.Customer.confirmedCustomer
                            }
                            loadFunction={loadConfirmedCustomers()}
                          />
                        </>
                      ) : null}
                    </TabPane>
                    <TabPane tabId={'unconfirmed'}>
                      {activeTab === 'unconfirmed' ? (
                        <>
                          <CustomerTable
                            loadingSelector={(state) =>
                              state.Customer.isLoadingUnconfirmed
                            }
                            customersSelector={(state) =>
                              state.Customer.unconfirmedCustomer
                            }
                            loadFunction={loadUnconfirmedCustomers()}
                          />
                        </>
                      ) : null}
                    </TabPane>
                    <TabPane tabId={'deleted'}>
                      {activeTab === 'deleted' ? (
                        <CustomerTable
                          loadingSelector={(state) =>
                            state.Customer.isLoadingDeleted
                          }
                          customersSelector={(state) =>
                            state.Customer.deletedCustomer
                          }
                          loadFunction={loadDeletedCustomers()}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId={'moderated'}>
                      {activeTab === 'moderated' ? (
                        <>
                          <CustomerTable
                            loadingSelector={(state) =>
                              state.Customer.isLoadingModerated
                            }
                            customersSelector={(state) =>
                              state.Customer.moderatedCustomer
                            }
                            loadFunction={loadModeratedCustomers()}
                          />
                        </>
                      ) : null}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
