import { IBaseAction } from '../type';
import { IPagedResult } from '../../models';

import {
  LOAD_PAYMENT_METHODS,
  LOAD_PAYMENT_METHODS_FAILED,
  SET_PAYMENT_METHODS,
} from './actionTypes';

interface IPaymentMethodState {
  paymentMethods: IPagedResult<IPaymentMethodState>;
  isLoading: boolean;
}

const initialState: IPaymentMethodState = {
  paymentMethods: {
    Total: 0,
    Data: [],
  },
  isLoading: false,
};

const PaymentMethods = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_PAYMENT_METHODS:
      state = {
        ...state,
        paymentMethods: action.payload,
        isLoading: false,
      };
      break;
    case LOAD_PAYMENT_METHODS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOAD_PAYMENT_METHODS_FAILED:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default PaymentMethods;
