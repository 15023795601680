import { createPortal } from 'react-dom';
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';

import LoadingButton from '../Common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { modifyCoins } from '../../services/customer.service';

interface ICustomerAddCoinsModalOptions {
  userId: number;
  isOpen: boolean;
  toggle: () => void;
  onAdded?: () => void;
}

export default function CustomerAddCoinsModal(
  opt: ICustomerAddCoinsModalOptions,
) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const formik = useFormik({
    initialValues: {
      coinCount: 0,
    },
    onReset: (values) => {
      values.coinCount = 0;
    },
    validateOnMount: true,
    validate: (values) => {
      const err = {} as any;

      if (values.coinCount == 0) {
        err.coinCount = 'Bitte geben Sie eine Anzahl ein';
      }

      return err;
    },
    onSubmit: async (values) => {
      try {
        if (values.coinCount == 0) return;

        await modifyCoins(opt.userId, values.coinCount, token!);

        if (values.coinCount > 0) {
          toast.success('Die Coins wurden erfolgreich gutgeschrieben.');
        } else if (values.coinCount < 0) {
          toast.success('Die Coins wurden erfolgreich abgezogen.');
        }

        if (opt.onAdded) opt.onAdded();

        opt.toggle();
        formik.resetForm();
      } catch (err) {
        if (values.coinCount > 0) {
          toast.error('Fehler beim Gutschreiben der Coins.');
        } else if (values.coinCount < 0) {
          toast.error('Fehler beim Abziehen der Coins.');
        }
      }
    },
  });

  return createPortal(
    <Modal
      isOpen={opt.isOpen}
      toggle={() => opt.toggle()}
      centered={true}
      backdrop={true}
    >
      <ModalHeader toggle={() => opt.toggle()}>Coins</ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Anzahl</Label>
              <input
                type={'number'}
                className={`form-control${
                  formik.errors.coinCount && formik.touched.coinCount
                    ? ' is-invalid'
                    : ''
                }`}
                id={'coinCount'}
                name={'coinCount'}
                placeholder={'Anzahl eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.coinCount ?? 0}
              />
              <ErrorMessage
                name={'coinCount'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <small>
              Geben Sie eine negative Zahl ein, um Coins abzuziehen oder eine
              positive Zahl, um Coins gutzuschreiben.
            </small>

            <br />
            <LoadingButton
              isLoading={formik.isSubmitting}
              className={'mt-2'}
              caption={
                formik.values.coinCount >= 0 ? 'Gutschreiben' : 'Abziehen'
              }
              isSubmit={true}
              disabled={formik.isSubmitting || !formik.isValid}
            />
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
