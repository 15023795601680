import React from 'react';
import { Button } from 'reactstrap';

interface IRefreshButtonOptions {
  refreshAction: Function;
}

export default function RefreshButton(opt: IRefreshButtonOptions) {
  return (
    <Button color={'primary'} onClick={() => opt.refreshAction()}>
      <div className={'d-flex flex-row align-items-center'}>
        <div>
          <i className={'ri-refresh-line'} />
        </div>
        <div className={'ml-2'}>Refresh</div>
      </div>
    </Button>
  );
}
