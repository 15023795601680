import { IBaseAction } from '../type';
import { IPagedResult } from '../../models';
import { ISupport } from '../../models/support';

import { LOAD_TICKETS, LOAD_TICKETS_FAILED, SET_TICKETS } from './actionTypes';

interface ISupportState {
  tickets: IPagedResult<ISupport>;
  isLoadingTickets: boolean;
}

const initialState: ISupportState = {
  tickets: {
    Total: 0,
    Data: [],
  },
  isLoadingTickets: false,
};

const Support = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_TICKETS:
      state = {
        ...state,
        tickets: action.payload,
        isLoadingTickets: false,
      };
      break;
    case LOAD_TICKETS:
      state = {
        ...state,
        isLoadingTickets: true,
      };
      break;
    case LOAD_TICKETS_FAILED:
      state = {
        ...state,
        isLoadingTickets: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default Support;
