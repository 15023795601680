import axios from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';

export async function transferDialog(
  dialogId: number,
  moderatorId: number,
  token: string,
): Promise<void> {
  return axios.post(
    `${environment.API_URL}/dialog/${dialogId}/transfer`,
    { moderatorId },
    getAxiosOptions(token),
  );
}
