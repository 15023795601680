import { IBaseAction } from '../../type';

import {
  CHECK_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_UNSUCCESS,
  LOGOUT,
} from './actionTypes';

interface ILoginState {
  isCheckingLogin: boolean;
  isLoggedIn: boolean;
  token?: string;
  username?: string;
}

const initialState: ILoginState = {
  isCheckingLogin: false,
  isLoggedIn: false,
};

const Login = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case CHECK_LOGIN:
      state = {
        ...state,
        isCheckingLogin: true,
      };
      break;
    case LOGIN_UNSUCCESS:
      state = {
        ...state,
        isCheckingLogin: false,
        isLoggedIn: false,
        username: undefined,
        token: undefined,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        isCheckingLogin: false,
        isLoggedIn: true,
        username: action.payload.username,
        token: action.payload.token,
      };
      break;
    case LOGOUT:
      state = initialState;
      break;
    default:
      break;
  }

  return state;
};

export default Login;
