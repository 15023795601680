import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import axios, { CancelToken } from 'axios';

import { getCustomerPictures } from '../../../services/customer.service';
import { useAppSelector } from '../../../store/hooks';
import { ICustomerPicture } from '../../../models/customer';

import CustomerPicture from './CustomerPicture';

interface ICustomerPictureOptions {
  userId: number | undefined;
  hideEdit?: boolean;
  toggleReload?: boolean;
}

export default function CustomerPictures(options: ICustomerPictureOptions) {
  const token = useAppSelector((state) => state.Login.token);
  const [pictures, setPictures] = useState<ICustomerPicture[]>();

  const reload = async (userId: number, cancelToken?: CancelToken) => {
    const customerPictures = await getCustomerPictures(
      userId,
      token!,
      cancelToken,
    );

    setPictures(customerPictures);
  };

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    reload(options.userId, cancelToken.token);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [options.userId, options.toggleReload]);

  if (!pictures) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Bilder</CardHeader>
        <CardBody>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={'flex-fill'}>
      <CardHeader>
        <div className={'d-flex flex-wrap'}>
          <div>Bilder</div>
          {!options.hideEdit ? (
            <div className={'ml-auto'}>
              <a href={`/customers/${options.userId}/pictures`}>Bearbeiten</a>
            </div>
          ) : null}
        </div>
      </CardHeader>
      <CardBody>
        <div className={'d-flex flex-wrap'}>
          {pictures.length <= 0 ? 'Der Benutzer hat keine Bilder' : ''}
          {pictures.map((pic) => (
            <div className={'p-3'} key={pic.UserPictureId}>
              <CustomerPicture
                pictureId={pic.UserPictureId}
                userId={pic.UserId}
                key={pic.UserPictureId}
                onPictureDeleted={() =>
                  setPictures([
                    ...pictures?.filter(
                      (x) => x.UserPictureId !== pic.UserPictureId,
                    ),
                  ])
                }
              />
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
}
