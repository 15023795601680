import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import { Emojione } from 'react-emoji-render';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getMessageContent } from '../../../services/message.service';
import { IMessage, IMessageContent } from '../../../models/message';
import { getProfilePictureUrl } from '../../../services/customer.service';
import { setLatestMessageDialog } from '../../../store/animation/actions';

interface ILatestMessageContent {
  message?: IMessage;
}

export default function LatestMessageContent(options: ILatestMessageContent) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [messageContent, setMessageContent] = useState<IMessageContent>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!options.message) return;

    const cancelToken = axios.CancelToken.source();

    const loadMessage = async (token: string, messageId: number) => {
      setIsLoadingMessage(true);
      try {
        const msg = await getMessageContent(
          messageId,
          token,
          cancelToken.token,
        );

        setMessageContent(msg);
        setIsLoadingMessage(false);
      } catch (e) {
        console.error(e);
      }
    };

    loadMessage(token!, options.message.MessageId);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [options.message]);

  const setDialog = () => {
    dispatch(
      setLatestMessageDialog(
        options.message?.SenderUserId,
        options.message?.ReceiverUserId,
      ),
    );
  };

  if (isLoadingMessage || !options.message) {
    return <Spinner />;
  }

  return (
    <div
      className={'d-flex flex-row  align-items-center'}
      style={{ cursor: 'pointer' }}
      onClick={() => setDialog()}
    >
      <div className={'d-flex mr-2'}>
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '60px',
            width: '60px',
            borderRadius: '50%',
            backgroundImage: `url(${getProfilePictureUrl(
              options.message.SenderUserId,
            )})`,
          }}
        />
      </div>
      <div className={'d-flex flex-column flex-grow-1'}>
        <div>
          {messageContent?.Content ? (
            <Emojione text={messageContent?.Content} />
          ) : (
            ''
          )}
        </div>
        {messageContent?.PicturePath ? (
          <div className={'mt-2'}>
            <a href={'#'}>Bild öffnen</a>
          </div>
        ) : (
          ''
        )}
        <div className={'mt-1 ml-auto'}>
          <small>
            Gesendet am:{' '}
            {moment(options.message?.DateCreate).format('DD.MM.YYYY HH:mm')}
          </small>
        </div>
      </div>
    </div>
  );
}
