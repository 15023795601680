import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppDispatch } from '../../store/hooks';
import { loadAgencies } from '../../store/agency/actions';

import AgencyTable from './components/AgencyTable';

export default function AgencyPage() {
  const [breadcrumbItems] = useState([
    { title: 'Agenturen', link: '#' },
    { title: 'Übersicht', link: '#' },
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      loadAgencies({
        page: 0,
        size: 10,
      }),
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="Agenturen" breadcrumbItems={breadcrumbItems} />

          <Row>
            <div className={'col-12'}>
              <AgencyTable />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
