import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { createPortal } from 'react-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IAgency } from '../../../models/agency';
import { updateAgency } from '../../../services/agency.service';
import { loadAgencies } from '../../../store/agency/actions';
import LoadingButton from '../../../components/Common/LoadingButton';

interface IEditAgency {
  name: string;
  minMessageCharCount: number | null;
}

interface IAgencyEditModalOptions {
  isOpen: boolean;
  agency: IAgency | undefined;
  close: Function;
}

export default function AgencyEditModal(opt: IAgencyEditModalOptions) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const initialValues: IEditAgency = {
    name: opt.agency?.Name ?? '',
    minMessageCharCount: opt.agency?.MinMessageCharCount ?? 0,
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onReset: (values) => {
      values.name = initialValues.name;
      values.minMessageCharCount = initialValues.minMessageCharCount;
    },
    onSubmit: async (values) => {
      await updateAgency(
        opt.agency?.AgencyId!,
        values.name,
        values.minMessageCharCount!,
        token!,
      );

      dispatch(
        loadAgencies({
          size: 20,
          page: 0,
        }),
      );

      opt.close();
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name || values.name === '') {
        errors.name = 'Bitte geben Sie einen Namen ein';
      }

      if (values.minMessageCharCount === null) {
        errors.minMessageCharCount = 'Bitte geben Sie eine Anzahl ein';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (opt.agency && formik) {
      formik.setValues({
        name: opt.agency.Name,
        minMessageCharCount: opt.agency.MinMessageCharCount,
      });
    }
  }, [opt.agency]);
  return (
    <FormikProvider value={formik}>
      <Modal centered={true} isOpen={opt.isOpen}>
        <ModalHeader>Agentur bearbeiten</ModalHeader>
        <ModalBody>
          <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Name</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.name && formik.touched.name ? ' is-invalid' : ''
                }`}
                id={'name'}
                name={'name'}
                placeholder={'Name eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name ? (
                <div className={'invalid-feedback'}>{formik.errors.name}</div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Mindestanzahl Zeichen (pro Nachricht)</Label>
              <input
                type={'number'}
                className={`form-control${
                  formik.errors.minMessageCharCount &&
                  formik.touched.minMessageCharCount
                    ? ' is-invalid'
                    : ''
                }`}
                id={'minMessageCharCount'}
                name={'minMessageCharCount'}
                placeholder={'Anzahl eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.minMessageCharCount ?? 0}
              />
              {formik.errors.minMessageCharCount &&
              formik.touched.minMessageCharCount ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.minMessageCharCount}
                </div>
              ) : (
                <></>
              )}
            </FormGroup>

            <div className={'d-flex'}>
              <div className={'p-2'}>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  isSubmit={true}
                />
              </div>
              <div className={'p-2'}>
                <Button color={'danger'} onClick={() => opt.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
}
