import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment';

import { IModerator, IModeratorStatistic } from '../../../models/moderator';
import { useAppSelector } from '../../../store/hooks';
import {
  getModeratorStatistic,
  getModeratorTodaysStatistic,
  getTotalFinishedDialogs,
  getTotalUnfinishedDialogs,
} from '../../../services/moderator.service';

interface IOnlineModeratorRowOptions {
  moderator: IModerator;
}

export default function OnlineModeratorRow(
  options: IOnlineModeratorRowOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [moderatorStatistic, setModeratorStatistic] =
    useState<IModeratorStatistic>();
  const [moderatorStatisticToday, setModeratorStatisticToday] =
    useState<IModeratorStatistic>();

  const [finishedDialogsCount, setFinishedDialogsCount] = useState<number>();
  const [unfinishedDialogsCount, setUnfinishedDialogsCount] =
    useState<number>();

  let lastOnline = null;

  if (options.moderator.OnlineStatus) {
    lastOnline = moment(options.moderator.OnlineStatus.DateCreate).format(
      'DD.MM.YYYY HH:mm',
    );
  }

  useEffect(() => {
    let cancel = false;

    const loadModeratorStatistic = async function () {
      const statistic = await getModeratorStatistic(
        options.moderator.ModeratorId,
        token!,
      );
      const statisticToday = await getModeratorTodaysStatistic(
        options.moderator.ModeratorId,
        token!,
      );

      if (cancel) return;

      setModeratorStatistic(statistic);
      setModeratorStatisticToday(statisticToday);
    };

    const loadModeratorDialogsCount = async function () {
      const finishedDialogsCount = await getTotalFinishedDialogs(
        options.moderator.ModeratorId,
        token!,
      );
      const unfinishedDialogsCount = await getTotalUnfinishedDialogs(
        options.moderator.ModeratorId,
        token!,
      );

      if (cancel) return;

      setFinishedDialogsCount(finishedDialogsCount);
      setUnfinishedDialogsCount(unfinishedDialogsCount);
    };

    loadModeratorStatistic();
    loadModeratorDialogsCount();

    return () => {
      cancel = true;
    };
  }, [token, options.moderator.ModeratorId]);

  return (
    <tr>
      <td>{options.moderator.ModeratorId}</td>
      <td>{options.moderator.Agency?.Name ?? 'keine'}</td>
      <td>{options.moderator.Username}</td>
      <td>
        {moderatorStatistic && moderatorStatisticToday ? (
          `${moderatorStatistic.IncomingMessageCount} (${moderatorStatisticToday.IncomingMessageCount})`
        ) : (
          <Spinner size={'sm'} />
        )}
      </td>
      <td>
        {moderatorStatistic && moderatorStatisticToday ? (
          `${moderatorStatistic.OutgoingMessageCount} (${moderatorStatisticToday.OutgoingMessageCount})`
        ) : (
          <Spinner size={'sm'} />
        )}
      </td>

      <td>
        {finishedDialogsCount && unfinishedDialogsCount ? (
          `${finishedDialogsCount} / ${unfinishedDialogsCount}`
        ) : (
          <Spinner size={'sm'} />
        )}
      </td>

      <td>{lastOnline ?? 'n/a'}</td>
    </tr>
  );
}
