import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import axios, { CancelTokenSource } from 'axios';
import { Check, Delete } from '@material-ui/icons';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

import {
  approveVoiceDescription,
  deleteVoiceDescription,
  getPendingVoiceDescriptions,
  getVoiceDescriptionPlayUrl,
} from '../../services/customer.service';
import { ICustomerVoiceDescription } from '../../models/customer';
import CustomerName from '../Customer/CustomerName';
import { useAppSelector } from '../../store/hooks';

export default function PendingVoiceDescriptions() {
  const [isLoading, setIsLoading] = useState(false);
  const token = useAppSelector((state) => state.Login.token);
  const [pending, setPending] = useState<ICustomerVoiceDescription[]>([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let interval: NodeJS.Timeout | null = null;

    const loadPending = async () => {
      await onLoadPending();
    };

    loadPending();

    interval = setInterval(() => loadPending(), 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      cancelToken.cancel();
    };
  }, [token]);

  const onLoadPending = async (
    interval?: any,
    cancelToken?: CancelTokenSource,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const _pending = await getPendingVoiceDescriptions(
        token!,
        cancelToken?.token,
      );

      setPending(_pending);
      setIsLoading(false);
    } catch (e) {
      toast.error(
        'Beim Abruf des ASA-Dialog-Status ist ein Fehler aufgetreten.',
      );

      if (interval) {
        clearInterval(interval);
      }
    }
  };

  const onDeleteVoiceDescription = async (
    userId: number,
    userVoiceDescriptionId: number,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await deleteVoiceDescription(userId, userVoiceDescriptionId, token!);
      await onLoadPending(undefined, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Löschen der Sprachnotiz');
    }

    setIsLoading(false);
  };

  const onApproveVoiceDescription = async (
    userId: number,
    userVoiceDescriptionId: number,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await approveVoiceDescription(userId, userVoiceDescriptionId, token!);
      await onLoadPending(undefined, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Freigeben der Sprachnotiz');
    }

    setIsLoading(false);
  };

  return (
    <>
      <Card>
        <CardHeader>Offene Freigaben</CardHeader>
        <CardBody>
          <LoadingOverlay spinner active={isLoading}>
            <div className={'table-responsive'}>
              <table className={'table table-hover'}>
                <thead>
                  <tr>
                    <th>Benutzer</th>
                    <th>Titel</th>
                    <th>Vorschau</th>
                    <th>Hochgeladen am</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!pending || pending.length <= 0 ? (
                    <tr>
                      <td colSpan={4} className={'text-center'}>
                        Keine Sprachnotizen gefunden
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {pending?.map((voice) => (
                    <>
                      <tr key={voice.UserVoiceDescriptionId}>
                        <td>
                          <Link
                            to={`/customers/${voice.UserId}`}
                            className="dropdown-item"
                          >
                            <CustomerName userId={voice.UserId} />
                          </Link>
                        </td>
                        <td>{voice.Title}</td>
                        <td>
                          <audio controls style={{ height: '40px' }}>
                            <source
                              src={getVoiceDescriptionPlayUrl(
                                voice.UserId,
                                voice.UserVoiceDescriptionId,
                              )}
                              type={voice.ContentType}
                            />
                          </audio>
                        </td>
                        <td>{voice.DateCreate}</td>
                        <td>
                          <button
                            className={'btn btn-sm text-success'}
                            onClick={() =>
                              onApproveVoiceDescription(
                                voice.UserId,
                                voice.UserVoiceDescriptionId,
                              )
                            }
                            title={'Sprachnotiz freigeben'}
                          >
                            <Check />
                          </button>
                          <button
                            className={'btn btn-sm text-danger'}
                            onClick={() =>
                              onDeleteVoiceDescription(
                                voice.UserId,
                                voice.UserVoiceDescriptionId,
                              )
                            }
                            title={'Sprachnotiz löschen'}
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </>
  );
}
