import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  loadOpenDialogs,
  openTransferDialog,
} from '../../../store/animation/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { transferDialog } from '../../../services/dialog.service';

export default function TransferDialog() {
  const dispatch = useAppDispatch();
  const [isTransferDialogOpen, setTransferDialogOpen] = useState(false);

  const shouldTransferDialog = useAppSelector(
    (state) => state.Animation.transferDialog,
  );
  const onlineModerators = useAppSelector((state) => state.Moderator.online);
  const token = useAppSelector((state) => state.Login.token);
  const [moderator, setModerator] = useState<number>();
  const [isTransfering, setIsTransfering] = useState(false);

  useEffect(() => {
    if (shouldTransferDialog) {
      setTransferDialogOpen(true);
    } else {
      setTransferDialogOpen(false);
    }
  }, [shouldTransferDialog]);

  useEffect(() => {
    if (!moderator && onlineModerators.length > 0) {
      setModerator(onlineModerators[0].ModeratorId);
    }
  }, [onlineModerators]);

  const doTransferDialog = async function () {
    if (!shouldTransferDialog || !moderator) return;

    try {
      setIsTransfering(true);

      await transferDialog(shouldTransferDialog!, moderator!, token!);
      dispatch(loadOpenDialogs());

      toast.success('Der Dialog wurde erfolgreich übergeben.');
    } catch (err) {
      toast.error(
        'Beim Übergeben des Dialogs ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Administrator.',
      );
    } finally {
      setIsTransfering(false);
      dispatch(openTransferDialog(null));
    }
  };

  return (
    <Modal
      isOpen={isTransferDialogOpen}
      toggle={() => {
        openTransferDialog(null);
        setTransferDialogOpen(false);
      }}
      backdrop={true}
      centered={true}
    >
      <ModalHeader>Dialog übergeben</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Moderator auswählen</label>
          <select
            className={'form-control'}
            value={
              onlineModerators.length <= 0
                ? ''
                : onlineModerators[0].ModeratorId
            }
            onChange={(ev) => setModerator(parseInt(ev.currentTarget.value))}
          >
            {onlineModerators?.map((mod) => (
              <option key={mod.ModeratorId} value={mod.ModeratorId}>
                {mod.Username}
              </option>
            ))}
          </select>
        </FormGroup>

        <Button
          color={'primary'}
          onClick={doTransferDialog}
          disabled={isTransfering}
        >
          {isTransfering ? (
            <>
              <Spinner size={'sm'} />{' '}
              <span className={'ml-2'}>Übergebe ...</span>
            </>
          ) : (
            'Übergeben'
          )}
        </Button>
      </ModalBody>
    </Modal>
  );
}
