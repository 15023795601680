import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => (
  <React.Fragment>
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col sm={12}>{new Date().getFullYear()} © loveSystem</Col>
        </Row>
      </Container>
    </footer>
  </React.Fragment>
);

export default Footer;
