import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import axios, { CancelToken } from 'axios';

import { getCustomerCoinInformation } from '../../../services/customer.service';
import { useAppSelector } from '../../../store/hooks';
import { ICustomerCoinInformation } from '../../../models/customer';
import CustomerAddCoinsModal from '../../../components/Customer/CustomerAddCoinsModal';

interface ICustomerCoinInformationOptions {
  userId: number | undefined;
}

export default function CustomerCoinInformation(
  options: ICustomerCoinInformationOptions,
) {
  const token = useAppSelector((state) => state.Login.token);
  const [coinInformation, setCoinInformation] =
    useState<ICustomerCoinInformation>();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const loadCoinInformation = async (
    userId: number,
    cancelToken?: CancelToken,
  ) => {
    const coinInfo = await getCustomerCoinInformation(
      userId,
      token!,
      cancelToken,
    );

    setCoinInformation(coinInfo);
  };

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    loadCoinInformation(options.userId, cancelToken.token);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [options.userId]);

  if (!coinInformation) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Coin Informationen</CardHeader>
        <CardBody>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <CustomerAddCoinsModal
        userId={options.userId!}
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
        onAdded={() => loadCoinInformation(options.userId!)}
      />
      <Card className={'flex-fill'}>
        <CardHeader>
          <div className={'d-flex flex-wrap'}>
            <div>Coin Informationen</div>
            <div className={'ml-auto'}>
              <a href={'#'} onClick={() => setIsAddModalOpen(true)}>
                Bearbeiten
              </a>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <div>
                <strong>Coinanzahl</strong>
                <div>{coinInformation.CoinCount} FlirtCoins</div>
              </div>
            </Col>
            <Col>
              <div>
                <strong>Käufe</strong>
                <div>{coinInformation.TransactionCount} Käufe</div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
