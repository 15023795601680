import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';

import { useAppSelector } from '../../store/hooks';
import { getPresent } from '../../services/present.service';
import { IPresent } from '../../models/Present';

import PresentPicture from './PresentPicture';

interface IPresentOptions {
  presentId: number;
}

export default function Present(opt: IPresentOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const [present, setPresent] = useState<IPresent>();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadPresent = async function (presentId: number) {
      const _present = await getPresent(presentId, token!, cancelToken.token);

      setPresent(_present);
    };

    loadPresent(opt.presentId);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [opt.presentId, token]);

  if (present) {
    return (
      <div className={'d-flex flex-column'}>
        <div className={'text-center'}>
          <PresentPicture presentId={opt.presentId} />
        </div>
        <div>
          <small>
            {present.Name} (
            {present.Costs.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            })}
            )
          </small>
        </div>
      </div>
    );
  }

  return <Spinner size={'sm'} />;
}
