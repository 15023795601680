import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';
import { IPaymentMethod } from '../../../models/payment-methods';
import {
  activatePaymentMethod,
  deactivatePaymentMethod,
} from '../../../services/payment-method.service';
import { loadPaymentMethods } from '../../../store/payment-methods/actions';

import PaymentMethodEditModal from './PaymentMethodEditModal';

export default function PaymentMethodsTable() {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] = useState<IPaymentMethod>();

  const token = useAppSelector((state) => state.Login.token);

  const loading = useAppSelector((state) => state.PaymentMethods.isLoading);
  const paymentMethods = useAppSelector(
    (state) => state.PaymentMethods.paymentMethods,
  );

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const onEditClick = function (row: IPaymentMethod) {
    setEditPaymentMethod(row);
    setIsEditModalOpen(true);
  };

  const onDeactivateClick = function (row: IPaymentMethod) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Zahlungsmethode deaktivieren',
      isOpen: true,
      content: `Wollen Sie die Zahlungsmethode ${row.Name} wirklich deaktivieren?`,
      confirmCallback: async () => {
        await deactivatePaymentMethod(row.PaymentMethodId, token!);
        toast.success('Die Zahlungsmethode wurde erfolgreich deaktiviert.');
        dispatch(
          loadPaymentMethods({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const onActivateClick = function (row: IPaymentMethod) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Zahlungsmethode aktivieren',
      isOpen: true,
      content: `Wollen Sie die Zahlungsmethode ${row.Name} wirklich aktivieren?`,
      confirmCallback: async () => {
        await activatePaymentMethod(row.PaymentMethodId, token!);
        toast.success('Die Zahlungsmethode wurde erfolgreich aktiviert.');
        dispatch(
          loadPaymentMethods({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'PaymentMethodId',
      headerName: '',
      width: 50,
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Description',
      headerName: 'Beschreibung',
      flex: 1,
    },
    {
      field: 'Active',
      headerName: 'Aktiv',
      width: 120,
      renderCell: (cell) => {
        const paymentMethod = cell.row as IPaymentMethod;
        if (paymentMethod.Active) {
          return <i className={'ri-check-fill ri-2x'} title={'Aktiviert'} />;
        }

        return <i className={'ri-close-fill ri-2x'} title={'Deaktiviert'} />;
      },
    },
    {
      field: 'DateChange',
      headerName: 'Geändert Am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (cell) => {
        const paymentMethod = cell.row as IPaymentMethod;

        let StatusToggle = (
          <Button
            color={'success'}
            className={'ml-2 btn-sm btn-rounded'}
            title={'Zahlungsmethode aktivieren'}
            onClick={() => onActivateClick(cell.row as IPaymentMethod)}
          >
            <i className={'ri-check-line'} />
          </Button>
        );

        if (paymentMethod.Active) {
          StatusToggle = (
            <Button
              color={'danger'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Zahlungsmethode deaktivieren'}
              onClick={() => onDeactivateClick(cell.row as IPaymentMethod)}
            >
              <i className={'ri-close-line'} />
            </Button>
          );
        }

        return (
          <div className={'d-flex flex-row'}>
            <Button
              color={'info'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Zahlungsmethode bearbeiten'}
              onClick={() => onEditClick(cell.row as IPaymentMethod)}
            >
              <i className={'ri-edit-2-line'} />
            </Button>
            {StatusToggle}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      loadPaymentMethods({
        page,
        size: pageSize,
      }),
    );
  }, [pageSize, page]);

  return (
    <Card>
      <CardBody>
        <DataGrid
          rows={paymentMethods.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.PaymentMethodId}
          autoHeight={true}
          rowCount={paymentMethods.Total}
          paginationMode={'server'}
          disableSelectionOnClick
          loading={loading}
        />
      </CardBody>
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
      <PaymentMethodEditModal
        close={() => {
          setIsEditModalOpen(false);
          dispatch(
            loadPaymentMethods({
              page,
              size: pageSize,
            }),
          );
        }}
        isOpen={isEditModalOpen}
        paymentMethod={editPaymentMethod}
      />
    </Card>
  );
}
