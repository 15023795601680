import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LoadingButton from '../../../components/Common/LoadingButton';
import { ISupport } from '../../../models/support';
import { answerTicket } from '../../../services/support.service';

interface IAnswerTicket {
  message: string;
}

interface IAnswerTicketModalOptions {
  isOpen: boolean;
  ticket: ISupport | undefined;
  close: Function;
}

export default function AnswerTicketModal(opt: IAnswerTicketModalOptions) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const initialValues: IAnswerTicket = {
    message: '',
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onReset: (values) => {
      values.message = '';
    },
    onSubmit: async (values) => {
      try {
        await answerTicket(opt.ticket?.SupportId!, values.message, token!);

        toast.success('Die Support-Anfrage wurde erfolgreich beantwortet.');

        opt.close();
      } catch (e) {
        toast.error(
          'Beim Beantworten der Support-Anfrage ist ein Fehler aufgetreten.',
        );
      }
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.message || values.message === '') {
        errors.message = 'Bitte geben Sie eine Nachricht ein';
      }

      return errors;
    },
  });

  return (
    <FormikProvider value={formik}>
      <Modal centered={true} isOpen={opt.isOpen}>
        <ModalHeader>Support-Anfrage beantworten</ModalHeader>
        <ModalBody>
          <div className={'d-flex flex-column flex-wrap'}>
            <div>Titel</div>

            <div>
              <pre>
                <code>{opt.ticket?.Title}</code>
              </pre>
            </div>
            <div>Nachricht</div>
            <div>
              <pre>
                <code>{opt.ticket?.Message}</code>
              </pre>
            </div>
          </div>
          <hr />
          <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Nachricht</Label>
              <textarea
                className={`form-control${
                  formik.errors.message && formik.touched.message
                    ? ' is-invalid'
                    : ''
                }`}
                id={'message'}
                name={'message'}
                placeholder={'Nachricht eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />
              {formik.errors.message && formik.touched.message ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.message}
                </div>
              ) : (
                <></>
              )}
            </FormGroup>

            <div className={'d-flex'}>
              <div className={'p-2'}>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Antworten'}
                  disabled={!formik.isValid}
                  isSubmit={true}
                />
              </div>
              <div className={'p-2'}>
                <Button color={'danger'} onClick={() => opt.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
}
