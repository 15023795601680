import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

import { IModerator } from '../../models/moderator';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loadOnlineModerators } from '../../store/moderator/actions';
import RefreshButton from '../Common/RefreshButton';
import { loadOnlineCustomer } from '../../store/online-customer/actions';

import OnlineModeratorRow from './components/OnlineModeratorRow';

export default function OnlineModerators() {
  const dispatch = useAppDispatch();
  const onlineModerators = useAppSelector((state) => state.Moderator.online);
  const isLoading = useAppSelector((state) => state.Moderator.isLoadingOnline);

  useEffect(() => {
    dispatch(loadOnlineModerators());
  }, [dispatch]);

  return (
    <Card>
      <LoadingOverlay spinner active={isLoading}>
        <CardHeader className={'d-flex flex-row align-items-center'}>
          <div className={'mr-auto'}>
            <h4>Online Moderatoren</h4>
          </div>
          <div className={'ml-auto'}>
            <RefreshButton
              refreshAction={() => dispatch(loadOnlineModerators())}
            />
          </div>
        </CardHeader>
        <CardBody>
          <div className={'table-responsive'}>
            <table className={'table table-hover'}>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Agentur</td>
                  <td>Username</td>
                  <td>INs (heute)</td>
                  <td>OUTs (heute)</td>
                  <td>beendete / offene Dialoge</td>
                  <td>letzter Herzschlag</td>
                </tr>
              </thead>
              <tbody>
                {onlineModerators?.map((mod) => (
                  <OnlineModeratorRow moderator={mod} key={mod.ModeratorId} />
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
}
