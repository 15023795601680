import { IPresent } from '../../models/Present';
import { IBaseAction } from '../type';
import { IPagedResult, IPagedSearchRequest } from '../../models';

import {
  LOAD_PRESENTS,
  LOAD_PRESENTS_FAILED,
  SET_PRESENTS,
} from './actionTypes';

export function setPresents(
  presents: IPagedResult<IPresent>,
): IBaseAction<IPagedResult<IPresent>> {
  return {
    type: SET_PRESENTS,
    payload: presents,
  };
}

export function loadPresents(request: IPagedSearchRequest): IBaseAction<any> {
  return {
    type: LOAD_PRESENTS,
    payload: request,
  };
}

export function loadPresentsFailed(): IBaseAction<any> {
  return {
    type: LOAD_PRESENTS_FAILED,
    payload: null,
  };
}
