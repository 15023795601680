import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';

import { getCustomer } from '../../../services/customer.service';
import { ICustomer } from '../../../models/customer';
import { useAppSelector } from '../../../store/hooks';
import FlagImage from '../../../components/Common/FlagImage';

interface ICustomerDetailsOptions {
  userId: number | undefined;
}

export default function CustomerPrivateInformation(
  options: ICustomerDetailsOptions,
) {
  const [customer, setCustomer] = useState<ICustomer>();
  const token = useAppSelector((state) => state.Login.token);

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    const loadUser = async (userId: number) => {
      const user = await getCustomer(userId, token!, cancelToken.token);

      setCustomer(user);
    };

    loadUser(options.userId);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [options.userId]);

  const CustomerStatus = () => {
    if (customer?.IsDeleted) {
      return <Badge color={'danger'}>Gelöscht</Badge>;
    }

    if (customer?.IsModerate) {
      return <Badge color={'info'}>moderiertes Profil</Badge>;
    }

    if (!customer?.IsConfirmed) {
      return <Badge color={'warning'}>unbestätigte E-Mail-Adresse</Badge>;
    }

    return <Badge color={'success'}>aktiv</Badge>;
  };

  if (!customer) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Private Daten</CardHeader>
        <CardBody>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={'flex-fill'}>
      <CardHeader>Private Daten</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <div>
              <strong>Benutzername</strong>
              <div>{customer.Username}</div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>E-Mail-Adresse</strong>
              <div>{customer.Email}</div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Vorname</strong>
              <div>{customer.Firstname}</div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Nachname</strong>
              <div>{customer.Lastname}</div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Adresse</strong>
              <div>{!customer.Address ? 'keine Angabe' : customer.Address}</div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Land</strong>
              <div>
                <FlagImage country={customer.Country} />
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>PLZ</strong>
              <div>{!customer.ZipCode ? 'keine Angabe' : customer.ZipCode}</div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Ort</strong>
              <div>{customer.City}</div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Geburtsdatum</strong>
              <div>
                {moment(customer.BirthDate).format('DD.MM.YYYY')} (
                {moment().diff(moment(customer.BirthDate), 'years')} Jahre)
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>angemeldet am</strong>
              <div>
                {moment(customer.DateCreate).format('DD.MM.YYYY HH:mm')} Uhr
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Domain</strong>
              <div>{!customer.Domain ? 'keine Angabe' : customer.Domain}</div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Status</strong>
              <div>
                <CustomerStatus />
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
