import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { Divider } from '@material-ui/core';
import { setIn } from 'formik';

import {
  getCustomer,
  getCustomerInterest,
  getCustomerPublicInformation,
  getProfilePictureUrl,
} from '../../../services/customer.service';
import {
  ICustomer,
  ICustomerInterest,
  ICustomerPublicInformation,
} from '../../../models/customer';
import { useAppSelector } from '../../../store/hooks';
import FlagImage from '../../../components/Common/FlagImage';

interface ICustomerInterestsOptions {
  userId: number | undefined;
}

export default function CustomerInterests(options: ICustomerInterestsOptions) {
  const [isLoading, setIsLoading] = useState(false);
  const [interests, setInterests] = useState<ICustomerInterest | null>(null);
  const token = useAppSelector((state) => state.Login.token);

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    const loadInterests = async (userId: number) => {
      setIsLoading(true);
      try {
        const interest = await getCustomerInterest(
          userId,
          token!,
          cancelToken.token,
        );

        setInterests(interest);
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    loadInterests(options.userId);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [options.userId]);

  if (isLoading) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Interessen</CardHeader>
        <CardBody>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={'flex-fill'}>
      <CardHeader>
        <div className={'d-flex flex-wrap'}>
          <div>Interessen</div>
          <div className={'ml-auto'}>
            <a href={`/customers/${options.userId}/interest`}>Bearbeiten</a>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <div>
              <strong>Suche nach</strong>
              <ul>
                {interests?.LookForGender?.split(',').map((lf) => (
                  <li key={lf}>{lf}</li>
                ))}
              </ul>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Aufgeschlossen für</strong>
              <ul>
                {!interests?.LookForType ? (
                  <li>keine Angabe</li>
                ) : (
                  interests?.LookForType?.split(',').map((lf) => (
                    <li key={lf}>{lf}</li>
                  ))
                )}
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              <strong>Hobbies</strong>
              <ul>
                {!interests?.Hobbies ? (
                  <li>keine Angabe</li>
                ) : (
                  interests?.Hobbies?.split(',').map((lf) => (
                    <li key={lf}>{lf}</li>
                  ))
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
