import axios, { CancelToken } from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IPagedResult } from '../models';
import { IInvoice } from '../models/invoice';

export async function getCustomerInvoices(
  userId: number,
  page: number,
  size: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<IPagedResult<IInvoice>> {
  return (
    await axios.get<IPagedResult<IInvoice>>(
      `${environment.API_URL}/invoices/${userId}`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
        },
        cancelToken,
      }),
    )
  ).data;
}
