import { IPresent } from '../../models/Present';
import { IPagedResult } from '../../models';
import { IBaseAction } from '../type';

import {
  LOAD_PRESENTS,
  LOAD_PRESENTS_FAILED,
  SET_PRESENTS,
} from './actionTypes';

interface IPresentState {
  presents: IPagedResult<IPresent>;
  isLoading: boolean;
  isLoadingOnline: boolean;
}

const initialState: IPresentState = {
  presents: {
    Total: 0,
    Data: [],
  },
  isLoading: false,
  isLoadingOnline: false,
};

const Present = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_PRESENTS:
      state = {
        ...state,
        presents: action.payload,
        isLoading: false,
      };
      break;
    case LOAD_PRESENTS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOAD_PRESENTS_FAILED:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default Present;
