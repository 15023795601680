import React, { ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import LoadingButton from './LoadingButton';

export interface IConfirmDialogOptions {
  title: string;
  content: ReactElement | string;
  isOpen: boolean;
  close: Function;
  confirmCallback: () => Promise<void>;
  cancelCallback?: Function;
}

export function ConfirmDialog(opt: IConfirmDialogOptions) {
  const [isLoading, setIsLoading] = useState(false);

  return createPortal(
    <Modal isOpen={opt.isOpen} centered={true} backdrop={true}>
      <ModalHeader>{opt.title}</ModalHeader>
      <ModalBody>{opt.content}</ModalBody>
      <ModalFooter>
        <LoadingButton
          isLoading={isLoading}
          caption={'Bestätigen'}
          isSubmit={false}
          onClick={async () => {
            setIsLoading(true);

            try {
              await opt.confirmCallback();
            } catch (err) {
              console.error(err);
            }

            setIsLoading(false);
            opt.close();
          }}
        />
        <Button
          color={'danger'}
          onClick={() => {
            if (opt.cancelCallback) {
              opt.cancelCallback();
            }
            opt.close();
          }}
        >
          Abbrechen
        </Button>
      </ModalFooter>
    </Modal>,
    document.body,
  );
}
