import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppDispatch } from '../../store/hooks';
import {
  loadActiveDialogs,
  loadFinishedDialogs,
  loadInsToday,
  loadNewCustomersToday,
  loadOpenDialogs,
  loadPendingDialogs,
  loadTotalCoins,
} from '../../store/animation/actions';
import { loadRevenue } from '../../store/overview/statistic/actions';
import Box from '../../components/Common/Box';
import ActiveDialogs from '../../components/Dialog/ActiveDialogs';
import OnlineModerators from '../../components/Moderator/OnlineModerators';
import OpenDialogs from '../../components/Dialog/OpenDialogs';

export default function AnimationLive() {
  const [breadcrumbItems] = useState([
    { title: 'Animation', link: '#' },
    { title: 'Live Ansicht', link: '#' },
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadPendingDialogs());
    dispatch(loadTotalCoins());
    dispatch(loadInsToday());
    dispatch(loadNewCustomersToday());
    dispatch(loadRevenue());
    dispatch(loadOpenDialogs());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dialogübersicht"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Offene Dialoge'}
                type={'number'}
                loadingSelector={(state) =>
                  state.Animation.isLoadingPendingDialogs
                }
                selector={(state) => state.Animation.pendingDialogs}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Coins im Umlauf'}
                type={'number'}
                loadingSelector={(state) => state.Animation.isLoadingTotalCoins}
                selector={(state) => state.Animation.totalCoins}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'INs heute'}
                type={'number'}
                loadingSelector={(state) => state.Animation.isLoadingInsToday}
                selector={(state) => state.Animation.insToday}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Neukunden heute'}
                type={'number'}
                loadingSelector={(state) =>
                  state.Animation.isLoadingNewCustomerToday
                }
                selector={(state) => state.Animation.newCustomerToday}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Umsatz heute'}
                type={'currency'}
                loadingSelector={(state) =>
                  state.Statistic.loadingTodaysRevenue
                }
                selector={(state) => state.Statistic.todaysRevenue}
              />
            </div>
          </Row>

          <Row>
            <div className={'col-12'}>
              <OnlineModerators />
            </div>
          </Row>

          <Row>
            <div className={'col-12'}>
              <OpenDialogs />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
