import axios from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IActiveDialog } from '../models/animation';

export async function getFinishedDialogsCount(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/animation/finished-dialogs`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPendingDialogsCount(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/animation/pending-dialogs`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getTotalCoins(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/animation/total-coins`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getInsToday(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/animation/ins-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getNewCustomersToday(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/animation/new-customer-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getActiveDialogs(
  token: string,
): Promise<IActiveDialog[]> {
  return (
    await axios.get<IActiveDialog[]>(
      `${environment.API_URL}/animation/latest`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOpenDialogs(token: string): Promise<IActiveDialog[]> {
  return (
    await axios.get<IActiveDialog[]>(
      `${environment.API_URL}/animation/open`,
      getAxiosOptions(token),
    )
  ).data;
}
