import React, { useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LoadingButton from '../../../components/Common/LoadingButton';
import { IPaymentMethod } from '../../../models/payment-methods';
import { updatePaymentMethod } from '../../../services/payment-method.service';

interface IEditPaymentMethod {
  name: string;
  description: string;
}

interface IPaymentMethodEditModalOptions {
  isOpen: boolean;
  paymentMethod: IPaymentMethod | undefined;
  close: Function;
}

export default function PaymentMethodEditModal(
  opt: IPaymentMethodEditModalOptions,
) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const initialValues: IEditPaymentMethod = {
    name: opt.paymentMethod?.Name ?? '',
    description: opt.paymentMethod?.Description ?? '',
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onReset: (values) => {
      values.name = initialValues.name;
      values.description = initialValues.description;
    },
    onSubmit: async (values) => {
      await updatePaymentMethod(
        opt.paymentMethod?.PaymentMethodId!,
        values.name,
        values.description,
        token!,
      );

      toast.success('Die Zahlungsmethode wurde erfolgreich gespeichert.');

      opt.close();
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name || values.name === '') {
        errors.name = 'Bitte geben Sie einen Namen ein';
      }

      if (!values.description || values.description === '') {
        errors.description = 'Bitte geben Sie eine Beschreibung ein';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (opt.paymentMethod && formik) {
      formik.setValues({
        name: opt.paymentMethod.Name,
        description: opt.paymentMethod.Description,
      });
    }
  }, [opt.paymentMethod]);

  return (
    <FormikProvider value={formik}>
      <Modal centered={true} isOpen={opt.isOpen}>
        <ModalHeader>Zahlungsmethode bearbeiten</ModalHeader>
        <ModalBody>
          <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Name</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.name && formik.touched.name ? ' is-invalid' : ''
                }`}
                id={'name'}
                name={'name'}
                placeholder={'Name eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name ? (
                <div className={'invalid-feedback'}>{formik.errors.name}</div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Beschreibung</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.description && formik.touched.description
                    ? ' is-invalid'
                    : ''
                }`}
                id={'description'}
                name={'description'}
                placeholder={'Beschreibung eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.errors.description && formik.touched.description ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.description}
                </div>
              ) : (
                <></>
              )}
            </FormGroup>

            <div className={'d-flex'}>
              <div className={'p-2'}>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  isSubmit={true}
                />
              </div>
              <div className={'p-2'}>
                <Button color={'danger'} onClick={() => opt.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
}
