import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';

interface ICustomerSearchOptions {
  onSearch: (searchTerm: string) => void;
}

interface ICustomerSearchForm {
  searchTerm: string;
}

export default function CustomerSearch(options: ICustomerSearchOptions) {
  const initialValues: ICustomerSearchForm = {
    searchTerm: '',
  };

  const [isOpen, setIsOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const formik = useFormik({
    initialValues,
    onReset: (values) => {
      values.searchTerm = '';
      options.onSearch(values.searchTerm);
    },
    onSubmit: (values) => {
      options.onSearch(values.searchTerm);
    },
  });

  const focus = () => {
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <Card>
      <CardHeader
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: 'pointer' }}
      >
        Suche
      </CardHeader>
      <Collapse isOpen={isOpen} onEntered={() => focus()}>
        <CardBody>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup row>
                <Label sm={2}>Benutzername suchen</Label>
                <Col sm={10}>
                  <Field
                    type={'text'}
                    name={'searchTerm'}
                    className={`form-control${
                      formik.errors.searchTerm && formik.touched.searchTerm
                        ? ' is-invalid'
                        : ''
                    }`}
                    innerRef={searchInputRef}
                    autoFocus={true}
                  />
                  <ErrorMessage
                    name={'searchTerm'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </Col>
              </FormGroup>
              <div className={'d-flex flex-wrap'}>
                <div>
                  <Button color={'primary'}>Suchen</Button>
                </div>
                <div className={'ml-2'}>
                  <Button
                    color={'secondary'}
                    onClick={() => formik.resetForm()}
                  >
                    Zurücksetzen
                  </Button>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </CardBody>
      </Collapse>
    </Card>
  );
}
