import { all } from 'redux-saga/effects';

// public
import loginSaga from './auth/login/saga';
import LayoutSaga from './layout/saga';
import statisticSaga from './overview/statistic/saga';
import onlineCustomerSaga from './online-customer/saga';
import animationSaga from './animation/saga';
import moderatorSaga from './moderator/saga';
import agencySaga from './agency/saga';
import customerSaga from './customer/saga';
import presentSaga from './present/saga';
import paymentMethodsSaga from './payment-methods/saga';
import coinPackagesSaga from './coin-packages/saga';
import supportSaga from './support/saga';

export default function* rootSaga() {
  yield all([
    // public
    loginSaga(),
    LayoutSaga(),
    statisticSaga(),
    onlineCustomerSaga(),
    animationSaga(),
    moderatorSaga(),
    agencySaga(),
    customerSaga(),
    presentSaga(),
    paymentMethodsSaga(),
    coinPackagesSaga(),
    supportSaga(),
  ]);
}
