import axios, { CancelToken } from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import {
  ICustomer,
  ICustomerCoinInformation,
  ICustomerHost,
  ICustomerInterest,
  ICustomerPicture,
  ICustomerPresent,
  ICustomerProfilePicture,
  ICustomerPublicInformation,
  ICustomerVisit,
  ICustomerVoiceDescription,
  ICustomerWishPresent,
  IOnlineCustomer,
} from '../models/customer';

export function getVoiceDescriptionPlayUrl(
  userId: number,
  userVoiceDescriptionId: number,
): string {
  return `${environment.API_URL}/user/${userId}/voice-description/${userVoiceDescriptionId}/play`;
}

export async function getPendingVoiceDescriptions(
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerVoiceDescription[]> {
  return (
    await axios.get<ICustomerVoiceDescription[]>(
      `${environment.API_URL}/user/pending-voice-descriptions`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getPendingProfilePictures(
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerProfilePicture[]> {
  return (
    await axios.get<ICustomerProfilePicture[]>(
      `${environment.API_URL}/tools/pending-profile-pictures`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export function getPendingProfilePictureURL(userId: number): string {
  return `${environment.API_URL}/tools/pending-profile-pictures/${userId}/preview`;
}

export async function getCustomerVoiceDescriptions(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerVoiceDescription[]> {
  return (
    await axios.get<ICustomerVoiceDescription[]>(
      `${environment.API_URL}/user/${userId}/voice-description`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerCoinInformation(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerCoinInformation> {
  return (
    await axios.get<ICustomerCoinInformation>(
      `${environment.API_URL}/user/${userId}/coin-info`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerInterest(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerInterest> {
  return (
    await axios.get<ICustomerInterest>(
      `${environment.API_URL}/user/${userId}/interest`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerPublicInformation(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerPublicInformation | null> {
  return (
    await axios.get<ICustomerPublicInformation | null>(
      `${environment.API_URL}/user/${userId}/public-information`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerPresents(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerPresent[]> {
  return (
    await axios.get<ICustomerPresent[]>(
      `${environment.API_URL}/user/${userId}/presents`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerWishPresents(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerWishPresent[]> {
  return (
    await axios.get<ICustomerWishPresent[]>(
      `${environment.API_URL}/user/${userId}/wish-presents`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerVisitedProfiles(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerVisit[]> {
  return (
    await axios.get<ICustomerVisit[]>(
      `${environment.API_URL}/user/${userId}/visited-profiles`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerConversations(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<number[]> {
  return (
    await axios.get<number[]>(
      `${environment.API_URL}/user/${userId}/conversations`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function findCustomers(
  usernameOrEmail: string,
  moderatedUserOnly: boolean = false,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomer[]> {
  if (moderatedUserOnly) {
    return (
      await axios.get<ICustomer[]>(
        `${environment.API_URL}/user/search-moderated/${usernameOrEmail}`,
        getAxiosOptions(token, {
          cancelToken,
        }),
      )
    ).data;
  }

  return (
    await axios.get<ICustomer[]>(
      `${environment.API_URL}/user/search/${usernameOrEmail}`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerPictures(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerPicture[]> {
  return (
    await axios.get<ICustomerPicture[]>(
      `${environment.API_URL}/user/${userId}/pictures`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getCustomerPictureUrl(
  userId: number,
  pictureId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<string> {
  const pictureData = (
    await axios.get(
      `${environment.API_URL}/user/${userId}/picture/${pictureId}`,
      getAxiosOptions(token, {
        responseType: 'blob',
        cancelToken,
      }),
    )
  ).data;

  return URL.createObjectURL(pictureData);
}

export async function getOnlineCustomers(
  token: string,
): Promise<IOnlineCustomer[]> {
  return (
    await axios.get<IOnlineCustomer[]>(
      `${environment.API_URL}/user`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getDeletedCustomers(
  page: number,
  size: number,
  username: string | null,
  token: string,
): Promise<ICustomer[]> {
  return (
    await axios.get<ICustomer[]>(
      `${environment.API_URL}/user/deleted`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
          username,
        },
      }),
    )
  ).data;
}

export async function getUnconfirmedCustomers(
  page: number,
  size: number,
  username: string | null,
  token: string,
): Promise<ICustomer[]> {
  return (
    await axios.get<ICustomer[]>(
      `${environment.API_URL}/user/unconfirmed`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
          username,
        },
      }),
    )
  ).data;
}

export async function getConfirmedCustomers(
  page: number,
  size: number,
  username: string | null,
  token: string,
): Promise<ICustomer[]> {
  return (
    await axios.get<ICustomer[]>(
      `${environment.API_URL}/user/confirmed`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
          username,
        },
      }),
    )
  ).data;
}

export async function getModeratedCustomers(
  page: number,
  size: number,
  username: string | null,
  token: string,
): Promise<ICustomer[]> {
  return (
    await axios.get<ICustomer[]>(
      `${environment.API_URL}/user/moderated`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
          username,
        },
      }),
    )
  ).data;
}

export function getProfilePictureUrl(id: number): string {
  return `${environment.API_URL}/user/${id}/profile-picture`;
}

export async function getCustomer(
  id: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomer> {
  return (
    await axios.get<ICustomer>(
      `${environment.API_URL}/user/${id}`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getLastLogin(
  id: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<Date | null> {
  return (
    await axios.get<Date | null>(
      `${environment.API_URL}/user/${id}/last-login`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function updateUser(
  id: number,
  customer: ICustomer,
  token: string,
): Promise<void> {
  return axios.put(
    `${environment.API_URL}/user/${id}`,
    customer,
    getAxiosOptions(token),
  );
}

export async function updateUserPublicInformation(
  id: number,
  publicInformation: ICustomerPublicInformation,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  return axios.put(
    `${environment.API_URL}/user/${id}/public-information`,
    publicInformation,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function uploadUserPicture(
  id: number,
  image: File,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  const axiosOptions = getAxiosOptions(token, {
    cancelToken,
  });

  const formData = new FormData();
  formData.append('image', image);

  await axios.post(`${environment.API_URL}/user/${id}/picture`, formData, {
    ...axiosOptions,
    headers: {
      ...axiosOptions.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function addVoiceDescription(
  userId: number,
  title: string,
  audioFile: File,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  const axiosOptions = getAxiosOptions(token, {
    cancelToken,
  });

  const formData = new FormData();
  formData.append('title', title);
  formData.append('audio', audioFile);

  await axios.post(
    `${environment.API_URL}/user/${userId}/add-voice-description`,
    formData,
    {
      ...axiosOptions,
      headers: {
        ...axiosOptions.headers,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

export async function updateUserInterest(
  id: number,
  lookForGender: string[],
  lookForType: string[],
  hobbies: string[],
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  const _lookForGender = lookForGender.join(',');
  const _lookForType = lookForType.join(',');
  const _hobbies = hobbies.join(',');

  return axios.put(
    `${environment.API_URL}/user/${id}/interest`,
    {
      LookForGender: _lookForGender,
      LookForType: _lookForType,
      Hobbies: _hobbies,
    },
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function deleteUser(id: number, token: string): Promise<void> {
  return axios.delete(
    `${environment.API_URL}/user/${id}`,
    getAxiosOptions(token),
  );
}

export async function deleteUserPicture(
  userPictureId: number,
  token: string,
): Promise<void> {
  return axios.delete(
    `${environment.API_URL}/user/picture/${userPictureId}`,
    getAxiosOptions(token),
  );
}

export async function deleteVoiceDescription(
  userId: number,
  userVoiceDescriptionId: number,
  token: string,
): Promise<void> {
  return axios.delete(
    `${environment.API_URL}/user/${userId}/voice-description/${userVoiceDescriptionId}`,
    getAxiosOptions(token),
  );
}

export async function approveVoiceDescription(
  userId: number,
  userVoiceDescriptionId: number,
  token: string,
): Promise<void> {
  return axios.post(
    `${environment.API_URL}/user/${userId}/voice-description/${userVoiceDescriptionId}/approve`,
    {},
    getAxiosOptions(token),
  );
}

export async function disapproveVoiceDescription(
  userId: number,
  userVoiceDescriptionId: number,
  token: string,
): Promise<void> {
  return axios.post(
    `${environment.API_URL}/user/${userId}/voice-description/${userVoiceDescriptionId}/disapprove`,
    {},
    getAxiosOptions(token),
  );
}

export async function approveProfilePicture(
  userId: number,
  token: string,
): Promise<void> {
  return axios.post(
    `${environment.API_URL}/tools/pending-profile-pictures/${userId}/approve`,
    {},
    getAxiosOptions(token),
  );
}

export async function rejectProfilePicture(
  userId: number,
  token: string,
): Promise<void> {
  return axios.post(
    `${environment.API_URL}/tools/pending-profile-pictures/${userId}/reject`,
    {},
    getAxiosOptions(token),
  );
}

export async function restoreUser(id: number, token: string): Promise<void> {
  return axios.post(
    `${environment.API_URL}/user/${id}/restore`,
    {},
    getAxiosOptions(token),
  );
}

export async function modifyCoins(
  id: number,
  coinCount: number,
  token: string,
): Promise<void> {
  return axios.post(
    `${environment.API_URL}/user/${id}/modify-coins/${coinCount}`,
    {},
    getAxiosOptions(token),
  );
}

export async function setProfilePicture(
  id: number,
  picture: File,
  token: string,
): Promise<void> {
  const formData = new FormData();
  formData.append('picture', picture);

  await axios.post(
    `${environment.API_URL}/user/${id}/profile-picture`,
    formData,
    getAxiosOptions(token),
  );
}

export async function emailAlreadyInUse(
  email: string,
  userId: number | undefined,
  token: string,
): Promise<boolean> {
  return (
    await axios.post<{
      exists: boolean;
    }>(
      `${environment.API_URL}/user/email-exists`,
      {
        email,
        userId,
      },
      getAxiosOptions(token),
    )
  ).data.exists;
}

export async function usernameAlreadyInUse(
  username: string,
  userId: number | undefined,
  token: string,
): Promise<boolean> {
  return (
    await axios.post<{
      exists: boolean;
    }>(
      `${environment.API_URL}/user/username-exists`,
      {
        username,
        userId,
      },
      getAxiosOptions(token),
    )
  ).data.exists;
}

export async function getDoubleIPHosts(
  token: string,
  cancelToken?: CancelToken,
): Promise<ICustomerHost[]> {
  return (
    await axios.get<ICustomerHost[]>(
      `${environment.API_URL}/user/double-ip`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getConfirmationUrl(
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<string> {
  const { data } = await axios.get<{
    url: string;
  }>(
    `${environment.API_URL}/user/${userId}/confirmation-url`,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );

  console.log(data.url);
  return data.url;
}
