import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { Emojione } from 'react-emoji-render';

import { IMessage } from '../../../models/message';
import { useAppSelector } from '../../../store/hooks';
import { getMessages } from '../../../services/message.service';

interface IActiveDialogMessagesOptions {
  userId: number | undefined;
  fakeId: number | undefined;
}

export default function ActiveDialogMessages(
  opt: IActiveDialogMessagesOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    let cancel = false;

    if (!opt.userId || !opt.fakeId) {
      setMessages([]);
      return;
    }

    const loadMessages = async () => {
      setIsLoading(true);

      const messages = await getMessages(opt.userId!, opt.fakeId!, token!);

      if (cancel) return;

      setMessages(messages);
      setIsLoading(false);
    };

    loadMessages();

    return () => {
      cancel = true;
    };
  }, [opt.userId, opt.fakeId, token]);

  return (
    <LoadingOverlay spinner active={isLoading}>
      <div className={'d-flex flex-column justify-content-stretch mt-2'}>
        {messages.map((msg) => (
          <div key={msg.MessageId}>
            <div className={'d-flex flex-md-row flex-column mt-2'}>
              <div className={'d-flex flex-column flex-grow-1'}>
                <strong>
                  {msg.SenderUserId === opt.fakeId ? 'Wir' : 'Kunde'}
                </strong>
                <small>
                  {msg.MessageContent?.Content && (
                    <Emojione text={msg.MessageContent?.Content} />
                  )}
                  {msg.MessageContent?.CloudVoiceMessageId && (
                    <small>Sprachnachricht</small>
                  )}
                </small>
              </div>
              <div className={'ml-auto'}>
                <small>
                  {moment(msg.DateCreate).format('dddd, DD.MMMM YYYY HH:mm')}
                </small>
              </div>
            </div>
            <hr className={'w-100'} />
          </div>
        ))}
      </div>
    </LoadingOverlay>
  );
}
