import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import axios from 'axios';

import { useAppSelector } from '../../../store/hooks';
import { getPresentPictureUrl } from '../../../services/present.service';
import {
  deleteUserPicture,
  getCustomerPictureUrl,
} from '../../../services/customer.service';
import LoadingButton from '../../../components/Common/LoadingButton';

interface ICustomerPictureOptions {
  userId: number;
  pictureId: number;
  onPictureDeleted?: () => void;
}

export default function CustomerPicture(opt: ICustomerPictureOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const [pictureUrl, setPictureUrl] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadCustomerPicture = async function (
      userId: number,
      pictureId: number,
    ) {
      const imageUrl = await getCustomerPictureUrl(
        userId,
        pictureId,
        token!,
        cancelToken.token,
      );

      setPictureUrl(imageUrl);
    };

    loadCustomerPicture(opt.userId, opt.pictureId);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [opt, token]);

  const deletePicture = async () => {
    setIsDeleting(true);

    await deleteUserPicture(opt.pictureId, token!);

    if (opt.onPictureDeleted) {
      opt.onPictureDeleted();
    }

    setIsDeleting(false);
  };

  if (pictureUrl) {
    return (
      <div className={'d-flex flex-column'}>
        <img src={pictureUrl} height={80} alt={'Kunde Bild'} />
        <LoadingButton
          isLoading={isDeleting}
          caption={'Löschen'}
          isSubmit={false}
          onClick={async () => deletePicture()}
          color={'danger'}
          className={'btn-sm mt-2'}
        />
      </div>
    );
  }

  return <Spinner size={'sm'} />;
}
