import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { redirectTo } from '../../store/redirect/actions';

export default function RedirectListener() {
  const redirectPath = useAppSelector((state) => state.Redirect.redirectTo);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (redirectPath) {
      history.push(redirectPath);
      dispatch(redirectTo(null));
    }
  }, [redirectPath, dispatch, history]);

  return <></>;
}
