import { IPagedResult } from '../../models';
import { IBaseAction } from '../type';
import { ICustomer } from '../../models/customer';

import {
  LOAD_CONFIRMED_CUSTOMERS,
  LOAD_CONFIRMED_CUSTOMERS_FAILED,
  LOAD_DELETED_CUSTOMERS,
  LOAD_DELETED_CUSTOMERS_FAILED,
  LOAD_MODERATED_CUSTOMERS,
  LOAD_MODERATED_CUSTOMERS_FAILED,
  LOAD_UNCONFIRMED_CUSTOMERS,
  SET_CONFIRMED_CUSTOMERS,
  SET_DELETED_CUSTOMERS,
  SET_MODERATED_CUSTOMERS,
  SET_UNCONFIRMED_CUSTOMERS,
} from './actionTypes';

export function setModeratedCustomers(
  data: IPagedResult<ICustomer>,
): IBaseAction<IPagedResult<ICustomer>> {
  return {
    type: SET_MODERATED_CUSTOMERS,
    payload: data,
  };
}

export function setConfirmedCustomers(
  data: IPagedResult<ICustomer>,
): IBaseAction<IPagedResult<ICustomer>> {
  return {
    type: SET_CONFIRMED_CUSTOMERS,
    payload: data,
  };
}

export function setUnconfirmedCustomers(
  data: IPagedResult<ICustomer>,
): IBaseAction<IPagedResult<ICustomer>> {
  return {
    type: SET_UNCONFIRMED_CUSTOMERS,
    payload: data,
  };
}

export function setDeletedCustomers(
  data: IPagedResult<ICustomer>,
): IBaseAction<IPagedResult<ICustomer>> {
  return {
    type: SET_DELETED_CUSTOMERS,
    payload: data,
  };
}

export function loadModeratedCustomersFailed(): IBaseAction<any> {
  return {
    type: LOAD_MODERATED_CUSTOMERS_FAILED,
    payload: null,
  };
}

export function loadConfirmedCustomersFailed(): IBaseAction<any> {
  return {
    type: LOAD_CONFIRMED_CUSTOMERS_FAILED,
    payload: null,
  };
}

export function loadUnconfirmedCustomersFailed(): IBaseAction<any> {
  return {
    type: LOAD_CONFIRMED_CUSTOMERS_FAILED,
    payload: null,
  };
}

export function loadDeletedCustomersFailed(): IBaseAction<any> {
  return {
    type: LOAD_DELETED_CUSTOMERS_FAILED,
    payload: null,
  };
}

export function loadConfirmedCustomers(): IBaseAction<any> {
  return {
    type: LOAD_CONFIRMED_CUSTOMERS,
    payload: null,
  };
}

export function loadModeratedCustomers(): IBaseAction<any> {
  return {
    type: LOAD_MODERATED_CUSTOMERS,
    payload: null,
  };
}

export function loadUnconfirmedCustomers(): IBaseAction<any> {
  return {
    type: LOAD_UNCONFIRMED_CUSTOMERS,
    payload: null,
  };
}

export function loadDeletedCustomers(): IBaseAction<any> {
  return {
    type: LOAD_DELETED_CUSTOMERS,
    payload: null,
  };
}
