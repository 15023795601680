import axios, { CancelToken } from 'axios';

import environment from '../helpers/environment';
import { IMessage, IMessageContent } from '../models/message';
import getAxiosOptions from '../helpers/axios_helper';

export async function getMessages(
  userId: number,
  partnerId: number,
  token: string,
): Promise<IMessage[]> {
  return (
    await axios.get<IMessage[]>(
      `${environment.API_URL}/user/${userId}/${partnerId}/messages`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPictureMessages(
  userId: number,
  partnerId: number,
  token: string,
): Promise<IMessage[]> {
  return (
    await axios.get<IMessage[]>(
      `${environment.API_URL}/user/${userId}/${partnerId}/picture-messages`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getMessagePictureUrl(
  messageId: number,
  token: string,
): Promise<string> {
  const pictureData = (
    await axios.get(
      `${environment.API_URL}/message/${messageId}/picture`,
      getAxiosOptions(token, { responseType: 'blob' }),
    )
  ).data;

  return URL.createObjectURL(pictureData);
}

export async function getTotalMessages(
  userId: number,
  partnerId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/user/${userId}/${partnerId}/total-messages`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getTotalPictures(
  userId: number,
  partnerId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/user/${userId}/${partnerId}/total-picture-messages`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getMessageContent(
  messageId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<IMessageContent> {
  return (
    await axios.get<IMessageContent>(
      `${environment.API_URL}/message/${messageId}`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getLatestMessages(
  token: string,
  cancelToken?: CancelToken,
): Promise<IMessage[]> {
  return (
    await axios.get<IMessage[]>(
      `${environment.API_URL}/message/latest-messages`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getPairedMessageFrom(
  messageId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<IMessage | null> {
  return (
    await axios.get<IMessage | null>(
      `${environment.API_URL}/message/${messageId}/pair`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function sendMessage(
  senderUserId: number,
  receiverUserId: number,
  message: string | null,
  image: File | null,
  voiceMessage: File | null,
  token: string,
): Promise<void> {
  const formData = new FormData();
  if (message) {
    formData.append('message', message);
  }
  if (image) {
    formData.append('image', image);
  }
  if (voiceMessage) {
    formData.append('voiceMessage', voiceMessage);
  }

  await axios.post(
    `${environment.API_URL}/message/send/${senderUserId}/${receiverUserId}`,
    formData,
    getAxiosOptions(token),
  );
}
