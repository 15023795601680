import { IModerator } from '../../models/moderator';
import { IBaseAction } from '../type';
import { IPagedRequest, IPagedResult } from '../../models';
import { IAgency } from '../../models/agency';
import {
  LOAD_AGENCIES,
  LOAD_AGENCIES_FAILED,
  SET_AGENCIES,
} from '../agency/actionTypes';

import {
  LOAD_MODERATORS,
  LOAD_MODERATORS_FAILED,
  LOAD_ONLINE_MODERATORS,
  LOAD_ONLINE_MODERATORS_FAILED,
  SET_MODERATORS,
  SET_ONLINE_MODERATORS,
} from './actionTypes';

export function setOnlineModerators(
  moderators: IModerator[],
): IBaseAction<IModerator[]> {
  return {
    type: SET_ONLINE_MODERATORS,
    payload: moderators,
  };
}

export function loadOnlineModerators(): IBaseAction<any> {
  return {
    type: LOAD_ONLINE_MODERATORS,
    payload: null,
  };
}

export function loadOnlineModeratorsFailed(): IBaseAction<any> {
  return {
    type: LOAD_ONLINE_MODERATORS_FAILED,
    payload: null,
  };
}

export function setModerators(
  agencies: IPagedResult<IModerator>,
): IBaseAction<IPagedResult<IModerator>> {
  return {
    type: SET_MODERATORS,
    payload: agencies,
  };
}

export function loadModerators(
  request: IPagedRequest,
): IBaseAction<IPagedRequest> {
  return {
    type: LOAD_MODERATORS,
    payload: request,
  };
}

export function loadModeratorsFailed(): IBaseAction<any> {
  return {
    type: LOAD_MODERATORS_FAILED,
    payload: null,
  };
}
