import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomerSelectModal from '../../components/Customer/CustomerSelectModal';
import CustomerName from '../../components/Customer/CustomerName';
import ActiveDialogHistory from '../../components/Dialog/components/ActiveDialogHistory';
import { useAppSelector } from '../../store/hooks';
import LoadingButton from '../../components/Common/LoadingButton';
import { sendMessage } from '../../services/message.service';

type SendMessageParams = {
  senderUserId?: string;
  receiverUserId: string;
};

export default function SendMessage() {
  const { senderUserId: paramSenderId, receiverUserId: paramReceiverId } =
    useParams<SendMessageParams>();

  const [breadcrumbItems] = useState([
    { title: 'Nachrichten', link: '#' },
    { title: 'Versand', link: '#' },
  ]);

  const [isSelectSenderModalOpen, setIsSelectSenderModalOpen] = useState(false);
  const [isSelectReceiverModalOpen, setIsSelectReceiverModalOpen] =
    useState(false);

  const [senderUserId, setSenderUserId] = useState<number>();
  const [receiverUserId, setReceiverUserId] = useState<number>();

  const [isInitialSet, setIsInitialSet] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setIsInitialSet(true);

    if (paramSenderId) {
      setSenderUserId(parseInt(paramSenderId));
    }

    if (paramReceiverId) {
      setReceiverUserId(parseInt(paramReceiverId));
    }

    setIsInitialSet(false);

    if (!paramSenderId) {
      setIsSelectReceiverModalOpen(false);
      setIsSelectSenderModalOpen(true);
    }

    if (paramSenderId && !paramReceiverId) {
      setIsSelectSenderModalOpen(false);
      setIsSelectReceiverModalOpen(true);
    }
  }, [paramSenderId, paramReceiverId]);

  useEffect(() => {
    if (isInitialSet) return;

    if (!senderUserId) {
      toast.warning('Sie müssen einen Absender auswählen!', {
        toastId: 'sender',
      });
      setIsSelectSenderModalOpen(true);
      return;
    }

    setIsSelectSenderModalOpen(false);

    if (!receiverUserId) {
      setIsSelectReceiverModalOpen(true);
    }
  }, [senderUserId, isSelectSenderModalOpen]);

  useEffect(() => {
    if (isInitialSet) return;

    if (!receiverUserId) {
      toast.warning('Sie müssen einen Empfänger auswählen!', {
        toastId: 'receiver',
      });
      setIsSelectSenderModalOpen(false);
      setIsSelectReceiverModalOpen(true);
      return;
    }

    setIsSelectReceiverModalOpen(false);
  }, [receiverUserId, isSelectReceiverModalOpen]);

  const token = useAppSelector((state) => state.Login.token);

  const initialValues: {
    message?: string;
  } = {
    message: undefined,
  };

  const [messageImage, setMessageImage] = useState<File>();
  const [voiceMessage, setVoiceMessage] = useState<File>();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    validateOnMount: true,
    onReset: (values) => {
      values.message = undefined;
    },
    onSubmit: async (values) => {
      if (!senderUserId || !receiverUserId) return;

      try {
        await sendMessage(
          senderUserId,
          receiverUserId,
          values.message ?? null,
          messageImage ?? null,
          voiceMessage ?? null,
          token!,
        );

        toast.success('Die Nachricht wurde erfolgreich versandt.');
        history.goBack();
      } catch (err) {
        toast.error('Beim Versenden der Nachricht ist ein Fehler aufgetreten.');
      }
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.message && !messageImage && !voiceMessage) {
        errors.message =
          'Sie müssen entweder ein Bild, Sprachnachricht auswählen oder eine Nachricht eingeben.';
      }

      return errors;
    },
  });

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Nachricht versenden"
            breadcrumbItems={breadcrumbItems}
          />

          <CustomerSelectModal
            isOpen={isSelectSenderModalOpen}
            title={'Absender auswählen'}
            onlyModerated={true}
            toggle={() => {
              setIsSelectSenderModalOpen(!isSelectSenderModalOpen);
            }}
            onSelect={(customer) => setSenderUserId(customer.UserId)}
          />

          <CustomerSelectModal
            isOpen={isSelectReceiverModalOpen}
            title={'Empfänger auswählen'}
            toggle={() => {
              setIsSelectReceiverModalOpen(!isSelectReceiverModalOpen);
            }}
            onSelect={(customer) => setReceiverUserId(customer.UserId)}
          />

          <Row>
            <Col sm={12}>
              <Card>
                <CardHeader>Empfänger / Absender</CardHeader>
                <CardBody>
                  <div className={'d-flex flex-wrap'}>
                    <div className={'d-flex flex-column flex-wrap p-2'}>
                      <strong>Empfänger</strong>

                      {receiverUserId ? (
                        <CustomerName userId={receiverUserId} />
                      ) : (
                        'Empfänger auswählen'
                      )}
                    </div>
                    <div className={'d-flex flex-column flex-wrap p-2'}>
                      <strong>Absender</strong>
                      {senderUserId ? (
                        <CustomerName userId={senderUserId} />
                      ) : (
                        'Absender auswählen'
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col
              sm={12}
              style={{
                display: senderUserId && receiverUserId ? 'block' : 'none',
              }}
            >
              <Card>
                <CardHeader>Nachricht versenden</CardHeader>
                <CardBody>
                  <FormikProvider value={formik}>
                    <Form
                      className={'form-horizontal'}
                      onSubmit={formik.handleSubmit}
                    >
                      <FormGroup>
                        <Label>Nachricht</Label>
                        <Field
                          type={'text'}
                          as={'textarea'}
                          rows={5}
                          name={'message'}
                          placeholder={'Nachricht eingeben'}
                          className={`form-control${
                            formik.errors.message && formik.touched.message
                              ? ' is-invalid'
                              : ''
                          }`}
                        />
                        {formik.errors.message && formik.touched.message ? (
                          <div className={'invalid-feedback'}>
                            {formik.errors.message}
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label>Bild</Label>
                        <input
                          type={'file'}
                          className={`form-control${
                            formik.errors.message && formik.touched.message
                              ? ' is-invalid'
                              : ''
                          }`}
                          id={'file'}
                          name={'file'}
                          accept={'image/*'}
                          placeholder={'Bild auswählen'}
                          onChange={(ev) => {
                            if (
                              ev.currentTarget &&
                              ev.currentTarget.files &&
                              ev.currentTarget.files.length > 0
                            ) {
                              setMessageImage(ev.currentTarget.files[0]);
                            } else {
                              setMessageImage(undefined);
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.message && formik.touched.message ? (
                          <div className={'invalid-feedback'}>
                            {formik.errors.message}
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label>Sprachnachricht</Label>
                        <input
                          type={'file'}
                          accept={'audio/*'}
                          className={`form-control${
                            formik.errors.message && formik.touched.message
                              ? ' is-invalid'
                              : ''
                          }`}
                          id={'voice'}
                          name={'voice'}
                          placeholder={'Sprachnachricht auswählen'}
                          onChange={(ev) => {
                            if (
                              ev.currentTarget &&
                              ev.currentTarget.files &&
                              ev.currentTarget.files.length > 0
                            ) {
                              setVoiceMessage(ev.currentTarget.files[0]);
                            } else {
                              setVoiceMessage(undefined);
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.message && formik.touched.message ? (
                          <div className={'invalid-feedback'}>
                            {formik.errors.message}
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormGroup>

                      <LoadingButton
                        isLoading={formik.isSubmitting}
                        caption={'Versenden'}
                        disabled={!formik.isValid}
                        isSubmit={true}
                      />
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>

            <Col
              sm={12}
              style={{
                display: senderUserId && receiverUserId ? 'block' : 'none',
              }}
            >
              <Card>
                <CardHeader>Verlauf</CardHeader>
                <CardBody>
                  {senderUserId && receiverUserId ? (
                    <ActiveDialogHistory
                      userId={senderUserId}
                      fakeId={receiverUserId}
                    />
                  ) : (
                    ''
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
