import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';

import IPChecker from './ip_checker';
import MassMessage from './mass_message';
import Asa from './asa';
import PendingVoiceDescriptionsPage from './pending_voice_descriptions';
import PendingProfilePicturesPage from './pending_profile_pictures';

export default function ToolsPage() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/ip-checker`}>
          <IPChecker />
        </Route>
        <Route path={`${match.path}/mass-message`}>
          <MassMessage />
        </Route>
        <Route path={`${match.path}/asa`}>
          <Asa />
        </Route>
        <Route path={`${match.path}/pending-voice-descriptions`}>
          <PendingVoiceDescriptionsPage />
        </Route>
        <Route path={`${match.path}/pending-profile-pictures`}>
          <PendingProfilePicturesPage />
        </Route>
      </Switch>
    </>
  );
}
