import React, { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAppSelector } from '../store/hooks';

export type AppRouteProps = {
  isProtected: boolean;
  authenticationPath: string;
  layout: ComponentType;
  component: ComponentType;
} & RouteProps;

export default function AppRoute({
  isProtected,
  authenticationPath,
  layout,
  component,
  ...routeProps
}: AppRouteProps) {
  const isLoggedIn = useAppSelector((state) => state.Login.isLoggedIn);
  const token = useAppSelector((state) => state.Login.token);

  const LayoutComp = layout;
  const Comp = component;

  console.log(`navigation to:${routeProps.path}`);

  if (isProtected && (!isLoggedIn || !token)) {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }

  return (
    <Route
      {...routeProps}
      render={(props) => (
        <LayoutComp>
          <Comp {...props} />
        </LayoutComp>
      )}
    />
  );
}
