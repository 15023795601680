import { createPortal } from 'react-dom';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import LoadingButton from '../Common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setProfilePicture } from '../../services/customer.service';

interface ICustomerEditProfilePictureModalOptions {
  userId: number;
  isOpen: boolean;
  toggle: () => void;
  onAdded?: () => void;
}

export default function CustomerEditProfilePictureModal(
  opt: ICustomerEditProfilePictureModalOptions,
) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const [addProfilePicture, setAddProfilePicture] = useState<File>();

  const formik = useFormik({
    initialValues: {},
    onReset: (values) => {},
    validate: (values) => {
      const err = {} as any;

      return err;
    },
    onSubmit: async (values) => {
      try {
        if (!addProfilePicture) return;

        await setProfilePicture(opt.userId, addProfilePicture, token!);

        toast.success('Das Profilbild wurde erfolgreich gespeichert.');

        if (opt.onAdded) opt.onAdded();

        opt.toggle();
        formik.resetForm();
      } catch (err) {
        toast.error('Fehler beim Speichern des Profilbilds.');
      }
    },
  });

  return createPortal(
    <Modal
      isOpen={opt.isOpen}
      toggle={() => opt.toggle()}
      centered={true}
      backdrop={true}
    >
      <ModalHeader toggle={() => opt.toggle()}>
        Profilbild aktualisieren
      </ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Bild</Label>
              <input
                type={'file'}
                className={'form-control'}
                id={'file'}
                name={'file'}
                placeholder={'Bild auswählen'}
                accept={'image/*'}
                onChange={(ev) => {
                  if (
                    ev.currentTarget &&
                    ev.currentTarget.files &&
                    ev.currentTarget.files.length > 0
                  ) {
                    setAddProfilePicture(ev.currentTarget.files[0]);
                  } else {
                    setAddProfilePicture(undefined);
                  }
                }}
                onBlur={formik.handleBlur}
              />
            </FormGroup>

            <div className={'d-flex'}>
              <div className={'p-2'}>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  disabled={!formik.isValid || !addProfilePicture}
                  isSubmit={true}
                />
              </div>
              <div className={'p-2'}>
                <Button color={'danger'} onClick={() => opt.toggle()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
