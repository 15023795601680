import React, { useEffect, useState } from 'react';
import { GridApiRef, GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import LoadingButton from '../../../components/Common/LoadingButton';
import { createAgency } from '../../../services/agency.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadAgencies } from '../../../store/agency/actions';
import { createModerator } from '../../../services/moderator.service';
import { loadModerators } from '../../../store/moderator/actions';

interface IAddAgency {
  agencyId: number;
  username: string;
  password: string;
  skype: string | undefined;
  email: string;
}

export default function ModeratorTableToolbar() {
  const token = useAppSelector((state) => state.Login.token);
  const agencies = useAppSelector((state) => state.Agency.agencies.Data);

  const dispatch = useAppDispatch();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const initialValues: IAddAgency = {
    username: '',
    agencyId: 0,
    email: '',
    skype: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    onReset: (values) => {
      values.username = initialValues.username;
      values.agencyId = initialValues.agencyId;
      values.email = initialValues.email;
      values.skype = initialValues.skype;
      values.password = initialValues.password;
    },
    onSubmit: async (values) => {
      await createModerator(
        values.agencyId,
        values.username,
        values.password,
        values.email,
        values.skype,
        token!,
      );

      toast.success('Der Moderator wurde erfolgreich hinzugefügt.');
      dispatch(
        loadModerators({
          size: 10,
          page: 0,
        }),
      );

      setIsAddModalOpen(false);
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.username || values.username === '') {
        errors.username = 'Bitte geben Sie einen Benutzernamen ein';
      }

      if (!values.password || values.password === '') {
        errors.password = 'Bitte geben Sie ein Passwort ein';
      }

      if (!values.email || values.email === '') {
        errors.email = 'Bitte geben Sie eine E-Mail-Adresse ein';
      }

      if (values.agencyId === undefined || values.agencyId <= 0) {
        errors.agencyId = 'Bitte geben Sie eine Agentur an';
      }

      return errors;
    },
  });

  return (
    <>
      <Modal
        isOpen={isAddModalOpen}
        centered={true}
        toggle={() =>
          !isSubmittingForm
            ? setIsAddModalOpen(!isAddModalOpen)
            : setIsAddModalOpen(isAddModalOpen)
        }
      >
        <ModalHeader>
          <h4>Moderator hinzufügen</h4>
        </ModalHeader>
        <ModalBody>
          <FormikProvider value={formik}>
            <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label>Agentur</Label>
                <select
                  name={'agencyId'}
                  id={'agencyId'}
                  className={`form-control${
                    formik.errors.agencyId && formik.touched.agencyId
                      ? ' is-invalid'
                      : ''
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.agencyId}
                >
                  {agencies.map((agency) => (
                    <option value={agency.AgencyId}>{agency.Name}</option>
                  ))}
                </select>
                {formik.errors.agencyId && formik.touched.agencyId ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.agencyId}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Benutzername</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.username && formik.touched.username
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'username'}
                  name={'username'}
                  placeholder={'Benutzername eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.errors.username && formik.touched.username ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.username}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Passwort</Label>
                <input
                  type={'password'}
                  className={`form-control${
                    formik.errors.password && formik.touched.password
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'password'}
                  name={'password'}
                  placeholder={'Passwort eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.password}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>E-Mail-Adresse</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.email && formik.touched.email
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'email'}
                  name={'email'}
                  placeholder={'Anzahl eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.email}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Skype</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.skype && formik.touched.skype
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'skype'}
                  name={'skype'}
                  placeholder={'Skype Benutzername eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.skype}
                />
                {formik.errors.skype && formik.touched.skype ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.skype}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <div className={'d-flex'}>
                <div className={'p-2'}>
                  <LoadingButton
                    isLoading={formik.isSubmitting}
                    caption={'Hinzufügen'}
                    isSubmit={true}
                  />
                </div>
                <div className={'p-2'}>
                  <Button
                    color={'danger'}
                    onClick={() => setIsAddModalOpen(false)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
      <GridToolbarContainer>
        <div className={'p-2'}>
          <Button color={'primary'} onClick={() => setIsAddModalOpen(true)}>
            Neuer Moderator
          </Button>
        </div>
      </GridToolbarContainer>
    </>
  );
}
