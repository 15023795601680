import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { useAppSelector } from '../../../store/hooks';
import { IPresent } from '../../../models/Present';
import { getLatestPresents } from '../../../services/present.service';
import Present from '../../Present/Present';

interface IActiveDialogPicturesOptions {
  userId: number | undefined;
  fakeId: number | undefined;
}

export default function ActiveDialogPresents(
  opt: IActiveDialogPicturesOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoading, setIsLoading] = useState(false);
  const [sentPresents, setSentPresents] = useState<IPresent[]>([]);
  const [receivedPresents, setReceivedPresents] = useState<IPresent[]>([]);

  useEffect(() => {
    let cancel = false;

    if (!opt.userId || !opt.fakeId) {
      setSentPresents([]);
      setReceivedPresents([]);
      return;
    }

    const loadPresents = async () => {
      setIsLoading(true);

      const receivedPresents = await getLatestPresents(
        opt.userId!,
        opt.fakeId!,
        token!,
      );
      const sentPresents = await getLatestPresents(
        opt.fakeId!,
        opt.userId!,
        token!,
      );

      if (cancel) return;

      setSentPresents(sentPresents);
      setReceivedPresents(receivedPresents);

      setIsLoading(false);
    };

    loadPresents();

    return () => {
      cancel = true;
    };
  }, [opt.userId, opt.fakeId, token]);

  return (
    <LoadingOverlay spinner active={isLoading}>
      <div className={'d-flex flex-row justify-content-stretch'}>
        <div className={'col'}>
          <div className={'p-2'}>
            <h4>Empfangen</h4>
            <hr />
          </div>
          <div className={'d-flex flex-row flex-wrap'}>
            {receivedPresents.map((present) => (
              <div className={'p-2'}>
                <Present
                  presentId={present.PresentId}
                  key={present.PresentId}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={'col'}>
          <div className={'p-2'}>
            <h4>Gesendet</h4>
            <hr />
          </div>
          <div className={'d-flex flex-row flex-wrap'}>
            {sentPresents.map((present) => (
              <div className={'p-2'}>
                <Present
                  presentId={present.PresentId}
                  key={present.PresentId}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}
