import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppDispatch } from '../../store/hooks';
import Box from '../../components/Common/Box';
import {
  loadNewCustomersCount,
  loadOnlineCustomersCount,
  loadPendingAsaDialogsCount,
  loadPendingNewCustomerDialogsCount,
  loadPendingNormalDialogsCount,
  loadTodaySentMessages,
  loadTodaysRevenue,
} from '../../store/overview/statistic/actions';
import OnlineCustomer from '../../components/Customer/OnlineCustomer';
import RevenueGraph from '../../components/Revenue/RevenueGraph';
import { loadNewCustomersToday } from '../../store/animation/actions';

export default function StarterPage() {
  const [breadcrumbItems] = useState([
    { title: 'Administration', link: '#' },
    { title: 'Dashboard', link: '#' },
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadTodaysRevenue());
    dispatch(loadOnlineCustomersCount());
    dispatch(loadTodaySentMessages());
    dispatch(loadNewCustomersToday());
    dispatch(loadNewCustomersCount());
    dispatch(loadPendingAsaDialogsCount());
    dispatch(loadPendingNormalDialogsCount());
    dispatch(loadPendingNewCustomerDialogsCount());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />

          <Row>
            <div className={'col-lg-3  d-flex align-items-stretch'}>
              <Box
                title={'Online Kunden'}
                selector={(state) => state.Statistic.onlineCustomerCount}
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingOnlineCustomerCount
                }
              />
            </div>
            <div className={'col-lg-3  d-flex align-items-stretch'}>
              <Box
                title={'Gesendete Nachrichten (heute)'}
                selector={(state) => state.Statistic.todaySentMessages}
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingTodaySentMessages
                }
              />
            </div>
            <div className={'col-lg-3  d-flex align-items-stretch'}>
              <Box
                title={'Umsatz (heute)'}
                selector={(state) => state.Statistic.todaysRevenue}
                type={'currency'}
                loadingSelector={(state) =>
                  state.Statistic.loadingTodaysRevenue
                }
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Neukunden heute'}
                type={'number'}
                loadingSelector={(state) =>
                  state.Animation.isLoadingNewCustomerToday
                }
                selector={(state) => state.Animation.newCustomerToday}
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Neukunden'}
                selector={(state) => state.Statistic.newCustomersCount}
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingNewCustomersCount
                }
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Offene ASA Dialoge'}
                selector={(state) => state.Statistic.pendingAsaDialogsCount}
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingPendingAsaDialogsCount
                }
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Offene Neukunden Dialoge'}
                selector={(state) =>
                  state.Statistic.pendingNewCustomerDialogsCount
                }
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingPendingNewCustomerDialogsCount
                }
              />
            </div>

            <div className={'col-lg-3 d-flex align-items-stretch'}>
              <Box
                title={'Offene Normale Dialoge'}
                selector={(state) => state.Statistic.pendingNormalDialogsCount}
                type={'number'}
                loadingSelector={(state) =>
                  state.Statistic.loadingPendingNormalDialogsCounts
                }
              />
            </div>
          </Row>

          <Row>
            <div className={'col-12'}>
              <OnlineCustomer />
            </div>
          </Row>

          <Row>
            <div className={'col-12'}>
              <RevenueGraph />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
