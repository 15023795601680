import { Card, CardBody } from 'reactstrap';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';

interface IBoxOptions {
  title: string;
  selector: (state: RootState) => number;
  loadingSelector: (state: RootState) => boolean;
  type: 'currency' | 'number';
}

export default function Box(opt: IBoxOptions) {
  const val = useAppSelector(opt.selector);
  const loading = useAppSelector(opt.loadingSelector);

  return (
    <Card className={'w-100'}>
      <LoadingOverlay active={loading} spinner>
        <CardBody>
          <div className="d-flex">
            <div className="flex-1 overflow-hidden">
              <p className="text-truncate font-size-14 mb-2">{opt.title}</p>
              <h4 className="mb-0">
                {' '}
                {opt.type === 'currency'
                  ? val?.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }) ?? 0
                  : val?.toLocaleString('de-DE') ?? 0}
              </h4>
            </div>
          </div>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
}
