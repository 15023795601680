import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LoadingButton from '../../../components/Common/LoadingButton';
import { IPresent } from '../../../models/Present';
import { updatePresent } from '../../../services/present.service';

interface IEditPresent {
  name: string;
  description: string;
  isPremium: boolean;
  costs: number;
}

interface IPresentEditModalOptions {
  isOpen: boolean;
  present: IPresent | undefined;
  close: Function;
}

export default function PresentEditModal(opt: IPresentEditModalOptions) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const initialValues: IEditPresent = {
    name: opt.present?.Name ?? '',
    description: opt.present?.Description ?? '',
    isPremium: opt.present?.IsPremium ?? false,
    costs: opt.present?.Costs ?? 0,
  };

  const [addPresentImage, setAddPresentImage] = useState<File>();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onReset: (values) => {
      values.name = initialValues.name;
      values.description = initialValues.description;
      values.isPremium = initialValues.isPremium;
      values.costs = initialValues.costs;
    },
    onSubmit: async (values) => {
      await updatePresent(
        opt.present?.PresentId!,
        values.name,
        values.description,
        addPresentImage ?? null,
        values.costs,
        values.isPremium,
        token!,
      );

      toast.success('Das Geschenk wurde erfolgreich gespeichert.');

      opt.close();
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name || values.name === '') {
        errors.name = 'Bitte geben Sie einen Namen ein';
      }

      if (!values.description || values.description === '') {
        errors.description = 'Bitte geben Sie eine Beschreibung ein';
      }

      if (!values.costs) {
        errors.costs = 'Bitte geben Sie einen Preis an';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (opt.present && formik) {
      formik.setValues({
        name: opt.present.Name,
        description: opt.present.Description,
        isPremium: opt.present.IsPremium,
        costs: opt.present.Costs,
      });
    }
  }, [opt.present]);

  return (
    <FormikProvider value={formik}>
      <Modal centered={true} isOpen={opt.isOpen}>
        <ModalHeader>Geschenk bearbeiten</ModalHeader>
        <ModalBody>
          <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Name</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.name && formik.touched.name ? ' is-invalid' : ''
                }`}
                id={'name'}
                name={'name'}
                placeholder={'Namen eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name ? (
                <div className={'invalid-feedback'}>{formik.errors.name}</div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Beschreibung</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.description && formik.touched.description
                    ? ' is-invalid'
                    : ''
                }`}
                id={'description'}
                name={'description'}
                placeholder={'Beschreibung eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.errors.description && formik.touched.description ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.description}
                </div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Bild</Label>
              <input
                type={'file'}
                className={'form-control'}
                id={'file'}
                name={'file'}
                placeholder={'Bild auswählen'}
                accept={'image/*'}
                onChange={(ev) => {
                  if (
                    ev.currentTarget &&
                    ev.currentTarget.files &&
                    ev.currentTarget.files.length > 0
                  ) {
                    setAddPresentImage(ev.currentTarget.files[0]);
                  } else {
                    setAddPresentImage(undefined);
                  }
                }}
                onBlur={formik.handleBlur}
              />
            </FormGroup>

            <FormGroup>
              <Label>Preis</Label>
              <input
                type={'number'}
                className={`form-control${
                  formik.errors.costs && formik.touched.costs
                    ? ' is-invalid'
                    : ''
                }`}
                id={'costs'}
                name={'costs'}
                placeholder={'Preis eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.costs}
              />
              {formik.errors.costs && formik.touched.costs ? (
                <div className={'invalid-feedback'}>{formik.errors.costs}</div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup className={'form-check'}>
              <input
                type={'checkbox'}
                className={`form-check-input${
                  formik.errors.isPremium && formik.touched.isPremium
                    ? ' is-invalid'
                    : ''
                }`}
                id={'isPremium'}
                name={'isPremium'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.isPremium}
              />
              {formik.errors.isPremium && formik.touched.isPremium ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.isPremium}
                </div>
              ) : (
                <></>
              )}
              <Label className={'form-check-label'}>Premium</Label>
            </FormGroup>

            <div className={'d-flex'}>
              <div className={'p-2'}>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  disabled={!formik.isValid}
                  isSubmit={true}
                />
              </div>
              <div className={'p-2'}>
                <Button color={'danger'} onClick={() => opt.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
}
