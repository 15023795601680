import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Spinner } from 'reactstrap';

import { IMessage } from '../../../models/message';
import { getLatestMessages } from '../../../services/message.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ActiveDialogHistory from '../../../components/Dialog/components/ActiveDialogHistory';
import { setLatestMessageDialog } from '../../../store/animation/actions';

import LatestMessageRow from './LatestMessageRow';

export default function LatestMessagesTable() {
  const [latestMessages, setLatestMessages] = useState<IMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dialog = useAppSelector((state) => state.Animation.latestMessageDialog);
  const token = useAppSelector((state) => state.Login.token);

  const activeDialog = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  // reset active dialog
  useEffect(() => {
    dispatch(setLatestMessageDialog());
  }, []);

  useEffect(() => {
    if (!dialog.fakeId || !dialog.userId || !activeDialog.current) return;

    activeDialog.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [dialog, activeDialog.current]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadMessages = async (token: string) => {
      setIsLoading(true);
      try {
        const msgs = await getLatestMessages(token, cancelToken.token);

        setLatestMessages(msgs);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    loadMessages(token!);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, []);

  if (isLoading || !latestMessages) {
    return (
      <Card>
        <CardBody>
          <Spinner />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardBody>
          {latestMessages.map((msg) => (
            <LatestMessageRow message={msg} key={msg.MessageId} />
          ))}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div ref={activeDialog}>
            <ActiveDialogHistory
              userId={dialog?.userId}
              fakeId={dialog?.fakeId}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
