import { createPortal } from 'react-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import ReactHowler from 'react-howler';

import { getVoiceDescriptionPlayUrl } from '../../services/customer.service';
import { ICustomerVoiceDescription } from '../../models/customer';

interface ICustomerPlayVoiceDescriptionModalOptions {
  voiceDescription?: ICustomerVoiceDescription;
  isOpen: boolean;
  toggle: () => void;
}

export default function CustomerPlayVoiceDescriptionModal(
  opt: ICustomerPlayVoiceDescriptionModalOptions,
) {
  const [isPlaying, setIsPlaying] = useState(false);

  if (!opt.voiceDescription) return <></>;

  return createPortal(
    <Modal
      isOpen={opt.isOpen}
      toggle={() => opt.toggle()}
      centered={true}
      backdrop={true}
    >
      <ModalHeader toggle={() => opt.toggle()}>
        Sprachnotiz abspielen
      </ModalHeader>
      <ModalBody className={'text-center'}>
        <ReactHowler
          src={getVoiceDescriptionPlayUrl(
            opt.voiceDescription.UserId,
            opt.voiceDescription.UserVoiceDescriptionId,
          )}
          playing={isPlaying}
          html5={true}
          format={[opt.voiceDescription.ContentType]}
          onEnd={() => setIsPlaying(false)}
        />

        <button
          className={'btn btn-primary'}
          onClick={() => {
            setIsPlaying(!isPlaying);
          }}
        >
          {isPlaying ? 'Pause' : 'Abspielen'}
        </button>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
