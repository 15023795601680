import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { useAppSelector } from '../../../store/hooks';
import { uploadUserPicture } from '../../../services/customer.service';

interface ICustomerPictureUploadOptions {
  userId: number;
  onUploadFinished: Function;
}

interface IImagePreview {
  name: string;
  url: string;
  formattedSize: string;
}

export default function CustomerPictureUpload(
  options: ICustomerPictureUploadOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<IImagePreview[]>();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFilePreviews(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            url: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          }),
        ),
      );
      setSelectedFiles(acceptedFiles);
    },
    accept: 'image/*',
  });

  useEffect(() => {
    if (selectedFiles.length < 0) return;

    const cancelToken = axios.CancelToken.source();

    const uploadPictures = async () => {
      for (const file of selectedFiles) {
        await uploadUserPicture(
          options.userId,
          file,
          token!,
          cancelToken.token,
        );
      }

      options.onUploadFinished();
    };

    uploadPictures();

    return () => {
      cancelToken.cancel('Unmounted');
    };
  }, [selectedFiles]);

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <Card>
      <CardHeader>Bilder hochladen</CardHeader>
      <CardBody>
        <div className={'dropzone d-flex align-items-center'}>
          <div
            className={
              'dz-message-needsclick d-flex flex-column justify-content-center align-items-center mx-auto text-center'
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="mb-3">
              <i className="display-4 text-muted ri-upload-cloud-2-line" />
            </div>

            <h4>
              {isDragActive
                ? 'Bilder hier fallen lassen'
                : 'Bilder hier Drag & Drop um den Upload zu starten.'}
            </h4>
          </div>
        </div>
        <div className={'dropzone-previews mt-3'} id={'file-previews'}>
          {filePreviews?.map((f, i) => (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={`${i}-file`}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.url}
                    />
                  </Col>
                  <Col>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <Col>
                    <div className={'text-right mr-2'}>
                      <i
                        className={'ri-check-fill ri-2x'}
                        style={{ color: 'green' }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          ))}
        </div>
      </CardBody>
    </Card>
  );
}
