import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store';
import { IPagedCustomerRequest, IPagedResult } from '../../../models';
import { ICustomer } from '../../../models/customer';
import { IBaseAction } from '../../../store/type';
import swissFlag from '../../../assets/images/flags/switzerland.svg';
import germanyFlag from '../../../assets/images/flags/germany.svg';
import austriaFlag from '../../../assets/images/flags/austria.svg';
import { deleteUser, restoreUser } from '../../../services/customer.service';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';
import { IInvoice } from '../../../models/invoice';
import { getCustomerInvoices } from '../../../services/invoice.service';

import CustomerEdit from './CustomerEdit';
import CustomerSearch from './CustomerSearch';

interface ICustomerInvoicesOptions {
  userId: number | undefined;
}

export default function CustomerInvoices(options: ICustomerInvoicesOptions) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [invoices, setInvoices] = useState<IPagedResult<IInvoice>>({
    Data: [],
    Total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    const load = async (userId: number) => {
      setIsLoading(true);

      try {
        const invoices = await getCustomerInvoices(
          userId,
          page,
          pageSize,
          token!,
          cancelToken.token,
        );
        setInvoices(invoices);
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    load(options.userId);
  }, [dispatch, page, pageSize, options.userId]);

  const columns: GridColDef[] = [
    {
      field: 'InvoiceId',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'PaymentMethod',
      headerName: 'Zahlungsmethode',
      width: 150,
    },
    {
      field: 'ItemText',
      headerName: 'Beschreibung',
      flex: 1,
    },
    {
      field: 'Total',
      headerName: 'Summe',
      width: 130,
      renderCell: (cell) =>
        (cell.value as number).toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }),
    },
    {
      field: 'TransactionId',
      headerName: 'Transaktions ID',
      flex: 1,
    },
    {
      field: 'Paid',
      headerName: 'Bezahlt',
      width: 140,
      renderCell: (cell) => {
        const paid = cell.value as boolean;

        if (paid) return <i className={'ri-check-line'} />;

        return <i className={'ri-close-line'} />;
      },
    },
    {
      field: 'DateCreate',
      headerName: 'Rechnungsdatum',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
  ];

  return (
    <Card>
      <CardHeader>Rechnungen</CardHeader>
      <CardBody>
        <DataGrid
          rows={invoices.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.InvoiceId}
          autoHeight={true}
          rowCount={invoices.Total}
          paginationMode={'server'}
          disableSelectionOnClick
          loading={isLoading}
        />
      </CardBody>
    </Card>
  );
}
