import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios, { CancelToken } from 'axios';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LoadingButton from '../../../components/Common/LoadingButton';
import { ICoinPackage } from '../../../models/coin-package';
import {
  assignPaymentMethods,
  getCoinPackagesPaymentMethods,
} from '../../../services/coin-package.service';
import { IPaymentMethod } from '../../../models/payment-methods';
import { getPaymentMethods } from '../../../services/payment-method.service';

interface ICoinPackageEditPaymentMethodsModalOptions {
  isOpen: boolean;
  coinPackage: ICoinPackage | undefined;
  close: Function;
}

export default function CoinPackageEditPaymentMethodsModal(
  opt: ICoinPackageEditPaymentMethodsModalOptions,
) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    IPaymentMethod[]
  >([]);

  const initialValues: {
    paymentMethods: IPaymentMethod[];
  } = {
    paymentMethods: [],
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onReset: (values) => {
      values.paymentMethods = [];
    },
    onSubmit: async (values) => {
      if (!selectedPaymentMethods || selectedPaymentMethods.length <= 0) {
        return;
      }

      await assignPaymentMethods(
        opt.coinPackage?.CoinPackageId!,
        selectedPaymentMethods.map(
          (paymentMethod) => paymentMethod.PaymentMethodId,
        ),
        token!,
      );

      toast.success(
        'Die Zahlungsmethode des Pakets wurden erfolgreich gespeichert.',
      );

      opt.close();
    },
    validate: (values) => {
      const errors: any = {};

      if (!selectedPaymentMethods || selectedPaymentMethods.length <= 0) {
        errors.paymentMethods =
          'Bitte wählen Sie zumindest eine Zahlungsmethode aus';
      }

      return errors;
    },
  });

  const loadAllPaymentMethods = async (cancelToken: CancelToken) => {
    setIsLoadingPaymentMethods(true);

    try {
      const paymentMethods = await getPaymentMethods(
        0,
        100,
        token!,
        cancelToken,
      );

      setPaymentMethods(paymentMethods.Data);
    } catch (err) {
      setPaymentMethods([]);
    } finally {
      setIsLoadingPaymentMethods(false);
    }
  };

  const loadAssignedPaymentMethods = async function (coinPackageId: number) {
    try {
      setIsLoadingPaymentMethods(true);
      const packages = await getCoinPackagesPaymentMethods(
        coinPackageId,
        token!,
      );

      setSelectedPaymentMethods(packages);
    } catch (e) {
      toast.error('Fehler beim Laden der Zahlungsmethoden.');
    } finally {
      setIsLoadingPaymentMethods(false);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (!opt.isOpen) {
      setPaymentMethods([]);
      setIsLoadingPaymentMethods(false);
      formik.resetForm();
    } else {
      if (!opt.coinPackage) return;

      loadAllPaymentMethods(cancelToken.token);
      loadAssignedPaymentMethods(opt.coinPackage.CoinPackageId);
    }

    return () => {
      cancelToken.cancel('Componente dismounted');
    };
  }, [opt.isOpen]);

  return createPortal(
    <Modal isOpen={opt.isOpen} centered={true} backdrop={true} size={'lg'}>
      <ModalHeader>Zahlungsmethoden für FlirtCoin Paket auswählen</ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Zahlungsmethoden</Label>
              <Typeahead
                isLoading={isLoadingPaymentMethods}
                options={paymentMethods}
                multiple={true}
                placeholder={'Nach Zahlungsmethode suchen ...'}
                selected={selectedPaymentMethods}
                filterBy={['Name']}
                id={'paymentMethods'}
                onChange={(selected) => {
                  setSelectedPaymentMethods(selected);
                }}
                labelKey={(paymentMethod) =>
                  `${paymentMethod.Name} (${
                    paymentMethod.Active ? 'aktiv' : 'nicht aktiv'
                  })`
                }
                onBlur={() => formik.setTouched({ paymentMethods: [] })}
                minLength={0}
                isValid={
                  (formik.touched.paymentMethods &&
                    selectedPaymentMethods.length > 0) ||
                  selectedPaymentMethods.length > 0
                }
                isInvalid={
                  (formik.touched.paymentMethods &&
                    selectedPaymentMethods.length <= 0) ||
                  selectedPaymentMethods.length <= 0
                }
                className={
                  formik.errors.paymentMethods && formik.touched.paymentMethods
                    ? 'is-invalid'
                    : ''
                }
                renderMenuItemChildren={(option, props) => (
                  <Fragment>
                    <div className={'d-flex flex-col flex-wrap'}>
                      <div>
                        {option.Name} ({option.Active ? 'aktiv' : 'nicht aktiv'}
                        )
                      </div>
                      <div>
                        <small>{option.Description}</small>
                      </div>
                    </div>
                  </Fragment>
                )}
              />
              <ErrorMessage
                name={'paymentMethods'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <div className={'d-flex flex-wrap'}>
              <div>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  isSubmit={true}
                  disabled={formik.isSubmitting || !formik.isValid}
                />
              </div>
              <div className={'ml-2'}>
                <Button color={'danger'} onClick={() => opt.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
