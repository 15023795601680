import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { IMessage } from '../../../models/message';
import { useAppSelector } from '../../../store/hooks';
import { getPictureMessages } from '../../../services/message.service';

import ActiveDialogPicture from './ActiveDialogPicture';

interface IActiveDialogPicturesOptions {
  userId: number | undefined;
  fakeId: number | undefined;
}

export default function ActiveDialogPictures(
  opt: IActiveDialogPicturesOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoading, setIsLoading] = useState(false);
  const [pictures, setPictures] = useState<IMessage[]>([]);

  useEffect(() => {
    let cancel = false;

    if (!opt.userId || !opt.fakeId) {
      setPictures([]);
      return;
    }

    const loadPictureMessages = async () => {
      setIsLoading(true);

      const pictureMessages = await getPictureMessages(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;

      setPictures(pictureMessages);
      setIsLoading(false);
    };

    loadPictureMessages();

    return () => {
      cancel = true;
    };
  }, [opt.userId, opt.fakeId, token]);

  return (
    <LoadingOverlay spinner active={isLoading}>
      <div className={'d-flex flex-row justify-content-stretch'}>
        <div className={'col'}>
          <div className={'p-2'}>
            <h4>Empfangen</h4>
            <hr />
          </div>
          <div className={'d-flex flex-row flex-wrap'}>
            {pictures
              .filter((x) => x.SenderUserId === opt.fakeId)
              .map((msg) => (
                <div className={'p-2'} key={msg.MessageId}>
                  <ActiveDialogPicture messageId={msg.MessageId} />
                </div>
              ))}
          </div>
        </div>

        <div className={'col'}>
          <div className={'p-2'}>
            <h4>Gesendet</h4>
            <hr />
          </div>
          <div className={'d-flex flex-row flex-wrap'}>
            {pictures
              .filter((x) => x.SenderUserId === opt.userId)
              .map((msg) => (
                <div className={'p-2'} key={msg.MessageId}>
                  <ActiveDialogPicture messageId={msg.MessageId} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}
