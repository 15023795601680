import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../store/hooks';
import LoadingButton from '../Common/LoadingButton';
import { addAsaDialogs } from '../../services/tools.service';
import { ConfirmDialog, IConfirmDialogOptions } from '../Common/ConfirmDialog';

export default function AddAsaDialogs() {
  const token = useAppSelector((state) => state.Login.token);
  const [isAdding, setIsAdding] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const add = async () => {
    setIsAdding(true);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'ASA Dialoge erstellen',
      content: (
        <div>
          Bitte vergewissere Dich zuerst, dass nicht bereits ein Job im
          Hintergrund läuft. Wollen Sie die Dialoge hinzufügen lassen?
        </div>
      ),
      confirmCallback: async () => {
        try {
          await addAsaDialogs(token!);
          toast.success('Die ASA Dialoge werden in kürze hinzugefügt.');
        } catch (e) {
          toast.error(
            'Beim Hinzufügen der ASA Dialog ist ein Fehler aufgetreten.',
          );
        }
        setIsAdding(false);
      },
      cancelCallback: () => {
        setIsAdding(false);
      },
    });
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className={'d-flex'}>
            <div className={'p-2'}>
              <LoadingButton
                isLoading={isAdding}
                onClick={() => add()}
                caption={'ASA Dialoge hinzufügen'}
                disabled={isAdding}
                isSubmit={false}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
    </>
  );
}
