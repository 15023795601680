import React from 'react';

import { ICustomer } from '../../../models/customer';

import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
} from 'reactstrap';

import LoadingButton from '../../../components/Common/LoadingButton';

import { Divider } from '@material-ui/core';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

import {
  emailAlreadyInUse,
  updateUser,
  usernameAlreadyInUse,
} from '../../../services/customer.service';
import { useAppSelector } from '../../../store/hooks';

interface ICustomerEditOptions {
  customer: ICustomer;
  close: () => void;
}

export default function CustomerEdit(options: ICustomerEditOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const formik = useFormik({
    initialValues: {
      ...options.customer,
      BirthDate: moment(options.customer.BirthDate).format('YYYY-MM-DD'),
    },
    onReset: (values) => options.customer,
    onSubmit: async (values) => {
      await updateUser(
        options.customer.UserId,
        {
          ...values,
          BirthDate: new Date(values.BirthDate),
        } as ICustomer,
        token!,
      );

      options.close();
    },
    validate: async (values) => {
      const err: any = {};

      if (!values.Username || values.Username === '') {
        err.Username = 'Bitte geben Sie einen Benutzernamen ein';
      } else {
        // check if already in use
        const alreadyInUse = await usernameAlreadyInUse(
          values.Username,
          options.customer.UserId,
          token!,
        );

        if (alreadyInUse) {
          err.Username = 'Dieser Benutzername ist bereits in Verwendung';
        }
      }

      if (!values.Email || values.Email === '') {
        err.Email = 'Bitte geben Sie eine E-Mail-Adresse ein';
      } else {
        // check if already in use
        const alreadyInUse = await emailAlreadyInUse(
          values.Email,
          options.customer.UserId,
          token!,
        );

        if (alreadyInUse) {
          err.Email = 'Diese E-Mail ist bereits in Verwendung';
        }
      }

      if (!values.Firstname || values.Firstname === '') {
        err.Firstname = 'Bitte geben Sie einen Vornamen ein';
      }

      if (!values.Lastname || values.Lastname === '') {
        err.Lastname = 'Bitte geben Sie einen Nachnamen ein';
      }

      if (!values.Gender || values.Gender === '') {
        err.Gender = 'Bitte wählen Sie ein Geschlecht aus';
      }

      if (!values.City || values.City === '') {
        err.City = 'Bitte geben Sie einen Ort an';
      }

      if (!values.ZipCode || values.ZipCode === '') {
        err.ZipCode = 'Bitte geben Sie eine PLZ an';
      }

      if (!values.Country || values.Country === '') {
        err.Country = 'Bitte geben Sie ein Land an';
      }

      if (!values.BirthDate) {
        err.BirthDate = 'Bitte geben Sie ein Geburtsdatum ein';
      }

      return err;
    },
  });

  const modalTitle = options.customer.IsModerate
    ? 'Profil bearbeiten'
    : 'Kunde bearbeiten';

  return (
    <Card>
      <CardHeader>{modalTitle}</CardHeader>
      <CardBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <legend>Kontakt Daten</legend>
            <Divider className={'mb-4'} light={true} />
            <FormGroup>
              <label>Benutzername</label>
              <Field
                type={'text'}
                name={'Username'}
                className={`form-control${
                  formik.errors.Username && formik.touched.Username
                    ? ' is-invalid'
                    : ''
                }`}
              />
              <ErrorMessage
                name={'Username'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <div className={'d-flex justify-content-between no-gutters'}>
              <div className={'col'}>
                <FormGroup>
                  <label>E-Mail</label>
                  <Field
                    type={'text'}
                    name={'Email'}
                    className={`form-control${
                      formik.errors.Email && formik.touched.Email
                        ? ' is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    name={'Email'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </FormGroup>
              </div>

              <div className={'col ml-2'}>
                <FormGroup>
                  <label>Geschlecht</label>
                  <Field
                    name={'Gender'}
                    as={'select'}
                    className={`form-control${
                      formik.errors.Lastname && formik.touched.Lastname
                        ? ' is-invalid'
                        : ''
                    }`}
                  >
                    <option value={'male'}>Männlich</option>
                    <option value={'female'}>Weiblich</option>
                  </Field>
                  <ErrorMessage
                    name={'Lastname'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </FormGroup>
              </div>
            </div>

            <legend>Persönliche Daten</legend>
            <Divider className={'mb-4'} light={true} />

            <div className={'d-flex justify-content-between no-gutters'}>
              <div className={'col'}>
                <FormGroup>
                  <label>Vorname</label>
                  <Field
                    type={'text'}
                    name={'Firstname'}
                    className={`form-control${
                      formik.errors.Firstname && formik.touched.Firstname
                        ? ' is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    name={'Firstname'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </FormGroup>
              </div>

              <div className={'col ml-2'}>
                <FormGroup>
                  <label>Nachname</label>
                  <Field
                    type={'text'}
                    name={'Lastname'}
                    className={`form-control${
                      formik.errors.Lastname && formik.touched.Lastname
                        ? ' is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    name={'Lastname'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <label>Adresse</label>
              <Field
                type={'text'}
                name={'Address'}
                className={`form-control${
                  formik.errors.Address && formik.touched.Address
                    ? ' is-invalid'
                    : ''
                }`}
              />
              <ErrorMessage
                name={'Address'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <div className={'d-flex justify-content-between no-gutters'}>
              <div className={'col'}>
                <FormGroup>
                  <label>PLZ</label>
                  <Field
                    type={'text'}
                    name={'ZipCode'}
                    className={`form-control${
                      formik.errors.ZipCode && formik.touched.ZipCode
                        ? ' is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    name={'ZipCode'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </FormGroup>
              </div>

              <div className={'col ml-2'}>
                <FormGroup>
                  <label>Ort</label>
                  <Field
                    type={'text'}
                    name={'City'}
                    className={`form-control${
                      formik.errors.City && formik.touched.City
                        ? ' is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    name={'City'}
                    className={'invalid-feedback'}
                    component={'div'}
                  />
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <label>Land</label>
              <Field
                name={'Country'}
                as={'select'}
                className={`form-control${
                  formik.errors.Country && formik.touched.Country
                    ? ' is-invalid'
                    : ''
                }`}
              >
                <option value={'DE'}>Deutschland</option>
                <option value={'AT'}>Österreich</option>
                <option value={'CH'}>Schweiz</option>
              </Field>
              <ErrorMessage
                name={'Country'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <FormGroup>
              <label>Geburtsdatum</label>
              <Field
                type={'date'}
                name={'BirthDate'}
                value={moment(formik.values.BirthDate).format('YYYY-MM-DD')}
                className={`form-control${
                  formik.errors.City && formik.touched.City ? ' is-invalid' : ''
                }`}
              />

              <ErrorMessage
                name={'BirthDate'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <div className={'d-flex'}>
              <div>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  isSubmit={true}
                  disabled={!formik.isValid}
                />
              </div>
              <div className={'ml-2'}>
                <Button color={'danger'} onClick={() => options.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </CardBody>
    </Card>
  );
}
