import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Field, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../store/hooks';
import LoadingButton from '../Common/LoadingButton';
import CustomerSelectModal from '../Customer/CustomerSelectModal';
import CustomerName from '../Customer/CustomerName';
import {
  getMassMessageReceiver,
  sendMassMessage,
} from '../../services/tools.service';
import { ConfirmDialog, IConfirmDialogOptions } from '../Common/ConfirmDialog';

interface ISendMassMessage {
  withProfilePicture: boolean | undefined;
  gay: boolean;
  lastOnlineSinceDays: string;
  gender: string;
  plzArea: string;
  country: string;
  message: string;
  domain: string;
  onlyBuyUsers?: string;
  hasMessageSent?: string;
  lastMessageSentDays?: string;
  voiceMessage?: File;
}

function parseNullableBoolean(value?: string): boolean | undefined {
  console.log('Parse nullable boolean', value);
  if (value === undefined || value === 'null') return undefined;

  console.log('Value', value === 'true');
  return value === 'true';
}

function parseNullableNumber(value?: string): number | undefined {
  console.log('Parse nullable number', value);
  if (value === undefined || value === 'null') return undefined;
  return Number(value);
}

export default function SendMassMessage() {
  const token = useAppSelector((state) => state.Login.token);

  const [messageImage, setMessageImage] = useState<File>();
  const [voiceMessage, setVoiceMessage] = useState<File>();
  const [customerId, setCustomerId] = useState<number>();
  const [isSelectCustomerModalOpen, setIsSelectCustomerModalOpen] =
    useState(false);

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const initialValues: ISendMassMessage = {
    withProfilePicture: undefined,
    gay: false,
    lastOnlineSinceDays: '',
    gender: '',
    plzArea: '',
    country: '',
    message: '',
    domain: '',
    onlyBuyUsers: undefined,
    hasMessageSent: undefined,
    lastMessageSentDays: undefined,
    voiceMessage: undefined,
  };

  const formik = useFormik({
    enableReinitialize: false,
    isInitialValid: false,
    initialValues,
    onReset: (values) => {
      values.message = '';
      setCustomerId(undefined);
    },
    onSubmit: async (values) => {
      try {
        // do something
        if (!customerId) return;

        const estimatedReceiver = await getMassMessageReceiver(
          customerId,
          values.withProfilePicture,
          values.gay,
          values.lastOnlineSinceDays,
          values.gender,
          values.country,
          values.domain,
          parseNullableBoolean(values.onlyBuyUsers),
          parseNullableBoolean(values.hasMessageSent),
          parseNullableNumber(values.lastMessageSentDays),
          token!,
        );

        setConfirmDialog({
          ...confirmDialog,
          isOpen: true,
          title: 'Massennachricht versenden',
          content: (
            <div>
              Diese Nachricht würde <strong>{estimatedReceiver}</strong> Profile
              adressieren. Wollen Sie die Nachricht absenden?
            </div>
          ),
          confirmCallback: async () => {
            try {
              await sendMassMessage(
                customerId,
                values.withProfilePicture,
                values.gay,
                values.lastOnlineSinceDays,
                values.gender,
                values.country,
                values.message,
                values.domain,
                parseNullableBoolean(values.onlyBuyUsers),
                parseNullableBoolean(values.hasMessageSent),
                parseNullableNumber(values.lastMessageSentDays),
                messageImage,
                voiceMessage,
                token!,
              );
              toast.success('Die Nachrichten werden in kürze versandt.');

              formik.resetForm();

              setCustomerId(undefined);
              setMessageImage(undefined);
              setVoiceMessage(undefined);
            } catch (e) {
              toast.error(
                'Beim Versenden der Nachricht ist ein Fehler aufgetreten.',
              );
            }
          },
        });
      } catch (e) {
        toast.error(
          'Beim Versenden der Nachrichten ist ein Fehler aufgetreten',
        );
      }
    },
    validate: (values) => {
      const errors: any = {};

      if ((!values.message || values.message === '') && !voiceMessage) {
        errors.message =
          'Bitte geben Sie eine Nachricht ein oder wählen Sie eine Sprachnachricht aus.';
      }

      return errors;
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Card>
          <CardBody>
            <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
              <div className={'d-flex flex-column flex-wrap'}>
                <div>
                  <Label>Absender</Label>
                </div>
                <div>
                  <Button
                    color={!customerId ? 'danger' : 'success'}
                    onClick={() => setIsSelectCustomerModalOpen(true)}
                  >
                    {!customerId ? (
                      'Benutzer auswählen'
                    ) : (
                      <CustomerName userId={customerId} />
                    )}
                  </Button>
                </div>
              </div>
              <hr />
              <Row className={'mt-2'}>
                <FormGroup className={'col-12 col-md-3'}>
                  <label>Geschlecht</label>
                  <Field
                    as={'select'}
                    name={'gender'}
                    className={'form-control'}
                  >
                    <option value={''} selected={true}>
                      Egal
                    </option>
                    <option value={'male'}>Männlich</option>
                    <option value={'female'}>Weiblich</option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <label>Herkunftsland</label>
                  <Field
                    as={'select'}
                    name={'country'}
                    className={'form-control'}
                  >
                    <option value={''} selected={true}>
                      Egal
                    </option>
                    <option value={'AT'}>Österreich</option>
                    <option value={'CH'}>Schweiz</option>
                    <option value={'DE'}>Deutschland</option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <label>Zuletzt online vor</label>
                  <Field
                    as={'select'}
                    name={'lastOnlineSinceDays'}
                    className={'form-control'}
                  >
                    <option value={''} selected={true}>
                      Egal
                    </option>
                    <option value="14">14 Tage (2 Wochen)</option>
                    <option value="30">30 Tage (1 Monat)</option>
                    <option value="60">60 Tage (2 Monate)</option>
                    <option value="90">90 Tage (3 Monate)</option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <label>Domain (enthält)</label>
                  <Field
                    as={'input'}
                    name={'domain'}
                    placeholder={'z.B. kontaktanzeigen.de'}
                    className={'form-control'}
                  />
                </FormGroup>
              </Row>

              <Row>
                <FormGroup className={'col-12 col-md-3'}>
                  <Label>Nur mit Profilbild</Label>
                  <Field
                    as={'select'}
                    name={'withProfilePicture'}
                    className={'form-control'}
                  >
                    <option value={''} selected={true}>
                      Egal
                    </option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <Label>Sexuelle Orientierung</Label>
                  <Field as={'select'} name={'gay'} className={'form-control'}>
                    <option value="false" selected={true}>
                      Alle
                    </option>
                    <option value="true">Homosexuelle</option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <Label>Kauf Verhalten</Label>
                  <Field
                    as={'select'}
                    name={'onlyBuyUsers'}
                    className={'form-control'}
                  >
                    <option value="null" selected={true}>
                      Egal
                    </option>
                    <option value="true">Kunden die gekauft haben</option>
                    <option value="false">
                      Kunden die nicht gekauft haben
                    </option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <Label>Nachricht Verhalten</Label>
                  <Field
                    as={'select'}
                    name={'hasMessageSent'}
                    className={'form-control'}
                  >
                    <option value="null" selected={true}>
                      Egal
                    </option>
                    <option value="true">
                      Kunden die Nachricht gesendet haben
                    </option>
                    <option value="false">
                      Kunden die keine Nachricht gesendet haben
                    </option>
                  </Field>
                </FormGroup>

                <FormGroup className={'col-12 col-md-3'}>
                  <Label>Letzte Nachricht gesendet?</Label>
                  <Field
                    as={'select'}
                    name={'lastMessageSentDays'}
                    className={'form-control'}
                  >
                    <option value="null" selected={true}>
                      Egal
                    </option>
                    <option value="7">vor 7 Tagen</option>
                    <option value="14">vor 14 Tagen</option>
                    <option value="30">vor 1 Monat</option>
                  </Field>
                </FormGroup>
              </Row>
              <FormGroup className={'mt-2'}>
                <Label>Nachricht</Label>
                <textarea
                  className={`form-control${
                    formik.errors.message && formik.touched.message
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'message'}
                  name={'message'}
                  placeholder={'Nachricht eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.errors.message && formik.touched.message ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.message}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Bild</Label>
                <input
                  type={'file'}
                  className={'form-control'}
                  id={'file'}
                  name={'file'}
                  placeholder={'Bild auswählen'}
                  accept={'image/*'}
                  onChange={(ev) => {
                    if (
                      ev.currentTarget &&
                      ev.currentTarget.files &&
                      ev.currentTarget.files.length > 0
                    ) {
                      setMessageImage(ev.currentTarget.files[0]);
                    } else {
                      setMessageImage(undefined);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>

              <FormGroup>
                <Label>Sprachnachricht (max. 10 Sekunden)</Label>
                <small className={'ml-2'}>
                  Achtung: Wird eine Sprachnachricht ausgewählt, wird nur diese
                  versandt!
                </small>
                <input
                  type={'file'}
                  className={'form-control'}
                  id={'voice-file'}
                  name={'voice-file'}
                  placeholder={'Sprachnachricht auswählen'}
                  accept={'audio/*'}
                  onChange={(ev) => {
                    if (
                      ev.currentTarget &&
                      ev.currentTarget.files &&
                      ev.currentTarget.files.length > 0
                    ) {
                      setVoiceMessage(ev.currentTarget.files[0]);
                    } else {
                      setVoiceMessage(undefined);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>

              <div className={'d-flex'}>
                <div className={'p-2'}>
                  <LoadingButton
                    isLoading={formik.isSubmitting}
                    caption={'Versenden'}
                    disabled={!customerId || !formik.isValid}
                    isSubmit={true}
                  />
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </FormikProvider>

      <CustomerSelectModal
        isOpen={isSelectCustomerModalOpen}
        onlyModerated={true}
        toggle={() => setIsSelectCustomerModalOpen(!isSelectCustomerModalOpen)}
        onSelect={(customer) => setCustomerId(customer.UserId)}
      />

      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
    </>
  );
}
