import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';

// Import Routes
import axios, { AxiosError } from 'axios';
import { ToastContainer } from 'react-toastify';

import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
import HorizontalLayout from './components/HorizontalLayout';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './theme.scss';
import RedirectListener from './components/Redirect/RedirectListener';
import { useAppDispatch } from './store/hooks';
import { logout } from './store/auth/login/actions';
import ConfirmationUrlGeneratorModal from './components/Tools/ConfirmationUrlGeneratorModal';

axios.defaults.withCredentials = true;

function App() {
  const dispatch = useAppDispatch();

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        dispatch(logout());
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );

  return (
    <React.Fragment>
      <Router>
        <RedirectListener />
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              authenticationPath={'/login'}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              authenticationPath={'/login'}
              layout={HorizontalLayout}
              component={route.component}
              key={idx}
              isProtected={true}
            />
          ))}
        </Switch>

        <ConfirmationUrlGeneratorModal />
        <ToastContainer />
      </Router>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  layout: state.Layout,
});

export default connect(mapStateToProps, null)(App);
