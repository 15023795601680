import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

import {
  getModeratorStatistic,
  getModeratorTodaysStatistic,
  getTotalFinishedDialogs,
  getTotalUnfinishedDialogs,
} from '../../../services/moderator.service';
import { useAppSelector } from '../../../store/hooks';
import { IModeratorStatistic } from '../../../models/moderator';

export interface IModeratorDialogStatisticOptions {
  moderatorId: number;
}

export default function ModeratorDialogStatistic(
  opt: IModeratorDialogStatisticOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [finishedDialogsCount, setFinishedDialogsCount] = useState<number>();
  const [unfinishedDialogsCount, setUnfinishedDialogsCount] =
    useState<number>();

  let cancelLoading: CancelTokenSource | undefined;

  useEffect(() => {
    const loadModeratorDialogsCount = async function () {
      cancelLoading = axios.CancelToken.source();

      try {
        const finishedDialogsCount = await getTotalFinishedDialogs(
          opt.moderatorId,
          token!,
          cancelLoading,
        );
        const unfinishedDialogsCount = await getTotalUnfinishedDialogs(
          opt.moderatorId,
          token!,
          cancelLoading,
        );

        setFinishedDialogsCount(finishedDialogsCount);
        setUnfinishedDialogsCount(unfinishedDialogsCount);
      } catch (err) {}
    };

    loadModeratorDialogsCount();

    return () => {
      if (typeof cancelLoading !== typeof undefined) {
        cancelLoading?.cancel('Component unmounted.');
      }
    };
  }, [token, opt.moderatorId]);

  if (
    finishedDialogsCount === undefined ||
    unfinishedDialogsCount === undefined
  ) {
    return (
      <>
        <Spinner size={'sm'} />
      </>
    );
  }

  return (
    <>
      {unfinishedDialogsCount} / {finishedDialogsCount}
    </>
  );
}
