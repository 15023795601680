import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import CustomerOverview from './overview';
import CustomerView from './view';
import CustomerEditPublicInformation from './public_information';
import CustomerEditInterest from './interest';
import CustomerEditPictures from './pictures';

export default function CustomerPage() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:userId/pictures`}>
          <CustomerEditPictures />
        </Route>
        <Route path={`${match.path}/:userId/interest`}>
          <CustomerEditInterest />
        </Route>
        <Route path={`${match.path}/:userId/public-information`}>
          <CustomerEditPublicInformation />
        </Route>
        <Route path={`${match.path}/:userId`}>
          <CustomerView />
        </Route>
        <Route path={match.path}>
          <CustomerOverview />
        </Route>
      </Switch>
    </>
  );
}
