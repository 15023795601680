import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import axios, { CancelTokenSource } from 'axios';

import { IAgency } from '../../../models/agency';
import { getAgency } from '../../../services/agency.service';
import { useAppSelector } from '../../../store/hooks';

interface IModeratorTableAgencyOptions {
  agencyId: number;
}

export default function ModeratorTableAgency(
  opt: IModeratorTableAgencyOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [agency, setAgency] = useState<IAgency>();

  let cancelToken: CancelTokenSource | undefined;

  useEffect(() => {
    const loadAgency = async () => {
      cancelToken = axios.CancelToken.source();

      try {
        const _agency = await getAgency(opt.agencyId, token!, cancelToken);
        setAgency(_agency);
      } catch (err) {}
    };

    loadAgency();

    return () => {
      cancelToken?.cancel('Component unmounted');
    };
  }, [opt.agencyId]);

  if (!agency) {
    return (
      <>
        <Spinner size={'sm'} />
      </>
    );
  }

  return <>{agency.Name}</>;
}
