import React, { useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LoadingButton from '../../../components/Common/LoadingButton';
import { IModerator } from '../../../models/moderator';
import { updateModerator } from '../../../services/moderator.service';
import { loadModerators } from '../../../store/moderator/actions';

interface IEditModerator {
  agencyId: number;
  username: string;
  password?: string;
  skype: string | undefined;
  email: string;
}

interface IModeratorEditModalOptions {
  isOpen: boolean;
  moderator: IModerator | undefined;
  close: Function;
}

export default function ModeratorEditModal(opt: IModeratorEditModalOptions) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const initialValues: IEditModerator = {
    username: opt.moderator?.Username ?? '',
    agencyId: opt.moderator?.AgencyId ?? -1,
    email: opt.moderator?.Email ?? '',
    skype: opt.moderator?.Skype ?? '',
    password: '',
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues,
    onReset: (values) => {
      values.username = initialValues.username;
      values.agencyId = initialValues.agencyId;
      values.email = initialValues.email;
      values.skype = initialValues.skype;
      values.password = initialValues.password;
    },
    onSubmit: async (values) => {
      await updateModerator(
        opt.moderator?.ModeratorId!,
        values.agencyId,
        values.username,
        values.password,
        values.email,
        values.skype,
        token!,
      );

      dispatch(
        loadModerators({
          size: 10,
          page: 0,
        }),
      );

      toast.success('Der Moderator wurde erfolgreich gespeichert.');

      opt.close();
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.username || values.username === '') {
        errors.username = 'Bitte geben Sie einen Benutzernamen ein';
      }

      if (!values.email || values.email === '') {
        errors.email = 'Bitte geben Sie eine E-Mail-Adresse ein';
      }

      if (values.agencyId === undefined || values.agencyId <= 0) {
        errors.agencyId = 'Bitte geben Sie eine Agentur an';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (opt.moderator && formik) {
      formik.setValues({
        username: opt.moderator.Username,
        agencyId: opt.moderator.AgencyId,
        email: opt.moderator.Email,
        skype: opt.moderator.Skype,
      });
    }
  }, [opt.moderator]);

  return (
    <FormikProvider value={formik}>
      <Modal centered={true} isOpen={opt.isOpen}>
        <ModalHeader>Moderator bearbeiten</ModalHeader>
        <ModalBody>
          <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Benutzername</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.username && formik.touched.username
                    ? ' is-invalid'
                    : ''
                }`}
                id={'username'}
                name={'username'}
                placeholder={'Benutzername eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
              />
              {formik.errors.username && formik.touched.username ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.username}
                </div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Passwort</Label>
              <input
                type={'password'}
                className={`form-control${
                  formik.errors.password && formik.touched.password
                    ? ' is-invalid'
                    : ''
                }`}
                id={'password'}
                name={'password'}
                placeholder={'Passwort eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password ? (
                <div className={'invalid-feedback'}>
                  {formik.errors.password}
                </div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>E-Mail-Adresse</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.email && formik.touched.email
                    ? ' is-invalid'
                    : ''
                }`}
                id={'email'}
                name={'email'}
                placeholder={'Anzahl eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email ? (
                <div className={'invalid-feedback'}>{formik.errors.email}</div>
              ) : (
                <></>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Skype</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.skype && formik.touched.skype
                    ? ' is-invalid'
                    : ''
                }`}
                id={'skype'}
                name={'skype'}
                placeholder={'Skype Benutzername eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skype}
              />
              {formik.errors.skype && formik.touched.skype ? (
                <div className={'invalid-feedback'}>{formik.errors.skype}</div>
              ) : (
                <></>
              )}
            </FormGroup>

            <div className={'d-flex'}>
              <div className={'p-2'}>
                <LoadingButton
                  isLoading={formik.isSubmitting}
                  caption={'Speichern'}
                  isSubmit={true}
                />
              </div>
              <div className={'p-2'}>
                <Button color={'danger'} onClick={() => opt.close()}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
}
