import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ICustomer } from '../../../models/customer';
import {
  getCustomer,
  getLastLogin,
  getProfilePictureUrl,
} from '../../../services/customer.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IActiveDialog } from '../../../models/animation';
import {
  openTransferDialog,
  setDialogHistory,
} from '../../../store/animation/actions';

import ActiveDialogIN from './ActiveDialogINs';
import ActiveDialogOUT from './ActiveDialogOUTs';

interface IActiveDialogRowOptions {
  row: IActiveDialog;
}

export default function ActiveDialogRow(opt: IActiveDialogRowOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const dispatch = useAppDispatch();

  const [user, setUser] = useState<ICustomer>();
  const [fake, setFake] = useState<ICustomer>();

  const [userLastLogin, setUserLastLogin] = useState<Date | null>(null);
  const onlineModerators = useAppSelector((state) => state.Moderator.online);

  useEffect(() => {
    let cancel = false;

    const loadCustomer = async function (userId: number) {
      const user = await getCustomer(userId, token!);

      if (cancel) return;
      setUser(user);
    };

    const loadFake = async function (userId: number) {
      const fake = await getCustomer(userId, token!);

      if (cancel) return;
      setFake(fake);
    };

    const loadLastLogin = async function (userId: number) {
      const lastLogin = await getLastLogin(userId, token!);

      if (cancel) return;
      setUserLastLogin(lastLogin);
    };

    loadCustomer(opt.row.UserId);
    loadFake(opt.row.FakeId);
    loadLastLogin(opt.row.UserId);

    return () => {
      cancel = true;
    };
  }, []);

  return (
    <tr>
      <td>
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '60px',
            width: '60px',
            borderRadius: '50%',
            backgroundImage: `url(${getProfilePictureUrl(opt.row.UserId)})`,
          }}
        />
      </td>
      <td>
        <Link to={`/customers/${user?.UserId}`}>
          {user?.Username ?? <Spinner size={'sm'} />}
        </Link>
      </td>
      <td>{opt.row.DialogType}</td>
      <td>
        {userLastLogin
          ? moment(userLastLogin).format('DD.MM.YYYY HH:mm')
          : 'n/a'}
      </td>
      <td>
        <ActiveDialogIN
          ModeratorId={opt.row.ModeratorId}
          FakeId={opt.row.FakeId}
          UserId={opt.row.UserId}
        />
      </td>
      <td>
        <ActiveDialogOUT
          ModeratorId={opt.row.ModeratorId}
          FakeId={opt.row.FakeId}
          UserId={opt.row.UserId}
        />
      </td>
      <td>
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '60px',
            width: '60px',
            borderRadius: '50%',
            backgroundImage: `url(${getProfilePictureUrl(opt.row.FakeId)})`,
          }}
        />
      </td>
      <td>{fake?.Username ?? <Spinner size={'sm'} />}</td>
      <td>
        {!opt.row.DateFinished ? (
          <Button
            color={'info'}
            disabled={!onlineModerators || onlineModerators.length <= 0}
            className={'mr-2'}
            onClick={() => dispatch(openTransferDialog(opt.row.DialogId))}
          >
            Übergeben
          </Button>
        ) : (
          <></>
        )}
        <Button
          color={'danger'}
          onClick={() =>
            dispatch(
              setDialogHistory({
                userId: opt.row.UserId,
                partnerId: opt.row.FakeId,
              }),
            )
          }
        >
          Verlauf öffnen
        </Button>
      </td>
    </tr>
  );
}
