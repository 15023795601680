import { Card, CardBody } from 'reactstrap';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';

interface IDoubleValueBoxOptions {
  title: string;
  selector: (state: RootState) => number;
  loadingSelector: (state: RootState) => boolean;

  selector2: (state: RootState) => number;
  loadingSelector2: (state: RootState) => boolean;

  type: 'currency' | 'number';
}

export default function DoubleValueBox(opt: IDoubleValueBoxOptions) {
  const val = useAppSelector(opt.selector);
  const loading = useAppSelector(opt.loadingSelector);

  const val2 = useAppSelector(opt.selector2);
  const loading2 = useAppSelector(opt.loadingSelector2);

  return (
    <Card className={'w-100'}>
      <LoadingOverlay active={loading || loading2} spinner>
        <CardBody>
          <div className="d-flex">
            <div className="flex-1 overflow-hidden">
              <p className="text-truncate font-size-14 mb-2">{opt.title}</p>
              <h4 className="mb-0">
                {' '}
                {opt.type === 'currency'
                  ? val.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : val.toLocaleString('en-US')}{' '}
                /{' '}
                {opt.type === 'currency'
                  ? val2.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : val2.toLocaleString('en-US')}
              </h4>
            </div>
          </div>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
}
