import { IBaseAction } from '../../type';
import { IRevenue } from '../../../models/statistic';

import {
  LOAD_INCOMING_MESSAGES,
  LOAD_INCOMING_MESSAGES_FAILED,
  LOAD_NEW_CUSTOMERS_COUNT,
  LOAD_NEW_CUSTOMERS_COUNT_FAILED,
  LOAD_ONLINE_CUSTOMERS_COUNT,
  LOAD_ONLINE_CUSTOMERS_COUNT_FAILED,
  LOAD_OUTGOING_MESSAGES,
  LOAD_OUTGOING_MESSAGES_FAILED,
  LOAD_PENDING_ASA_DIALOGS_COUNT,
  LOAD_PENDING_ASA_DIALOGS_COUNT_FAILED,
  LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
  LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT_FAILED,
  LOAD_PENDING_NORMAL_DIALOGS_COUNT,
  LOAD_PENDING_NORMAL_DIALOGS_COUNT_FAILED,
  LOAD_REVENUE,
  LOAD_REVENUE_FAILED,
  LOAD_TODAYS_REVENUE,
  LOAD_TODAYS_REVENUE_FAILED,
  LOAD_TODAY_SENT_MESSAGES,
  LOAD_TODAY_SENT_MESSAGES_FAILED,
  SET_INCOMING_MESSAGES,
  SET_NEW_CUSTOMERS_COUNT,
  SET_ONLINE_CUSTOMERS_COUNT,
  SET_OUTGOING_MESSAGES,
  SET_PENDING_ASA_DIALOGS_COUNT,
  SET_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
  SET_PENDING_NORMAL_DIALOGS_COUNT,
  SET_REVENUE,
  SET_TODAYS_REVENUE,
  SET_TODAY_SENT_MESSAGES,
} from './actionTypes';

export function setRevenue(revenue: IRevenue[]): IBaseAction<any> {
  return {
    type: SET_REVENUE,
    payload: revenue,
  };
}

export function setTodaysRevenue(revenue: number): IBaseAction<any> {
  return {
    type: SET_TODAYS_REVENUE,
    payload: revenue,
  };
}

export function setTodaySentMessages(messages: number): IBaseAction<any> {
  return {
    type: SET_TODAY_SENT_MESSAGES,
    payload: messages,
  };
}

export function setOnlineCustomersCount(
  onlineCustomerCount: number,
): IBaseAction<any> {
  return {
    type: SET_ONLINE_CUSTOMERS_COUNT,
    payload: onlineCustomerCount,
  };
}

export function setIncomingMessages(incomingMessage: number): IBaseAction<any> {
  return {
    type: SET_INCOMING_MESSAGES,
    payload: incomingMessage,
  };
}

export function setOutgoingMessages(outgoingMessage: number): IBaseAction<any> {
  return {
    type: SET_OUTGOING_MESSAGES,
    payload: outgoingMessage,
  };
}

export function setNewCustomerCount(count: number): IBaseAction<any> {
  return {
    type: SET_NEW_CUSTOMERS_COUNT,
    payload: count,
  };
}

export function loadRevenue(): IBaseAction<any> {
  return {
    type: LOAD_REVENUE,
    payload: null,
  };
}

export function loadTodaysRevenue(): IBaseAction<any> {
  return {
    type: LOAD_TODAYS_REVENUE,
    payload: null,
  };
}

export function loadTodaySentMessages(): IBaseAction<any> {
  return {
    type: LOAD_TODAY_SENT_MESSAGES,
    payload: null,
  };
}

export function loadOnlineCustomersCount(): IBaseAction<any> {
  return {
    type: LOAD_ONLINE_CUSTOMERS_COUNT,
    payload: null,
  };
}

export function loadNewCustomersCount(): IBaseAction<any> {
  return {
    type: LOAD_NEW_CUSTOMERS_COUNT,
    payload: null,
  };
}

export function loadIncomingMessagesCount(): IBaseAction<any> {
  return {
    type: LOAD_INCOMING_MESSAGES,
    payload: null,
  };
}

export function loadOutgoingMessagesCount(): IBaseAction<any> {
  return {
    type: LOAD_OUTGOING_MESSAGES,
    payload: null,
  };
}

export function loadPendingAsaDialogsCount(): IBaseAction<any> {
  return {
    type: LOAD_PENDING_ASA_DIALOGS_COUNT,
    payload: null,
  };
}

export function loadPendingNormalDialogsCount(): IBaseAction<any> {
  return {
    type: LOAD_PENDING_NORMAL_DIALOGS_COUNT,
    payload: null,
  };
}

export function loadPendingNewCustomerDialogsCount(): IBaseAction<any> {
  return {
    type: LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
    payload: null,
  };
}

export function setPendingAsaDialogsCount(count: number): IBaseAction<any> {
  return {
    type: SET_PENDING_ASA_DIALOGS_COUNT,
    payload: count,
  };
}

export function setPendingNormalDialogsCount(count: number): IBaseAction<any> {
  return {
    type: SET_PENDING_NORMAL_DIALOGS_COUNT,
    payload: count,
  };
}

export function setPendingNewCustomerDialogsCount(
  count: number,
): IBaseAction<any> {
  return {
    type: SET_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
    payload: count,
  };
}

export function loadRevenueFailed(): IBaseAction<any> {
  return {
    type: LOAD_REVENUE_FAILED,
    payload: null,
  };
}

export function loadTodaysRevenueFailed(): IBaseAction<any> {
  return {
    type: LOAD_TODAYS_REVENUE_FAILED,
    payload: null,
  };
}

export function loadTodaySentMessagesFailed(): IBaseAction<any> {
  return {
    type: LOAD_TODAY_SENT_MESSAGES_FAILED,
    payload: null,
  };
}

export function loadOnlineCustomersCountFailed(): IBaseAction<any> {
  return {
    type: LOAD_ONLINE_CUSTOMERS_COUNT_FAILED,
    payload: null,
  };
}

export function loadIncomingMessagesFailed(): IBaseAction<any> {
  return {
    type: LOAD_INCOMING_MESSAGES_FAILED,
    payload: null,
  };
}

export function loadOutgoingMessagesFailed(): IBaseAction<any> {
  return {
    type: LOAD_OUTGOING_MESSAGES_FAILED,
    payload: null,
  };
}

export function loadNewCustomersCountFailed(): IBaseAction<any> {
  return {
    type: LOAD_NEW_CUSTOMERS_COUNT_FAILED,
    payload: null,
  };
}

export function loadPendingAsaDialogsCountFailed(): IBaseAction<any> {
  return {
    type: LOAD_PENDING_ASA_DIALOGS_COUNT_FAILED,
    payload: null,
  };
}

export function loadPendingNormalDialogsCountFailed(): IBaseAction<any> {
  return {
    type: LOAD_PENDING_NORMAL_DIALOGS_COUNT_FAILED,
    payload: null,
  };
}

export function loadPendingNewCustomerDialogsCountFailed(): IBaseAction<any> {
  return {
    type: LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT_FAILED,
    payload: null,
  };
}
