export const SET_FINISHED_DIALOGS = 'animation/setFinishedDialogs';
export const LOAD_FINISHED_DIALOGS = 'animation/loadFinishedDialogs';
export const LOAD_FINISHED_DIALOGS_FAILED =
  'animation/loadFinishedDialogsFailed';

export const SET_PENDING_DIALOGS = 'animation/setPendingDialogs';
export const LOAD_PENDING_DIALOGS = 'animation/loadPendingDialogs';
export const LOAD_PENDING_DIALOGS_FAILED = 'animation/loadPendingDialogsFailed';

export const SET_TOTAL_COINS = 'animation/setTotalCoins';
export const LOAD_TOTAL_COINS = 'animation/loadTotalCoins';
export const LOAD_TOTAL_COINS_FAILED = 'animation/loadTotalCoinsFailed';

export const SET_INS_TODAY = 'animation/setInsToday';
export const LOAD_INS_TODAY = 'animation/loadInsToday';
export const LOAD_INS_TODAY_FAILED = 'animation/loadInsTodayFailed';

export const SET_NEW_CUSTOMER_TODAY = 'animation/setNewCustomerToday';
export const LOAD_NEW_CUSTOMER_TODAY = 'animation/loadNewCustomerToday';
export const LOAD_NEW_CUSTOMER_TODAY_FAILED =
  'animation/loadNewCustomerTodayFailed';

export const SET_ACTIVE_DIALOGS = 'animation/setActiveDialogs';
export const LOAD_ACTIVE_DIALOGS = 'animation/loadActiveDialogs';
export const LOAD_ACTIVE_DIALOGS_FAILED = 'animation/loadActiveDialogsFailed';

export const SET_OPEN_DIALOGS = 'animation/setOpenDialogs';
export const LOAD_OPEN_DIALOGS = 'animation/loadOpenDialogs';
export const LOAD_OPEN_DIALOGS_FAILED = 'animation/loadOpenDialogsFailed';

export const SET_DIALOG_HISTORY = 'animation/setDialogHistory';

export const TRANSFER_DIALOG = 'animation/transferDialog';

export const SET_LATEST_MESSAGE_DIALOG = 'animation/setLatestMessageDialog';
