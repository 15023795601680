import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';
import { ISupport } from '../../../models/support';
import { deleteTicket } from '../../../services/support.service';
import { loadTickets } from '../../../store/support/actions';
import CustomerName from '../../../components/Customer/CustomerName';

import AnswerTicketModal from './AnswerTicketModal';

export default function TicketsTable() {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);
  const [answerTicket, setAnswerTicket] = useState<ISupport>();

  const token = useAppSelector((state) => state.Login.token);

  const loading = useAppSelector((state) => state.Support.isLoadingTickets);
  const tickets = useAppSelector((state) => state.Support.tickets);

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const onAnswerClick = function (row: ISupport) {
    setAnswerTicket(row);
    setIsAnswerModalOpen(true);
  };

  const onDeleteClick = function (row: ISupport) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Support-Anfrage löschen',
      isOpen: true,
      content: `Wollen Sie die Anfrage ${row.Title} wirklich löschen?`,
      confirmCallback: async () => {
        await deleteTicket(row.SupportId, token!);
        toast.success('Die Support-Anfrage wurde erfolgreich gelöscht.');
        dispatch(
          loadTickets({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'SupportId',
      headerName: ' ',
      width: 80,
    },
    {
      field: 'UserId',
      headerName: 'Kunde',
      flex: 1,
      renderCell: (cell) => {
        const customerId = cell.value as number;

        return (
          <Link to={`/customers/${customerId}`}>
            <CustomerName userId={customerId} />
          </Link>
        );
      },
    },
    {
      field: 'Title',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'DateCreate',
      headerName: 'Erstellt Am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (cell) => {
        const ticket = cell.row as ISupport;

        return (
          <div className={'d-flex flex-row'}>
            <Button
              color={'info'}
              className={'btn-sm btn-rounded'}
              title={'Anfrage beantworten'}
              onClick={() => onAnswerClick(cell.row as ISupport)}
            >
              <i className={'ri-chat-1-line'} />
            </Button>
            <Button
              color={'danger'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Anfrage löschen'}
              onClick={() => onDeleteClick(cell.row as ISupport)}
            >
              <i className={'ri-delete-bin-2-line'} />
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      loadTickets({
        page,
        size: pageSize,
      }),
    );
  }, [pageSize, page]);

  return (
    <Card>
      <CardBody>
        <DataGrid
          rows={tickets.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.SupportId}
          autoHeight={true}
          rowCount={tickets.Total}
          paginationMode={'server'}
          disableSelectionOnClick
          loading={loading}
        />
      </CardBody>
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
      <AnswerTicketModal
        isOpen={isAnswerModalOpen}
        ticket={answerTicket}
        close={() => {
          setIsAnswerModalOpen(false);
          loadTickets({
            page,
            size: pageSize,
          });
        }}
      />
    </Card>
  );
}
