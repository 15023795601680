import axios from 'axios';

import { IPagedResult } from '../models';
import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { ISupport } from '../models/support';

export async function getTickets(
  page: number,
  size: number,
  token: string,
): Promise<IPagedResult<ISupport>> {
  return (
    await axios.get(
      `${environment.API_URL}/support/tickets`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
        },
      }),
    )
  ).data;
}

export async function deleteTicket(
  ticketId: number,
  token: string,
): Promise<void> {
  await axios.delete(
    `${environment.API_URL}/support/ticket/${ticketId}`,
    getAxiosOptions(token),
  );
}

export async function answerTicket(
  ticketId: number,
  message: string,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/support/ticket/${ticketId}/answer`,
    {
      message,
    },
    getAxiosOptions(token),
  );
}
