import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IPagedRequest, IPagedResult } from '../../models';
import { ISupport } from '../../models/support';
import { getTickets } from '../../services/support.service';

import { LOAD_TICKETS } from './actionTypes';
import { loadTicketsFailed, setTickets } from './actions';

function* loadTickets(action: IBaseAction<IPagedRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<ISupport> = yield call(
      getTickets,
      action.payload.page,
      action.payload.size,
      token,
    );

    yield put(setTickets(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadTicketsFailed());
  }
}

export function* watchLoadTickets() {
  yield takeEvery<IBaseAction<any>>(LOAD_TICKETS, loadTickets);
}

function* supportSaga() {
  yield all([fork(watchLoadTickets)]);
}

export default supportSaga;
