import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';

import { getLastLogin } from '../../services/customer.service';
import { useAppSelector } from '../../store/hooks';

interface ICustomerLastLoginOptions {
  userId: number;
}

export default function CustomerLastLogin(opt: ICustomerLastLoginOptions) {
  const [isLoadingLastLogin, setIsLoadingLastLogin] = useState(false);

  const token = useAppSelector((state) => state.Login.token);
  const [lastLogin, setLastLogin] = useState<Date | null>(null);

  useEffect(() => {
    setIsLoadingLastLogin(true);
    const cancelToken = axios.CancelToken.source();

    const loadLastLogin = async () => {
      try {
        const _lastLogin = await getLastLogin(
          opt.userId,
          token!,
          cancelToken.token,
        );

        setLastLogin(_lastLogin);
      } catch (err) {
      } finally {
        setIsLoadingLastLogin(false);
      }
    };

    loadLastLogin();

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, []);

  if (isLoadingLastLogin) {
    return (
      <>
        <Spinner size={'sm'} />
      </>
    );
  }

  if (lastLogin) {
    const momentDate = moment(lastLogin);

    return <>{momentDate.format('DD.MM.YYYY HH:mm')}</>;
  }

  return <div>n/a</div>;
}
