import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import axios, { CancelTokenSource } from 'axios';

import { useAppSelector } from '../../../store/hooks';
import { IModeratorStatistic } from '../../../models/moderator';
import {
  getModeratorStatistic,
  getModeratorTodaysStatistic,
} from '../../../services/moderator.service';

export interface IModeratorStatisticOptions {
  moderatorId: number;
}

export default function ModeratorMessageStatistic(
  opt: IModeratorStatisticOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [moderatorStatistic, setModeratorStatistic] =
    useState<IModeratorStatistic>();
  const [moderatorStatisticToday, setModeratorStatisticToday] =
    useState<IModeratorStatistic>();

  let cancelToken: CancelTokenSource | undefined;

  useEffect(() => {
    const loadModeratorStatistic = async function () {
      try {
        cancelToken = axios.CancelToken.source();

        const statistic = await getModeratorStatistic(opt.moderatorId, token!);
        const statisticToday = await getModeratorTodaysStatistic(
          opt.moderatorId,
          token!,
        );

        setModeratorStatistic(statistic);
        setModeratorStatisticToday(statisticToday);
      } catch (e) {}
    };

    loadModeratorStatistic();

    return () => {
      cancelToken?.cancel('Component unmounted');
    };
  }, [token, opt.moderatorId]);

  if (!moderatorStatistic || !moderatorStatisticToday) {
    return (
      <>
        <Spinner size={'sm'} />
      </>
    );
  }

  return (
    <>
      {moderatorStatistic.IncomingMessageCount} (
      {moderatorStatisticToday.IncomingMessageCount}) /{' '}
      {moderatorStatistic.OutgoingMessageCount} (
      {moderatorStatisticToday.OutgoingMessageCount})
    </>
  );
}
