import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useAppDispatch } from '../../store/hooks';
import { loadAgencies } from '../../store/agency/actions';
import { loadModerators } from '../../store/moderator/actions';

import ModeratorTable from './components/ModeratorTable';

export default function ModeratorPage() {
  const [breadcrumbItems] = useState([
    { title: 'Moderatoren', link: '#' },
    { title: 'Übersicht', link: '#' },
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      loadModerators({
        page: 0,
        size: 10,
      }),
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs title="Moderatoren" breadcrumbItems={breadcrumbItems} />

          <Row>
            <div className={'col-12'}>
              <ModeratorTable />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
