import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import CoinPackagesTable from './components/CoinPackagesTable';

export default function CoinPackages() {
  const [breadcrumbItems] = useState([
    { title: 'Einstellungen', link: '#' },
    { title: 'Flirtcoin Pakete', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Flirtcoin Pakete"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-12'}>
              <CoinPackagesTable />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
