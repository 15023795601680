import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';

import PresentOverview from './overview';

export default function PresentPage() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={match.path}>
          <PresentOverview />
        </Route>
      </Switch>
    </>
  );
}
