import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { getProfilePictureUrl } from '../../../services/customer.service';
import CustomerEditProfilePictureModal from '../../../components/Customer/CustomerEditProfilePictureModal';
import CustomerSelectModal from '../../../components/Customer/CustomerSelectModal';

interface ICustomerPictureOptions {
  userId: number | undefined;
}

export default function CustomerProfilePicture(
  options: ICustomerPictureOptions,
) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const history = useHistory();
  const [showCustomerSearch, setShowCustomerSearch] = useState(false);

  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();

  const updateProfilePictureUrl = () => {
    setProfilePictureUrl(
      `${getProfilePictureUrl(options.userId!)}?v=${new Date().valueOf()}`,
    );
  };

  useEffect(() => {
    if (!options.userId) return;

    updateProfilePictureUrl();
  }, [options.userId]);

  return (
    <>
      <CustomerEditProfilePictureModal
        userId={options.userId!}
        isOpen={isEditModalOpen}
        toggle={() => setIsEditModalOpen(!isEditModalOpen)}
        onAdded={() => updateProfilePictureUrl()}
      />
      <Card className={'flex-fill'}>
        <CardHeader>
          <div className={'d-flex flex-wrap'}>
            <div>Profilbild</div>
            <div className={'ml-auto'}>
              <a href={'#'} onClick={() => setIsEditModalOpen(true)}>
                Bearbeiten
              </a>
            </div>
          </div>
        </CardHeader>
        <CardBody
          className={
            'd-flex flex-column justify-content-center align-items-center'
          }
          style={{
            gap: '10px',
          }}
        >
          {options.userId ? (
            <img
              src={profilePictureUrl}
              className={'img-fluid'}
              style={{ borderRadius: '10px' }}
            />
          ) : (
            <Spinner size={'md'} />
          )}

          <Button color={'primary'} onClick={() => setShowCustomerSearch(true)}>
            Konversation beginnen
          </Button>
        </CardBody>
      </Card>
      {showCustomerSearch && (
        <CustomerSelectModal
          isOpen={showCustomerSearch}
          onlyModerated={true}
          toggle={() => setShowCustomerSearch(false)}
          onSelect={(profile) => {
            history.push(`/send-message/${options.userId}/${profile.UserId}`);
          }}
        />
      )}
    </>
  );
}
