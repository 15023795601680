import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';
import { loadPresents } from '../../../store/present/actions';
import { IPresent } from '../../../models/Present';
import {
  deletePresent,
  restorePresent,
} from '../../../services/present.service';
import PresentPicture from '../../../components/Present/PresentPicture';

import PresentTableToolbar from './PresentTableToolbar';
import PresentSearch from './PresentSearch';
import PresentEditModal from './PresentEditModal';

export default function PresentTable() {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const token = useAppSelector((state) => state.Login.token);

  const loading = useAppSelector((state) => state.Present.isLoading);
  const presents = useAppSelector((state) => state.Present.presents);

  const [editPresent, setEditPresent] = useState<IPresent | undefined>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const onEditClick = function (row: IPresent) {
    setEditPresent(row);
    setIsEditModalOpen(true);
  };

  const onDeleteClick = function (row: IPresent) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Löschen',
      isOpen: true,
      content: 'Wollen Sie dieses Geschenk wirklich als gelöscht markieren?',
      confirmCallback: async () => {
        await deletePresent(row.PresentId, token!);
        toast.success('Das Geschenk wurde erfolgreich gelöscht.');

        dispatch(
          loadPresents({
            page,
            size: pageSize,
            searchTerm,
          }),
        );
      },
    });
  };

  const onRestoreClick = function (row: IPresent) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Wiederherstellen',
      isOpen: true,
      content: 'Wollen Sie dieses Geschenk wirklich wiederherstellen?',
      confirmCallback: async () => {
        await restorePresent(row.PresentId, token!);
        toast.success('Das Geschenk wurde erfolgreich wiederhergestellt.');

        dispatch(
          loadPresents({
            page,
            size: pageSize,
            searchTerm,
          }),
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'PresentId',
      headerName: '#',
      width: 50,
    },
    {
      field: 'Image',
      headerName: ' ',
      width: 80,
      renderCell: (cell) => {
        const present = cell.row as IPresent;

        return (
          <>
            <PresentPicture
              presentId={present.PresentId}
              key={present.PresentId}
              height={30}
            />
          </>
        );
      },
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Description',
      headerName: 'Beschreibung',
      flex: 1,
    },
    {
      field: 'Costs',
      headerName: 'Kosten',
      flex: 1,
      renderCell: (cell) => (
        <>
          {(cell.value as number).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
          })}
        </>
      ),
    },
    {
      field: 'IsPremium',
      headerName: 'Premium',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cell) => {
        const isPremium = cell.value as boolean;

        if (isPremium) {
          return <i className={'ri-check-line ri-2x'} />;
        }

        return <i className={'ri-close-line  ri-2x'} />;
      },
    },

    {
      field: 'IsDeleted',
      headerName: 'Gelöscht',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cell) => {
        const isPremium = cell.value as boolean;

        if (isPremium) {
          return <i className={'ri-check-line ri-2x'} />;
        }

        return <i className={'ri-close-line  ri-2x'} />;
      },
    },
    {
      field: 'DateCreate',
      headerName: 'Erstellt Am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (cell) => {
        const present = cell.row as IPresent;

        if (present.IsDeleted) {
          return (
            <div className={'d-flex flex-row'}>
              <Button
                color={'info'}
                className={'ml-2 btn-sm btn-rounded'}
                onClick={() => console.log()}
              >
                <i className={'ri-edit-2-line'} />
              </Button>

              <Button
                color={'warning'}
                className={'ml-2 btn-sm btn-rounded'}
                onClick={() => onRestoreClick(cell.row as IPresent)}
              >
                <i className={'ri-refresh-line'} />
              </Button>
            </div>
          );
        }

        return (
          <div className={'d-flex flex-row'}>
            <Button
              color={'info'}
              className={'ml-2 btn-sm btn-rounded'}
              onClick={() => onEditClick(cell.row as IPresent)}
            >
              <i className={'ri-edit-2-line'} />
            </Button>

            <Button
              color={'danger'}
              className={'ml-2 btn-sm btn-rounded'}
              onClick={() => onDeleteClick(cell.row as IPresent)}
            >
              <i className={'ri-delete-bin-2-line'} />
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      loadPresents({
        page,
        size: pageSize,
        searchTerm: '',
      }),
    );
  }, [pageSize, page]);

  return (
    <Card>
      <CardBody>
        <PresentEditModal
          isOpen={isEditModalOpen}
          present={editPresent}
          close={() => {
            dispatch(
              loadPresents({
                page,
                size: pageSize,
                searchTerm,
              }),
            );
            setIsEditModalOpen(false);
            setEditPresent(undefined);
          }}
        />
        <PresentSearch
          onSearch={(searchTerm) => {
            setSearchTerm(searchTerm);
            dispatch(
              loadPresents({
                page,
                size: pageSize,
                searchTerm,
              }),
            );
          }}
        />
        <DataGrid
          rows={presents.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          components={{
            Toolbar: PresentTableToolbar,
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.PresentId}
          autoHeight={true}
          rowCount={presents.Total}
          paginationMode={'server'}
          disableSelectionOnClick
          loading={loading}
        />
      </CardBody>
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
    </Card>
  );
}
