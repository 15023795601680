import axios, { CancelToken } from 'axios';

import { IPagedResult } from '../models';
import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { ICoinPackage } from '../models/coin-package';
import { IPaymentMethod } from '../models/payment-methods';

export async function getCoinPackages(
  page: number,
  size: number,
  token: string,
): Promise<IPagedResult<ICoinPackage>> {
  return (
    await axios.get(
      `${environment.API_URL}/coinpackages/`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
        },
      }),
    )
  ).data;
}

export async function activateCoinPackage(
  coinPackageId: number,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/coinpackages/${coinPackageId}/activate`,
    {},
    getAxiosOptions(token),
  );
}

export async function deactivateCoinPackage(
  coinPackageId: number,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/coinpackages/${coinPackageId}/deactivate`,
    {},
    getAxiosOptions(token),
  );
}

export async function getCoinPackagesPaymentMethods(
  coinPackageId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<IPaymentMethod[]> {
  return (
    await axios.get(
      `${environment.API_URL}/coinpackages/${coinPackageId}/payment-methods`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function assignPaymentMethods(
  coinPackageId: number,
  paymentMethods: number[],
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/coinpackages/${coinPackageId}/assign-payment-methods`,
    {
      paymentMethodIds: paymentMethods,
    },
    getAxiosOptions(token),
  );
}

export async function getCoinPackagePictureUrl(
  coinPackageId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<string> {
  const pictureData = (
    await axios.get(
      `${environment.API_URL}/coinpackages/${coinPackageId}/picture`,
      getAxiosOptions(token, {
        responseType: 'blob',
        cancelToken,
      }),
    )
  ).data;

  return URL.createObjectURL(pictureData);
}

export async function createCoinPackage(
  name: string,
  description: string | undefined,
  amount: number,
  price: number,
  oneTimePackage: boolean,
  bgColor: string,
  image: File,
  orderNr: number,
  popular: boolean,
  token: string,
): Promise<ICoinPackage> {
  const formData = new FormData();
  formData.append('name', name);

  if (description) {
    formData.append('description', description);
  }

  formData.append('amount', amount.toString());
  formData.append('price', price.toString());
  formData.append('oneTimePackage', oneTimePackage ? 'true' : 'false');
  formData.append('bgColor', bgColor);
  formData.append('image', image);
  formData.append('orderNr', orderNr.toString());
  formData.append('popular', popular ? 'true' : 'false');

  return (
    await axios.post<ICoinPackage>(
      `${environment.API_URL}/coinpackages`,
      formData,
      getAxiosOptions(token),
    )
  ).data;
}

export async function updateCoinPackage(
  coinPackageId: number,
  name: string,
  description: string | undefined,
  amount: number,
  price: number,
  oneTimePackage: boolean,
  bgColor: string,
  image: File | undefined,
  orderNr: number,
  popular: boolean,
  token: string,
): Promise<ICoinPackage> {
  const formData = new FormData();
  formData.append('name', name);

  if (description) {
    formData.append('description', description);
  }

  formData.append('amount', amount.toString());
  formData.append('price', price.toString());
  formData.append('oneTimePackage', oneTimePackage ? 'true' : 'false');
  formData.append('bgColor', bgColor);

  if (image) {
    formData.append('image', image);
  }

  formData.append('orderNr', orderNr.toString());
  formData.append('popular', popular ? 'true' : 'false');

  return (
    await axios.put<ICoinPackage>(
      `${environment.API_URL}/coinpackages/${coinPackageId}`,
      formData,
      getAxiosOptions(token),
    )
  ).data;
}
