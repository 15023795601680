import { IBaseAction } from '../type';
import { IActiveDialog, IActiveDialogHistory } from '../../models/animation';

import {
  LOAD_ACTIVE_DIALOGS,
  LOAD_ACTIVE_DIALOGS_FAILED,
  LOAD_FINISHED_DIALOGS,
  LOAD_FINISHED_DIALOGS_FAILED,
  LOAD_INS_TODAY,
  LOAD_INS_TODAY_FAILED,
  LOAD_NEW_CUSTOMER_TODAY,
  LOAD_NEW_CUSTOMER_TODAY_FAILED,
  LOAD_OPEN_DIALOGS,
  LOAD_OPEN_DIALOGS_FAILED,
  LOAD_PENDING_DIALOGS,
  LOAD_PENDING_DIALOGS_FAILED,
  LOAD_TOTAL_COINS,
  LOAD_TOTAL_COINS_FAILED,
  SET_ACTIVE_DIALOGS,
  SET_DIALOG_HISTORY,
  SET_FINISHED_DIALOGS,
  SET_INS_TODAY,
  SET_LATEST_MESSAGE_DIALOG,
  SET_NEW_CUSTOMER_TODAY,
  SET_OPEN_DIALOGS,
  SET_PENDING_DIALOGS,
  SET_TOTAL_COINS,
  TRANSFER_DIALOG,
} from './actionTypes';

export const setFinishedDialogs = (data: number): IBaseAction<number> => ({
  type: SET_FINISHED_DIALOGS,
  payload: data,
});

export const loadFinishedDialogs = (): IBaseAction<any> => ({
  type: LOAD_FINISHED_DIALOGS,
  payload: null,
});

export const loadFinishedDialogsFailed = (): IBaseAction<any> => ({
  type: LOAD_FINISHED_DIALOGS_FAILED,
  payload: null,
});

export const setPendingDialogs = (data: number): IBaseAction<number> => ({
  type: SET_PENDING_DIALOGS,
  payload: data,
});

export const loadPendingDialogs = (): IBaseAction<any> => ({
  type: LOAD_PENDING_DIALOGS,
  payload: null,
});

export const loadPendingDialogsFailed = (): IBaseAction<any> => ({
  type: LOAD_PENDING_DIALOGS_FAILED,
  payload: null,
});

export const setTotalCoins = (data: number): IBaseAction<number> => ({
  type: SET_TOTAL_COINS,
  payload: data,
});

export const loadTotalCoins = (): IBaseAction<any> => ({
  type: LOAD_TOTAL_COINS,
  payload: null,
});

export const loadTotalCoinsFailed = (): IBaseAction<any> => ({
  type: LOAD_TOTAL_COINS_FAILED,
  payload: null,
});

export const setNewCustomersToday = (data: number): IBaseAction<number> => ({
  type: SET_NEW_CUSTOMER_TODAY,
  payload: data,
});

export const loadNewCustomersToday = (): IBaseAction<any> => ({
  type: LOAD_NEW_CUSTOMER_TODAY,
  payload: null,
});

export const loadNewCustomersTodayFailed = (): IBaseAction<any> => ({
  type: LOAD_NEW_CUSTOMER_TODAY_FAILED,
  payload: null,
});

export const setInsToday = (data: number): IBaseAction<number> => ({
  type: SET_INS_TODAY,
  payload: data,
});

export const loadInsToday = (): IBaseAction<any> => ({
  type: LOAD_INS_TODAY,
  payload: null,
});

export const loadInsTodayFailed = (): IBaseAction<any> => ({
  type: LOAD_INS_TODAY_FAILED,
  payload: null,
});

export const setActiveDialogs = (
  data: IActiveDialog[],
): IBaseAction<IActiveDialog[]> => ({
  type: SET_ACTIVE_DIALOGS,
  payload: data,
});

export const loadActiveDialogs = (): IBaseAction<any> => ({
  type: LOAD_ACTIVE_DIALOGS,
  payload: null,
});

export const loadActiveDialogsFailed = (): IBaseAction<any> => ({
  type: LOAD_ACTIVE_DIALOGS_FAILED,
  payload: null,
});

export const setOpenDialogs = (
  data: IActiveDialog[],
): IBaseAction<IActiveDialog[]> => ({
  type: SET_OPEN_DIALOGS,
  payload: data,
});

export const loadOpenDialogs = (): IBaseAction<any> => ({
  type: LOAD_OPEN_DIALOGS,
  payload: null,
});

export const loadOpenDialogsFailed = (): IBaseAction<any> => ({
  type: LOAD_OPEN_DIALOGS_FAILED,
  payload: null,
});

export const setDialogHistory = (
  data: IActiveDialogHistory | null,
): IBaseAction<IActiveDialogHistory | null> => ({
  type: SET_DIALOG_HISTORY,
  payload: data,
});

export const openTransferDialog = (
  dialogId: number | null,
): IBaseAction<number | null> => ({
  type: TRANSFER_DIALOG,
  payload: dialogId,
});

export const setLatestMessageDialog = (
  userId?: number,
  fakeId?: number,
): IBaseAction<{ userId?: number; fakeId?: number }> => ({
  type: SET_LATEST_MESSAGE_DIALOG,
  payload: {
    userId,
    fakeId,
  },
});
