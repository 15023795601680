import { IBaseAction } from '../type';

import { REDIRECT } from './actionTypes';

interface IRedirectState {
  redirectTo: string | null;
}

const initialState: IRedirectState = {
  redirectTo: null,
};

const redirect = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case REDIRECT:
      state = {
        ...state,
        redirectTo: action.payload.path,
      };
      break;
    default:
      break;
  }
  return state;
};

export default redirect;
