import React from 'react';

import { IActiveDialog } from '../../../models/animation';

import ActiveDialogRow from './ActiveDialogRow';

interface IActiveDialogTableOptions {
  rows: IActiveDialog[];
}

export default function ActiveDialogTable(opt: IActiveDialogTableOptions) {
  return (
    <div className={'table-responsive'}>
      <table className={'table table-hover'}>
        <thead>
          <tr>
            <td>Kunde</td>
            <td></td>
            <td>Typ</td>
            <td>letzter Login</td>
            <td>OUT (heute)</td>
            <td>IN (heute)</td>
            <td>Profil</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {opt.rows.map((row) => (
            <ActiveDialogRow key={row.DialogId} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
