import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';

import { getCustomerConversations } from '../../../services/customer.service';
import { useAppSelector } from '../../../store/hooks';
import CustomerName from '../../../components/Customer/CustomerName';

interface ICustomerConversationsOptions {
  userId: number | undefined;
}

export default function CustomerConversations(
  options: ICustomerConversationsOptions,
) {
  const [isLoadingCustomerConversations, setIsLoadingCustomerConversations] =
    useState(false);
  const [customerConversations, setCustomerConversations] = useState<number[]>(
    [],
  );

  const token = useAppSelector((state) => state.Login.token);

  const history = useHistory();

  useEffect(() => {
    if (!options.userId) return;

    setIsLoadingCustomerConversations(true);

    const cancelToken = axios.CancelToken.source();

    const loadVisitedProfiles = async () => {
      const profiles = await getCustomerConversations(
        options.userId!,
        token!,
        cancelToken.token,
      );

      setCustomerConversations(profiles);
    };

    loadVisitedProfiles();

    setIsLoadingCustomerConversations(false);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [options.userId]);

  if (isLoadingCustomerConversations) {
    return (
      <>
        <Card>
          <CardHeader>Konversation geführt mit</CardHeader>
          <CardBody>
            <Spinner />
          </CardBody>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>Letzte 20 Konversationen geführt mit</CardHeader>
        <CardBody>
          <Scrollbars style={{ height: 300 }}>
            {customerConversations.length <= 0
              ? 'Keine Konversationen geführt'
              : ''}
            {customerConversations.map((profile) => (
              <Card className={'d-flex flex-fill'} key={profile}>
                <CardBody className={'d-flex flex-fill align-items-center'}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/customers/${profile}`)}
                  >
                    <CustomerName userId={profile} />
                  </div>
                  <div className={'ml-auto'}>
                    <Button
                      color={'primary'}
                      onClick={() =>
                        history.push(
                          `/send-message/${options.userId!}/${profile}`,
                        )
                      }
                    >
                      Konversation starten
                    </Button>
                  </div>
                </CardBody>
              </Card>
            ))}
          </Scrollbars>
        </CardBody>
      </Card>
    </>
  );
}
