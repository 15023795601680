import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IPagedRequest, IPagedResult } from '../../models';
import { IAgency } from '../../models/agency';
import { getAgencies } from '../../services/agency.service';

import { loadAgenciesFailed, setAgencies } from './actions';
import { LOAD_AGENCIES } from './actionTypes';

function* loadAgencies(action: IBaseAction<IPagedRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<IAgency> = yield call(
      getAgencies,
      action.payload.page,
      action.payload.size,
      token,
    );

    yield put(setAgencies(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadAgenciesFailed());
  }
}

export function* watchLoadAgencies() {
  yield takeEvery<IBaseAction<any>>(LOAD_AGENCIES, loadAgencies);
}

function* agencySaga() {
  yield all([fork(watchLoadAgencies)]);
}

export default agencySaga;
