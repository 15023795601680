import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import axios, { CancelToken } from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { deleteUser, getDoubleIPHosts } from '../../services/customer.service';
import { useAppSelector } from '../../store/hooks';
import { ICustomerHost } from '../../models/customer';
import CustomerName from '../Customer/CustomerName';
import CustomerLastLogin from '../Customer/CustomerLastLogin';
import { ConfirmDialog, IConfirmDialogOptions } from '../Common/ConfirmDialog';

export default function DoubleIPUser() {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoading, setIsLoading] = useState(false);
  const [doubleHosts, setDoubleHosts] = useState<ICustomerHost[]>([]);

  const history = useHistory();

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const handleDelete = (userId: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Löschen',
      content: 'Wollen Sie die Agentur wirklich löschen?',
      confirmCallback: async () => {
        await deleteUser(userId, token!);
        toast.success('Der Benutzer wurde erfolgreich gesperrt!');
        load();
      },
    });
  };

  const load = async (cancelToken?: CancelToken) => {
    setIsLoading(true);

    try {
      const users = await getDoubleIPHosts(token!, cancelToken);
      setDoubleHosts(users);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    load();

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, []);

  if (isLoading) {
    return (
      <Card>
        <CardHeader>Benutzer mit doppelter IP-Adresse</CardHeader>
        <CardBody>
          <Spinner />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>Benutzer mit doppelter IP-Adresse</CardHeader>
        <CardBody>
          <div className={'table-responsive'}>
            <table className={'table table-hover'}>
              <thead>
                <tr>
                  <th>Benutzername</th>
                  <th>IP</th>
                  <th>zuletzt angemeldet</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {doubleHosts.map((host) => (
                  <tr key={host.UserId}>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/customers/${host.UserId}`)}
                    >
                      <CustomerName userId={host.UserId} />
                    </td>
                    <td>{host.Host}</td>
                    <td>
                      <CustomerLastLogin userId={host.UserId} />
                    </td>
                    <td>
                      <Button
                        color={'danger'}
                        onClick={() => {
                          handleDelete(host.UserId);
                        }}
                      >
                        Sperren
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
    </>
  );
}
