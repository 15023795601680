import React, { CSSProperties, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { asEnumerable } from 'linq-es2015';

import RefreshButton from '../Common/RefreshButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  loadActiveDialogs,
  setDialogHistory,
} from '../../store/animation/actions';

import ActiveDialogModerator from './components/ActiveDialogModerator';
import ActiveDialogHistory from './components/ActiveDialogHistory';

export default function ActiveDialogs() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state) => state.Animation.isLoadingActiveDialogs,
  );
  const activeDialogs = useAppSelector(
    (state) => state.Animation.activeDialogs,
  );
  const activeDialogHistory = useAppSelector(
    (state) => state.Animation.dialogHistory,
  );

  const [isHistoryOpen, setHistoryOpen] = useState(false);

  useEffect(() => {
    dispatch(setDialogHistory(null));
  }, [dispatch]);

  useEffect(() => {
    const shouldOpen = !!activeDialogHistory;
    setHistoryOpen(shouldOpen);

    if (shouldOpen) {
      setTimeout(() => {
        document
          .getElementById('history')
          ?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [activeDialogHistory]);

  const historyStyle = {
    display: isHistoryOpen ? 'flex' : 'none',
  } as CSSProperties;

  return (
    <>
      <Card>
        <LoadingOverlay spinner active={isLoading}>
          <CardHeader className={'d-flex flex-row align-items-center'}>
            <div className={'mr-auto'}>
              <h4>Dialoge</h4>
            </div>
            <div className={'ml-auto'}>
              <RefreshButton
                refreshAction={() => dispatch(loadActiveDialogs())}
              />
            </div>
          </CardHeader>
          <CardBody>
            {asEnumerable(activeDialogs)
              .GroupBy((x) => x.ModeratorId)
              .ToArray()
              .map((mod) => (
                <ActiveDialogModerator
                  ModeratorId={mod.key!}
                  Dialogs={mod}
                  key={mod.key}
                />
              ))}
          </CardBody>
        </LoadingOverlay>
      </Card>
      <Card style={historyStyle} id={'history'}>
        <CardHeader>
          <h4>Verlauf</h4>
        </CardHeader>
        <CardBody>
          <ActiveDialogHistory
            key={
              activeDialogHistory
                ? `${activeDialogHistory.userId}-${activeDialogHistory.partnerId}`
                : ''
            }
            userId={activeDialogHistory?.userId}
            fakeId={activeDialogHistory?.partnerId}
          />
        </CardBody>
      </Card>
    </>
  );
}
