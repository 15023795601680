import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IPagedCustomerRequest, IPagedResult } from '../../models';
import { ICustomer } from '../../models/customer';
import {
  getConfirmedCustomers,
  getDeletedCustomers,
  getModeratedCustomers,
  getUnconfirmedCustomers,
} from '../../services/customer.service';

import {
  loadConfirmedCustomersFailed,
  loadDeletedCustomersFailed,
  loadModeratedCustomersFailed,
  loadUnconfirmedCustomersFailed,
  setConfirmedCustomers,
  setDeletedCustomers,
  setModeratedCustomers,
  setUnconfirmedCustomers,
} from './actions';
import {
  LOAD_CONFIRMED_CUSTOMERS,
  LOAD_DELETED_CUSTOMERS,
  LOAD_MODERATED_CUSTOMERS,
  LOAD_UNCONFIRMED_CUSTOMERS,
} from './actionTypes';

function* loadConfirmedCustomers(action: IBaseAction<IPagedCustomerRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<ICustomer> = yield call(
      getConfirmedCustomers,
      action.payload.page,
      action.payload.size,
      action.payload.username,
      token,
    );

    yield put(setConfirmedCustomers(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadConfirmedCustomersFailed());
  }
}

function* loadModeratedCustomers(action: IBaseAction<IPagedCustomerRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<ICustomer> = yield call(
      getModeratedCustomers,
      action.payload.page,
      action.payload.size,
      action.payload.username,
      token,
    );

    yield put(setModeratedCustomers(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadModeratedCustomersFailed());
  }
}

function* loadUnconfirmedCustomers(action: IBaseAction<IPagedCustomerRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<ICustomer> = yield call(
      getUnconfirmedCustomers,
      action.payload.page,
      action.payload.size,
      action.payload.username,
      token,
    );

    yield put(setUnconfirmedCustomers(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadUnconfirmedCustomersFailed());
  }
}

function* loadDeletedCustomers(action: IBaseAction<IPagedCustomerRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<ICustomer> = yield call(
      getDeletedCustomers,
      action.payload.page,
      action.payload.size,
      action.payload.username,
      token,
    );

    yield put(setDeletedCustomers(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadDeletedCustomersFailed());
  }
}

export function* watchLoadConfirmedCustomers() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_CONFIRMED_CUSTOMERS,
    loadConfirmedCustomers,
  );
}

export function* watchLoadUnconfirmedCustomers() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_UNCONFIRMED_CUSTOMERS,
    loadUnconfirmedCustomers,
  );
}

export function* watchLoadDeletedCustomers() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_DELETED_CUSTOMERS,
    loadDeletedCustomers,
  );
}

export function* watchLoadModeratedCustomers() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_MODERATED_CUSTOMERS,
    loadModeratedCustomers,
  );
}

function* customerSaga() {
  yield all([
    fork(watchLoadConfirmedCustomers),
    fork(watchLoadUnconfirmedCustomers),
    fork(watchLoadDeletedCustomers),
    fork(watchLoadModeratedCustomers),
  ]);
}

export default customerSaga;
