import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

// Login Redux States

import { IBaseAction } from '../../type';
import { IRevenue } from '../../../models/statistic';
import {
  getIncomingMessages,
  getNewCustomersCount,
  getOnlineCustomersCount,
  getOutgoingMessages,
  getPendingAsaDialogsCount,
  getPendingNewCustomerDialogsCount,
  getPendingNormalDialogsCount,
  getRevenue,
  getTodaysRevenue,
  getTodaysSentMessages,
} from '../../../services/statistic.service';
import { RootState } from '../../index';

import {
  loadIncomingMessagesFailed,
  loadNewCustomersCountFailed,
  loadOnlineCustomersCountFailed,
  loadOutgoingMessagesFailed,
  loadPendingAsaDialogsCountFailed,
  loadPendingNewCustomerDialogsCountFailed,
  loadPendingNormalDialogsCountFailed,
  loadRevenueFailed,
  loadTodaySentMessagesFailed,
  loadTodaysRevenueFailed,
  setIncomingMessages,
  setNewCustomerCount,
  setOnlineCustomersCount,
  setOutgoingMessages,
  setPendingAsaDialogsCount,
  setPendingNewCustomerDialogsCount,
  setPendingNormalDialogsCount,
  setRevenue,
  setTodaySentMessages,
  setTodaysRevenue,
} from './actions';
import {
  LOAD_INCOMING_MESSAGES,
  LOAD_NEW_CUSTOMERS_COUNT,
  LOAD_ONLINE_CUSTOMERS_COUNT,
  LOAD_OUTGOING_MESSAGES,
  LOAD_PENDING_ASA_DIALOGS_COUNT,
  LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
  LOAD_PENDING_NORMAL_DIALOGS_COUNT,
  LOAD_REVENUE,
  LOAD_TODAYS_REVENUE,
  LOAD_TODAY_SENT_MESSAGES,
} from './actionTypes';

export const getAuthToken = (state: RootState) => state.Login.token;

function* loadRevenue(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IRevenue[] = yield call(getRevenue, token);

    // const response = yield call(fireBaseBackend.loginUser, user.username, user.password);
    yield put(setRevenue(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadRevenueFailed());
  }
}

function* loadTodaysRevenue(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getTodaysRevenue, token);

    yield put(setTodaysRevenue(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadTodaysRevenueFailed());
  }
}

function* loadTodaySentMessages(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getTodaysSentMessages, token);

    yield put(setTodaySentMessages(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadTodaySentMessagesFailed());
  }
}

function* loadOnlineCustomersCount(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getOnlineCustomersCount, token);

    yield put(setOnlineCustomersCount(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadOnlineCustomersCountFailed());
  }
}

function* loadIncomingMessages(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getIncomingMessages, token);

    yield put(setIncomingMessages(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadIncomingMessagesFailed());
  }
}

function* loadOutgoingMessages(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getOutgoingMessages, token);

    yield put(setOutgoingMessages(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadOutgoingMessagesFailed());
  }
}

function* loadNewCustomersCount(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getNewCustomersCount, token);

    yield put(setNewCustomerCount(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadNewCustomersCountFailed());
  }
}

function* loadPendingAsaDialogsCount(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getPendingAsaDialogsCount, token);

    yield put(setPendingAsaDialogsCount(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadPendingAsaDialogsCountFailed());
  }
}

function* loadPendingNormalDialogsCount(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(getPendingNormalDialogsCount, token);

    yield put(setPendingNormalDialogsCount(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadPendingNormalDialogsCountFailed());
  }
}

function* loadPendingNewCustomerDialogsCount(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: number = yield call(
      getPendingNewCustomerDialogsCount,
      token,
    );

    yield put(setPendingNewCustomerDialogsCount(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadPendingNewCustomerDialogsCountFailed());
  }
}

export function* watchRevenueLoad() {
  yield takeEvery<IBaseAction<any>>(LOAD_REVENUE, loadRevenue);
}

export function* watchTodaysRevenueLoad() {
  yield takeEvery<IBaseAction<any>>(LOAD_TODAYS_REVENUE, loadTodaysRevenue);
}

export function* watchOnlineCustomersCountLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_ONLINE_CUSTOMERS_COUNT,
    loadOnlineCustomersCount,
  );
}

export function* watchIncomingMessagesLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_INCOMING_MESSAGES,
    loadIncomingMessages,
  );
}

export function* watchOutgoingMessagesLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_OUTGOING_MESSAGES,
    loadOutgoingMessages,
  );
}

export function* watchNewCustomersCountLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_NEW_CUSTOMERS_COUNT,
    loadNewCustomersCount,
  );
}

export function* watchPendingAsaDialogsCountLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_PENDING_ASA_DIALOGS_COUNT,
    loadPendingAsaDialogsCount,
  );
}

export function* watchPendingNormalDialogsCountLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_PENDING_NORMAL_DIALOGS_COUNT,
    loadPendingNormalDialogsCount,
  );
}

export function* watchPendingNewCustomerDialogsCountLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
    loadPendingNewCustomerDialogsCount,
  );
}

export function* watchTodaySentMessagesLoad() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_TODAY_SENT_MESSAGES,
    loadTodaySentMessages,
  );
}

function* statisticSaga() {
  yield all([
    fork(watchRevenueLoad),
    fork(watchTodaysRevenueLoad),
    fork(watchOnlineCustomersCountLoad),
    fork(watchIncomingMessagesLoad),
    fork(watchOutgoingMessagesLoad),
    fork(watchNewCustomersCountLoad),
    fork(watchPendingAsaDialogsCountLoad),
    fork(watchPendingNormalDialogsCountLoad),
    fork(watchPendingNewCustomerDialogsCountLoad),
    fork(watchTodaySentMessagesLoad),
  ]);
}

export default statisticSaga;
