import axios from 'axios';

import environment from '../helpers/environment';
import { IRevenue } from '../models/statistic';
import getAxiosOptions from '../helpers/axios_helper';

export async function getRevenue(token: string): Promise<IRevenue[]> {
  return (
    await axios.get<IRevenue[]>(
      `${environment.API_URL}/statistic/revenue`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getTodaysRevenue(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/revenue-today`,
      getAxiosOptions(token),
    )
  ).data;
}
export async function getTodaysSentMessages(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/sent-messages-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOnlineCustomersCount(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/online-customers`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getIncomingMessages(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/incoming-messages`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOutgoingMessages(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/outgoing-messages`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getNewCustomersCount(token: string): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/new-customers`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPendingAsaDialogsCount(
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/pending-asa-dialogs`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPendingNewCustomerDialogsCount(
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/pending-new-customer-dialogs`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPendingNormalDialogsCount(
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/pending-normal-dialogs`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getInsTodayPerProfileWithoutModerator(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/${userId}/${fakeId}/ins-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOutsTodayPerProfileWithoutModerator(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/${userId}/${fakeId}/outs-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getInsTotalPerProfileWithoutModerator(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/${userId}/${fakeId}/ins-total`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOutsTotalPerProfileWithoutModerator(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/statistic/${userId}/${fakeId}/outs-total`,
      getAxiosOptions(token),
    )
  ).data;
}
