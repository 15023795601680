import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Spinner,
} from 'reactstrap';

import { IModerator } from '../../../models/moderator';
import { IActiveDialog } from '../../../models/animation';
import { useAppSelector } from '../../../store/hooks';
import { getModerator } from '../../../services/moderator.service';

import ActiveDialogTable from './ActiveDialogTable';

interface IActiveDialogModeratorOptions {
  ModeratorId: number | null;
  Dialogs: IActiveDialog[];
}

const collapsableLink = {
  cursor: 'pointer',
} as CSSProperties;

export default function ActiveDialogModerator(
  opt: IActiveDialogModeratorOptions,
) {
  const token = useAppSelector((state) => state.Login.token);
  const [moderator, setModerator] = useState<IModerator>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!opt.ModeratorId) return;

    let cancel = false;

    const loadModerator = async function () {
      const moderator = await getModerator(opt.ModeratorId!, token!);

      if (cancel) return;
      setModerator(moderator);
    };

    loadModerator();

    return () => {
      cancel = true;
    };
  }, [opt.ModeratorId, token]);

  return (
    <Card key={opt.ModeratorId ?? 'pool'}>
      <CardHeader>
        <Button onClick={() => setIsOpen(!isOpen)} style={collapsableLink}>
          {opt.ModeratorId
            ? moderator?.Username ?? <Spinner size={'sm'} />
            : 'Dialoge von Pool'}
        </Button>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <ActiveDialogTable rows={opt.Dialogs} />
        </CardBody>
      </Collapse>
    </Card>
  );
}
