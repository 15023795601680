import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import LoadingButton from '../Common/LoadingButton';
import CustomerName from '../Customer/CustomerName';
import CustomerSelectModal from '../Customer/CustomerSelectModal';
import { closeConfirmationUrlGenerator } from '../../store/tools/actions';
import { getConfirmationUrl } from '../../services/customer.service';

export default function ConfirmationUrlGeneratorModal() {
  const shouldOpen = useAppSelector(
    (state) => state.Tools.isConfirmationUrlGeneratorModalOpen,
  );
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.Login.token);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [customerId, setCustomerId] = useState<number>();

  const [isSelectCustomerModalOpen, setIsSelectCustomerModalOpen] =
    useState(false);
  const [confirmationUrl, setConfirmationUrl] = useState<string>();

  const urlInputRef = useRef<HTMLInputElement>(null);

  const generateConfirmationUrl = async () => {
    if (!customerId) return;

    setIsLoading(true);

    try {
      const url = await getConfirmationUrl(customerId, token!);

      setConfirmationUrl(url);
    } catch (err) {
      toast.error('Beim Abrufen der URL ist ein Fehler aufgetreten.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (confirmationUrl && urlInputRef && urlInputRef.current) {
      urlInputRef.current.focus();
      urlInputRef.current.select();
    }
  }, [confirmationUrl]);

  useEffect(() => {
    if (shouldOpen) {
      setIsOpen(true);
      setCustomerId(undefined);
      setConfirmationUrl(undefined);
    }
  }, [shouldOpen]);

  useEffect(() => () => {
    dispatch(closeConfirmationUrlGenerator());
  });

  useEffect(() => {
    console.log('URL', confirmationUrl);
  }, [confirmationUrl]);

  return createPortal(
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        centered={true}
        backdrop={true}
      >
        <ModalHeader>Bestätigungs URL generieren</ModalHeader>
        <ModalBody>
          {confirmationUrl !== undefined ? (
            <div
              className={
                'd-flex flex-column flex-wrap align-items-center flex-fill'
              }
            >
              <div className={'w-100'}>URL</div>
              <div className={'w-100'}>
                <input
                  type={'text'}
                  readOnly={true}
                  value={confirmationUrl || ''}
                  className={'form-control'}
                  ref={urlInputRef}
                />
              </div>
              <div className={'w-100'}>
                <small>
                  Beachten Sie, dass die Domain die hier angezeigt wird, nicht
                  mit der Benutzer-Domain übereinstimmen kann.
                </small>
              </div>
              <div className={'mt-2'}>
                <Button color={'primary'} onClick={() => setIsOpen(false)}>
                  Schließen
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className={'d-flex flex-wrap align-items-center'}>
                <div>
                  <strong>Kunde</strong>
                </div>
                <div className={'ml-2'}>
                  {customerId ? (
                    <CustomerName userId={customerId} />
                  ) : (
                    <i>nicht ausgewählt</i>
                  )}
                </div>
                <div className={'ml-auto'}>
                  <Button
                    color={'danger'}
                    size={'sm'}
                    onClick={() => setIsSelectCustomerModalOpen(true)}
                  >
                    auswählen
                  </Button>
                </div>
              </div>

              <div className={'mt-3'}>
                <LoadingButton
                  isLoading={isLoading}
                  caption={'Generieren'}
                  isSubmit={false}
                  disabled={customerId === undefined}
                  onClick={generateConfirmationUrl}
                />
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
      <CustomerSelectModal
        isOpen={isSelectCustomerModalOpen}
        toggle={() => setIsSelectCustomerModalOpen(!isSelectCustomerModalOpen)}
        onSelect={(customer) => setCustomerId(customer.UserId)}
      />
    </>,
    document.body,
  );
}
