import React, { useEffect, useState } from 'react';

import {
  getOutsTodayPerProfile,
  getOutsTotalPerProfile,
} from '../../../services/moderator.service';
import { useAppSelector } from '../../../store/hooks';
import {
  getOutsTodayPerProfileWithoutModerator,
  getOutsTotalPerProfileWithoutModerator,
} from '../../../services/statistic.service';

interface IActiveDialogINOptions {
  ModeratorId: number | null;
  FakeId: number;
  UserId: number;
}

export default function ActiveDialogOUT(opt: IActiveDialogINOptions) {
  const token = useAppSelector((state) => state.Login.token);
  const [outs, setOuts] = useState({
    total: 0,
    today: 0,
  });

  useEffect(() => {
    let cancel = false;

    const loadInsOuts = async function () {
      let todaysOuts = 0;
      let totalOuts = 0;

      if (!opt.ModeratorId) {
        todaysOuts = await getOutsTodayPerProfileWithoutModerator(
          opt.FakeId,
          opt.UserId,
          token!,
        );
        totalOuts = await getOutsTotalPerProfileWithoutModerator(
          opt.FakeId,
          opt.UserId,
          token!,
        );
      } else {
        todaysOuts = await getOutsTodayPerProfile(
          opt.ModeratorId,
          opt.FakeId,
          opt.UserId,
          token!,
        );
        totalOuts = await getOutsTotalPerProfile(
          opt.ModeratorId,
          opt.FakeId,
          opt.UserId,
          token!,
        );
      }

      if (cancel) return;
      setOuts({
        today: todaysOuts,
        total: totalOuts,
      });
    };

    loadInsOuts();

    return () => {
      cancel = true;
    };
  }, [opt.ModeratorId, opt.FakeId, opt.UserId, token]);

  return (
    <>
      {outs.total} ({outs.today})
    </>
  );
}
