import axios from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { INotification } from '../models/notification';

export async function getTotalKisses(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/user/${userId}/${fakeId}/total-kisses`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getTotalGreetings(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/user/${userId}/${fakeId}/total-greetings`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getLatestKisses(
  fakeId: number,
  userId: number,
  token: string,
): Promise<INotification[]> {
  return (
    await axios.get<INotification[]>(
      `${environment.API_URL}/user/${userId}/${fakeId}/kisses`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getLatestGreetings(
  fakeId: number,
  userId: number,
  token: string,
): Promise<INotification[]> {
  return (
    await axios.get<INotification[]>(
      `${environment.API_URL}/user/${userId}/${fakeId}/greetings`,
      getAxiosOptions(token),
    )
  ).data;
}
