import { IBaseAction } from '../type';
import { IPagedResult } from '../../models';
import { IAgency } from '../../models/agency';

import {
  LOAD_AGENCIES,
  LOAD_AGENCIES_FAILED,
  SET_AGENCIES,
} from './actionTypes';

interface IAgencyState {
  agencies: IPagedResult<IAgency>;
  isLoading: boolean;
}

const initialState: IAgencyState = {
  agencies: {
    Total: 0,
    Data: [],
  },
  isLoading: false,
};

const Agency = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_AGENCIES:
      state = {
        ...state,
        agencies: action.payload,
        isLoading: false,
      };
      break;
    case LOAD_AGENCIES:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOAD_AGENCIES_FAILED:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default Agency;
