import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deleteModerator,
  resetModeratorStatistic,
} from '../../../services/moderator.service';
import { IModerator } from '../../../models/moderator';
import { loadModerators } from '../../../store/moderator/actions';
import ModeratorMessageStatistic from '../../../components/Moderator/components/ModeratorMessageStatistic';
import ModeratorDialogStatistic from '../../../components/Moderator/components/ModeratorDialogStatistic';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';

import ModeratorEditModal from './ModeratorEditModal';
import ModeratorTableAgency from './ModeratorTableAgency';
import ModeratorTableToolbar from './ModeratorTableToolbar';

export default function ModeratorTable() {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModerator, setEditModerator] = useState<IModerator>();

  const token = useAppSelector((state) => state.Login.token);

  const loading = useAppSelector((state) => state.Moderator.isLoading);
  const moderators = useAppSelector((state) => state.Moderator.moderators);

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const onEditClick = function (row: IModerator) {
    setEditModerator(row);
    setIsEditModalOpen(true);
  };

  const onDeleteClick = function (row: IModerator) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Löschen',
      isOpen: true,
      content: 'Wollen Sie diesen Moderator wirklich als gelöscht markieren?',
      confirmCallback: async () => {
        await deleteModerator(row.ModeratorId, token!);
        toast.success('Der Moderator wurde erfolgreich gelöscht.');
        dispatch(
          loadModerators({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const onResetClick = function (row: IModerator) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'Statistik zurücksetzen',
      isOpen: true,
      content: `Wollen Sie die Statistik von ${row.Username} wirklich zurücksetzen?`,
      confirmCallback: async () => {
        await resetModeratorStatistic(row.ModeratorId, token!);
        toast.success('Die Statistik wurde erfolgreich zurückgesetzt.');
        dispatch(
          loadModerators({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'ModeratorId',
      headerName: '',
      width: 50,
    },
    {
      field: 'Agentur',
      headerName: 'Agentur',
      flex: 1,
      renderCell: (cell) => (
        <>
          <ModeratorTableAgency agencyId={cell.row.AgencyId} />
        </>
      ),
    },
    {
      field: 'Username',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Email',
      headerName: 'E-Mail',
      flex: 1,
    },
    {
      field: 'MessageStatistic',
      headerName: 'INs (heute) / OUTs (heute)',
      flex: 1,
      renderCell: (cell) => (
        <>
          <ModeratorMessageStatistic moderatorId={cell.row.ModeratorId} />
        </>
      ),
    },
    {
      field: 'DialogStatistic',
      headerName: 'Offene / Beendete Dialoge',
      flex: 1,
      renderCell: (cell) => (
        <>
          <ModeratorDialogStatistic moderatorId={cell.row.ModeratorId} />
        </>
      ),
    },
    {
      field: 'DateCreate',
      headerName: 'Erstellt Am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (cell) => (
        <div className={'d-flex flex-row'}>
          <Button
            color={'info'}
            className={'ml-2 btn-sm btn-rounded'}
            onClick={() => onEditClick(cell.row as IModerator)}
          >
            <i className={'ri-edit-2-line'} />
          </Button>

          <Button
            color={'warning'}
            className={'ml-2 btn-sm btn-rounded'}
            onClick={() => onResetClick(cell.row as IModerator)}
          >
            <i className={'ri-refresh-line'} />
          </Button>

          <Button
            color={'danger'}
            className={'ml-2 btn-sm btn-rounded'}
            onClick={() => onDeleteClick(cell.row as IModerator)}
          >
            <i className={'ri-delete-bin-2-line'} />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      loadModerators({
        page,
        size: pageSize,
      }),
    );
  }, [pageSize, page]);

  return (
    <Card>
      <CardBody>
        <DataGrid
          rows={moderators.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.ModeratorId}
          autoHeight={true}
          rowCount={moderators.Total}
          paginationMode={'server'}
          components={{
            Toolbar: ModeratorTableToolbar,
          }}
          disableSelectionOnClick
          loading={loading}
        />
      </CardBody>
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
      <ModeratorEditModal
        close={() => setIsEditModalOpen(false)}
        isOpen={isEditModalOpen}
        moderator={editModerator}
      />
    </Card>
  );
}
