import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import CustomerProfilePicture from './components/CustomerProfilePicture';
import CustomerPrivateInformation from './components/CustomerPrivateInformation';
import CustomerCoinInformation from './components/CustomerCoinInformation';
import CustomerPublicInformation from './components/CustomerPublicInformation';
import CustomerPictures from './components/CustomerPictures';
import CustomerPresents from './components/CustomerPresents';
import CustomerWishPresents from './components/CustomerWishPresents';
import CustomerInvoices from './components/CustomerInvoices';
import CustomerInterests from './components/CustomerInterest';
import CustomerVisitedProfiles from './components/CustomerVisitedProfiles';
import CustomerConversations from './components/CustomerConversations';
import CustomerVoiceDescriptions from './components/CustomerVoiceDescriptions';

type CustomerViewParams = {
  userId?: string;
};

export default function CustomerView() {
  const { userId: userIdStr } = useParams<CustomerViewParams>();

  const [userId, setUserId] = useState<number | undefined>();

  useEffect(() => {
    if (userIdStr) {
      setUserId(parseInt(userIdStr));
    }
  }, [userIdStr]);

  const [breadcrumbItems] = useState([
    { title: 'Kunden', link: '#' },
    { title: 'Ansicht', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Kundenansicht"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col sm={2} className={'d-flex align-items-stretch flex-column'}>
              <CustomerProfilePicture userId={userId} />
              <CustomerCoinInformation userId={userId} />
            </Col>

            <Col sm={5} className={'d-flex align-items-stretch flex-column'}>
              <CustomerPrivateInformation userId={userId} />
            </Col>

            <Col sm={5} className={'d-flex align-items-stretch flex-column'}>
              <CustomerPublicInformation userId={userId} />
            </Col>

            <Col sm={3} className={'d-flex align-items-stretch flex-column'}>
              <CustomerPictures userId={userId} />
            </Col>

            <Col sm={3} className={'d-flex align-items-stretch flex-column'}>
              <CustomerInterests userId={userId} />
            </Col>

            <Col sm={3} className={'d-flex align-items-stretch flex-column'}>
              <CustomerPresents userId={userId} />
            </Col>

            <Col sm={3} className={'d-flex align-items-stretch flex-column'}>
              <CustomerWishPresents userId={userId} />
            </Col>

            <Col sm={12}>
              <CustomerVoiceDescriptions userId={userId!} />
            </Col>

            <Col
              sm={12}
              md={6}
              className={'d-flex align-items-stretch flex-column'}
            >
              <CustomerVisitedProfiles userId={userId} />
            </Col>

            <Col
              sm={12}
              md={6}
              className={'d-flex align-items-stretch flex-column'}
            >
              <CustomerConversations userId={userId} />
            </Col>

            <Col sm={12}>
              <CustomerInvoices userId={userId} />
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
