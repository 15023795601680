import { Badge, Spinner } from 'reactstrap';
import React from 'react';

import { ICustomer } from '../../models/customer';

interface ICustomerStatusOptions {
  customer?: ICustomer;
}

export default function CustomerStatus(options: ICustomerStatusOptions) {
  if (!options.customer) {
    return <Spinner size={'sm'} />;
  }
  if (options.customer?.IsDeleted) {
    return <Badge color={'danger'}>Gelöscht</Badge>;
  }

  if (options.customer?.IsModerate) {
    return <Badge color={'info'}>moderiertes Profil</Badge>;
  }

  if (!options.customer?.IsConfirmed) {
    return <Badge color={'warning'}>unbestätigte E-Mail-Adresse</Badge>;
  }

  return <Badge color={'success'}>aktiv</Badge>;
}
