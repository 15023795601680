export const SET_CONFIRMED_CUSTOMERS = 'customer/setConfirmedCustomers';
export const SET_UNCONFIRMED_CUSTOMERS = 'customer/setUnconfirmedCustomers';
export const SET_DELETED_CUSTOMERS = 'customer/setDeletedCustomers';
export const SET_MODERATED_CUSTOMERS = 'customer/setModeratedCustomers';

export const LOAD_CONFIRMED_CUSTOMERS = 'customer/loadConfirmedCustomers';
export const LOAD_UNCONFIRMED_CUSTOMERS = 'customer/loadUnconfirmedCustomers';
export const LOAD_DELETED_CUSTOMERS = 'customer/loadDeletedCustomers';
export const LOAD_MODERATED_CUSTOMERS = 'customer/loadModeratedCustomers';

export const LOAD_CONFIRMED_CUSTOMERS_FAILED =
  'customer/loadConfirmedCustomersFailed';
export const LOAD_UNCONFIRMED_CUSTOMERS_FAILED =
  'customer/loadUnconfirmedCustomersFailed';
export const LOAD_DELETED_CUSTOMERS_FAILED =
  'customer/loadDeletedCustomersFailed';
export const LOAD_MODERATED_CUSTOMERS_FAILED =
  'customer/loadModeratedCustomersFailed';
