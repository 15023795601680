import axios, { CancelToken } from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IAsaStatus, IMassMessageStatus } from '../models/tools';

export async function getLastAsaStatus(
  token: string,
  cancelToken?: CancelToken,
): Promise<IAsaStatus[]> {
  return (
    await axios.get<IAsaStatus[]>(
      `${environment.API_URL}/tools/asa/status`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getLastMassMessages(
  token: string,
  cancelToken?: CancelToken,
): Promise<IMassMessageStatus[]> {
  return (
    await axios.get<IMassMessageStatus[]>(
      `${environment.API_URL}/tools/mass-message/status`,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;
}

export async function getMassMessageReceiver(
  senderUserId: number,
  withProfilePicture: boolean | undefined,
  gay: boolean,
  lastOnlineSinceDays: string | undefined,
  gender: string | undefined,
  country: string | undefined,
  domain: string | undefined,
  onlyBuyUsers: boolean | undefined,
  hasMessageSent: boolean | undefined,
  lastMessageSentDays: number | undefined,
  token: string,
): Promise<number> {
  return (
    await axios.get(
      `${environment.API_URL}/tools/mass-message/receiver`,
      getAxiosOptions(token, {
        params: {
          senderUserId,
          withProfilePicture,
          gay,
          domain,
          lastOnlineSinceDays,
          gender,
          country,
          onlyBuyUsers,
          hasMessageSent,
          lastMessageSentDays,
        },
      }),
    )
  ).data;
}

export async function cancelMassMessage(
  jobId: number,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/tools/mass-message/cancel/${jobId}`,
    null,
    getAxiosOptions(token),
  );
}

export async function cancelAsa(id: number, token: string): Promise<void> {
  await axios.post(
    `${environment.API_URL}/tools/asa/cancel/${id}`,
    null,
    getAxiosOptions(token),
  );
}

export async function addAsaDialogs(token: string): Promise<void> {
  await axios.post(
    `${environment.API_URL}/tools/asa/add`,
    {},
    getAxiosOptions(token),
  );
}

export async function sendMassMessage(
  senderUserId: number,
  withProfilePicture: boolean | undefined,
  gay: boolean,
  lastOnlineSinceDays: string | undefined,
  gender: string | undefined,
  country: string | undefined,
  message: string,
  domain: string | undefined,
  onlyBuyUsers: boolean | undefined,
  hasMessageSent: boolean | undefined,
  lastMessageSentDays: number | undefined,
  image: File | undefined,
  voiceMessage: File | undefined,
  token: string,
): Promise<void> {
  const formData = new FormData();

  formData.append('senderUserId', senderUserId.toString());
  formData.append('gay', gay.toString());

  if (withProfilePicture) {
    formData.append('withProfilePicture', withProfilePicture.toString());
  }
  if (lastOnlineSinceDays) {
    formData.append('lastOnlineSinceDays', lastOnlineSinceDays.toString());
  }

  if (gender) {
    formData.append('gender', gender.toString());
  }

  if (country) {
    formData.append('country', country.toString());
  }

  if (message) {
    formData.append('message', message.toString());
  }

  if (domain) {
    formData.append('domain', domain.toString());
  }

  if (image) {
    formData.append('image', image);
  }

  if (voiceMessage) {
    formData.append('voiceMessage', voiceMessage);
  }

  if (onlyBuyUsers) {
    formData.append('onlyBuyUsers', onlyBuyUsers.toString());
  }

  if (hasMessageSent) {
    formData.append('hasMessageSent', hasMessageSent.toString());
  }

  if (lastMessageSentDays) {
    formData.append('lastMessageSentDays', lastMessageSentDays.toString());
  }

  await axios.post(
    `${environment.API_URL}/tools/mass-message/send`,
    formData,
    getAxiosOptions(token),
  );
}
