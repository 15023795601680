import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import LoadingOverlay from 'react-loading-overlay';

import { loadRevenue } from '../../store/overview/statistic/actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import RefreshButton from '../Common/RefreshButton';

export default function RevenueGraph() {
  const dispatch = useAppDispatch();
  const revenue = useAppSelector((state) => state.Statistic.revenue);
  const isLoading = useAppSelector((state) => state.Statistic.loadingRevenue);

  const chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grace: '25%',
        grid: {
          lineWidth: 1,
          borderColor: 'rgba(0,0,0,0.5)',
        },
        ticks: {
          callback: (value: any, index: any, values: any) =>
            value.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Einnahmen der letzten 3 Monate',
        display: true,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            const _label = context.dataset.label;

            return context.parsed.y.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            });
          },
        },
      },
    },
  };
  const [chartSeries, setChartSeries] = useState<any>();

  useEffect(() => {
    dispatch(loadRevenue());
  }, []);

  useEffect(() => {
    const seriesData: any = {
      labels: [],
      datasets: [
        {
          capBezierPoints: true,
          label: 'Einnahmen',
          data: [],
          fill: true,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgba(255, 99, 132, 0.7)',
          pointRadius: 4,
          pointBackgroundColor: 'rgba(255, 99, 132, 1)',
          pointBorderWidth: 1,
          pointHitRadius: 20,
          borderWidth: 2,
          tension: 0.4,
          showLine: true,
        },
      ],
    };

    for (const _revenue of revenue) {
      seriesData.datasets[0].data.push(_revenue.Total);
      seriesData.labels?.push(
        moment(`${_revenue.Year}-${_revenue.Month}-01`).format('MMM YY'),
      );
    }

    setChartSeries(seriesData);
  }, [revenue]);

  return (
    <Card>
      <LoadingOverlay spinner active={isLoading}>
        <CardHeader className={'d-flex align-items-center'}>
          <div className={'mr-auto'}>
            <h4>Umsatz Verlauf</h4>
          </div>
          <div className={'ml-auto'}>
            <RefreshButton refreshAction={() => dispatch(loadRevenue())} />
          </div>
        </CardHeader>
        <CardBody>
          <Line data={chartSeries} options={chartOptions} height={300} />
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
}
