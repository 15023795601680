import React from 'react';
import { Button, Spinner } from 'reactstrap';

interface ILoadingButtonOptions {
  isLoading: boolean;
  caption: string;
  isSubmit: boolean;
  className?: string;
  color?: string;
  disabled?: boolean;
  onClick?: () => Promise<void>;
}

export default function LoadingButton(opt: ILoadingButtonOptions) {
  if (opt.isLoading) {
    return (
      <Button
        color={opt.color ?? 'primary'}
        disabled={opt.isLoading || opt.disabled}
        type={opt.isSubmit ? 'submit' : 'button'}
        className={opt.className}
      >
        <Spinner size={'sm'} />
        <span className={'ml-2'}>{opt.caption}</span>
      </Button>
    );
  }
  return (
    <Button
      color={opt.color ?? 'primary'}
      disabled={opt.isLoading || opt.disabled}
      type={opt.isSubmit ? 'submit' : 'button'}
      className={opt.className}
      onClick={async () => {
        if (opt.onClick) {
          await opt.onClick();
        }
      }}
    >
      {opt.caption}
    </Button>
  );
}
