import { IBaseAction } from '../type';
import { IPagedRequest, IPagedResult } from '../../models';
import { IPaymentMethod } from '../../models/payment-methods';

import {
  LOAD_PAYMENT_METHODS,
  LOAD_PAYMENT_METHODS_FAILED,
  SET_PAYMENT_METHODS,
} from './actionTypes';

export function setPaymentMethods(
  paymentMethods: IPagedResult<IPaymentMethod>,
): IBaseAction<IPagedResult<IPaymentMethod>> {
  return {
    type: SET_PAYMENT_METHODS,
    payload: paymentMethods,
  };
}

export function loadPaymentMethods(
  request: IPagedRequest,
): IBaseAction<IPagedRequest> {
  return {
    type: LOAD_PAYMENT_METHODS,
    payload: request,
  };
}

export function loadPaymentMethodsFailed(): IBaseAction<any> {
  return {
    type: LOAD_PAYMENT_METHODS_FAILED,
    payload: null,
  };
}
