import { IBaseAction } from '../../type';

import {
  CHECK_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_UNSUCCESS,
  LOGOUT,
} from './actionTypes';

export interface ICheckLogin {
  username: string;
  password: string;
}

export const checkLogin = (data: ICheckLogin): IBaseAction<ICheckLogin> => ({
  type: CHECK_LOGIN,
  payload: data,
});

export const loginSuccess = (username: string, token: string) => ({
  type: LOGIN_SUCCESS,
  payload: { username, token },
});

export const loginUnsuccess = () => ({
  type: LOGIN_UNSUCCESS,
  payload: null,
});

export const logout = () => ({
  type: LOGOUT,
  payload: null,
});
