import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

// users
import avatar from '../../../assets/images/users/profile_small.jpg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { logout } from '../../../store/auth/login/actions';

export function ProfileMenu() {
  const [menu, setMenu] = useState(false);
  const username = useAppSelector((state) => state.Login.username);

  const dispatch = useAppDispatch();

  const toggle = function () {
    setMenu(!menu);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user mr-1"
            src={avatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-1 text-transform">
            {username}
          </span>
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem href="#">
            <i className="ri-user-line align-middle mr-1" /> Profil
          </DropdownItem>
          <DropdownItem
            className="text-danger"
            href="/logout"
            onClick={() => {
              dispatch(logout());
            }}
          >
            <i className="ri-shut-down-line align-middle mr-1 text-danger" />
            Abmelden
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default ProfileMenu;
