import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IPagedResult, IPagedSearchRequest } from '../../models';
import { IPresent } from '../../models/Present';
import { getPresents } from '../../services/present.service';

import { loadPresentsFailed, setPresents } from './actions';
import { LOAD_PRESENTS } from './actionTypes';

function* loadPresents(action: IBaseAction<IPagedSearchRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<IPresent> = yield call(
      getPresents,
      action.payload.page,
      action.payload.size,
      action.payload.searchTerm,
      token,
    );

    yield put(setPresents(response));
  } catch (error) {
    yield put(loadPresentsFailed());
  }
}

export function* watchLoadPresents() {
  yield takeEvery<IBaseAction<any>>(LOAD_PRESENTS, loadPresents);
}

function* presentSaga() {
  yield all([fork(watchLoadPresents)]);
}

export default presentSaga;
