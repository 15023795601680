import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ICustomerInterest } from '../../models/customer';
import {
  getCustomerInterest,
  updateUserInterest,
} from '../../services/customer.service';
import { useAppSelector } from '../../store/hooks';
import LoadingButton from '../../components/Common/LoadingButton';

type CustomerEditInterestParams = {
  userId?: string;
};

export default function CustomerEditInterest() {
  const { userId: userIdStr } = useParams<CustomerEditInterestParams>();

  const token = useAppSelector((state) => state.Login.token);
  const history = useHistory();

  const [interests, setInterests] = useState<ICustomerInterest | null>(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadInterests = async (userId: number) => {
      const interest = await getCustomerInterest(
        userId,
        token!,
        cancelToken.token,
      );

      setInterests(interest);
    };

    if (userIdStr) {
      loadInterests(parseInt(userIdStr));
    }

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [userIdStr]);

  const [breadcrumbItems] = useState([
    { title: 'Kunde', link: '#' },
    { title: 'Interessen', link: '#' },
  ]);

  useEffect(() => {
    if (!interests || !formik) return;

    formik.setValues({
      LookForGender: !interests?.LookForGender
        ? []
        : interests.LookForGender.split(','),
      LookForType: !interests?.LookForType
        ? []
        : interests.LookForType.split(','),
      Hobbies: !interests?.Hobbies ? [] : interests.Hobbies.split(','),
    });
  }, [interests]);

  const formik = useFormik({
    initialValues: {
      LookForGender: !interests?.LookForGender
        ? []
        : interests.LookForGender.split(','),
      LookForType: !interests?.LookForType
        ? []
        : interests.LookForType.split(','),
      Hobbies: !interests?.Hobbies ? [] : interests.Hobbies.split(','),
    },
    onSubmit: async (values) => {
      if (!userIdStr) return;

      const userId = parseInt(userIdStr);

      try {
        await updateUserInterest(
          userId,
          values.LookForGender,
          values.LookForType,
          values.Hobbies,
          token!,
        );

        toast.success('Die Interessen wurden erfolgreich aktualisiert.');
        history.push(`/customers/${userId}`);
      } catch (err) {
        console.error(err);
        toast.error('Beim Speichern ist ein Fehler aufgetreten.');
      }
    },
  });

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Öffentliche Informationen"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <div className={'d-flex flex-wrap flex-fill'}>
                        <div
                          className={'d-flex flex-column flex-wrap flex-fill'}
                        >
                          <div>
                            <label>Ich suche nach</label>
                          </div>
                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForGender'}
                              className={'form-check-input'}
                              value={'Mann'}
                            />
                            <label className={'form-check-label'}>Mann</label>
                          </div>
                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForGender'}
                              className={'form-check-input'}
                              value={'Frau'}
                            />
                            <label className={'form-check-label'}>Frau</label>
                          </div>
                        </div>

                        <div
                          className={'d-flex flex-column flex-wrap flex-fill'}
                        >
                          <div>
                            <label>Ich bin aufgeschlossen für</label>
                          </div>
                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForType'}
                              className={'form-check-input'}
                              value={'Affäre'}
                            />
                            <label className={'form-check-label'}>Affäre</label>
                          </div>
                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForType'}
                              className={'form-check-input'}
                              value={'Ehe'}
                            />
                            <label className={'form-check-label'}>Ehe</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForType'}
                              className={'form-check-input'}
                              value={'Flirt'}
                            />
                            <label className={'form-check-label'}>Flirt</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForType'}
                              className={'form-check-input'}
                              value={'Freundschaft'}
                            />
                            <label className={'form-check-label'}>
                              Freundschaft
                            </label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForType'}
                              className={'form-check-input'}
                              value={'für alles offen'}
                            />
                            <label className={'form-check-label'}>
                              für alles offen
                            </label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'LookForType'}
                              className={'form-check-input'}
                              value={'Partnerschaft'}
                            />
                            <label className={'form-check-label'}>
                              Partnerschaft
                            </label>
                          </div>
                        </div>

                        <div
                          className={'d-flex flex-column flex-wrap flex-fill'}
                        >
                          <div>
                            <label>Hobbies & Freizeit</label>
                          </div>
                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Chatten'}
                            />
                            <label className={'form-check-label'}>
                              Chatten
                            </label>
                          </div>
                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Familie'}
                            />
                            <label className={'form-check-label'}>
                              Familie
                            </label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Filme'}
                            />
                            <label className={'form-check-label'}>Filme</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Freunde'}
                            />
                            <label className={'form-check-label'}>
                              Freunde
                            </label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Karriere'}
                            />
                            <label className={'form-check-label'}>
                              Karriere
                            </label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Kino'}
                            />
                            <label className={'form-check-label'}>Kino</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Kochen'}
                            />
                            <label className={'form-check-label'}>Kochen</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Kultur'}
                            />
                            <label className={'form-check-label'}>Kultur</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Kunst'}
                            />
                            <label className={'form-check-label'}>Kunst</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Musik'}
                            />
                            <label className={'form-check-label'}>Musik</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Reisen'}
                            />
                            <label className={'form-check-label'}>Reisen</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Spazieren'}
                            />
                            <label className={'form-check-label'}>
                              Spazieren
                            </label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Sport'}
                            />
                            <label className={'form-check-label'}>Sport</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Tanzen'}
                            />
                            <label className={'form-check-label'}>Tanzen</label>
                          </div>

                          <div className={'form-check'}>
                            <Field
                              type={'checkbox'}
                              name={'Hobbies'}
                              className={'form-check-input'}
                              value={'Unterhaltungen'}
                            />
                            <label className={'form-check-label'}>
                              Unterhaltungen
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className={'d-flex flex-wrap'}>
                        <div className={'p-2'}>
                          <LoadingButton
                            isLoading={formik.isSubmitting}
                            caption={'Speichern'}
                            isSubmit={true}
                            disabled={formik.isSubmitting || !formik.isValid}
                          />
                        </div>
                        <div className={'p-2'}>
                          <Button
                            color={'secondary'}
                            onClick={() => {
                              history.push(`/customers/${userIdStr}`);
                            }}
                          >
                            Abbrechen
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
