import React, { useState } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import LoadingButton from '../../../components/Common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createCoinPackage } from '../../../services/coin-package.service';
import { loadCoinPackages } from '../../../store/coin-packages/actions';

interface IAddCoinPackage {
  name: string;
  description: string | undefined;
  amount: number;
  price: number;
  oneTimePackage: boolean;
  bgColor: string;
  orderNr: number;
  popular: boolean;
}

export default function CoinPackagesTableToolbar() {
  const token = useAppSelector((state) => state.Login.token);

  const dispatch = useAppDispatch();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const initialValues: IAddCoinPackage = {
    name: '',
    description: '',
    amount: 0,
    price: 0,
    oneTimePackage: false,
    bgColor: '',
    orderNr: 0,
    popular: false,
  };

  const [addCoinPackageImage, setAddCoinPackageImage] = useState<File>();

  const formik = useFormik({
    initialValues,
    onReset: (values) => {
      values.name = initialValues.name;
      values.description = initialValues.description;
      values.amount = initialValues.amount;
      values.price = initialValues.price;
      values.oneTimePackage = initialValues.oneTimePackage;
      values.bgColor = initialValues.bgColor;
      values.orderNr = initialValues.orderNr;
      values.popular = initialValues.popular;
    },
    onSubmit: async (values) => {
      if (!addCoinPackageImage) return;

      await createCoinPackage(
        values.name,
        values.description,
        values.amount,
        values.price,
        values.oneTimePackage,
        values.bgColor,
        addCoinPackageImage,
        values.orderNr,
        values.popular,
        token!,
      );

      toast.success('Das Paket wurde erfolgreich hinzugefügt.');
      dispatch(
        loadCoinPackages({
          size: 10,
          page: 0,
        }),
      );

      setIsAddModalOpen(false);
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name || values.name === '') {
        errors.name = 'Bitte geben Sie einen Namen ein';
      }

      if (!values.amount || values.amount <= 0) {
        errors.amount = 'Bitte geben Sie eine gültige Anzahl ein';
      }

      if (!values.price || values.price <= 0) {
        errors.price = 'Bitte geben Sie einen gültigen Preis ein';
      }

      if (!values.bgColor || values.bgColor === '') {
        errors.bgColor = 'Bitte geben Sie einen gültigen Farbcode ein';
      }

      if (!values.orderNr) {
        errors.orderNr = 'Bitte geben Sie eine Reihenfolge ein';
      }

      return errors;
    },
  });

  return (
    <>
      <Modal
        isOpen={isAddModalOpen}
        centered={true}
        toggle={() =>
          !isSubmittingForm
            ? setIsAddModalOpen(!isAddModalOpen)
            : setIsAddModalOpen(isAddModalOpen)
        }
      >
        <ModalHeader>Paket hinzufügen</ModalHeader>
        <ModalBody>
          <FormikProvider value={formik}>
            <Form className={'form-horizontal'} onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label>Name</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.name && formik.touched.name
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'name'}
                  name={'name'}
                  placeholder={'Name eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className={'invalid-feedback'}>{formik.errors.name}</div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Beschreibung</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.description && formik.touched.description
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'description'}
                  name={'description'}
                  placeholder={'Beschreibung eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.description}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Bild</Label>
                <input
                  type={'file'}
                  className={'form-control'}
                  id={'file'}
                  name={'file'}
                  placeholder={'Bild auswählen'}
                  accept={'image/*'}
                  onChange={(ev) => {
                    if (
                      ev.currentTarget &&
                      ev.currentTarget.files &&
                      ev.currentTarget.files.length > 0
                    ) {
                      setAddCoinPackageImage(ev.currentTarget.files[0]);
                    } else {
                      setAddCoinPackageImage(undefined);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>

              <FormGroup>
                <Label>FlirtCoin Anzahl</Label>
                <input
                  type={'number'}
                  className={`form-control${
                    formik.errors.amount && formik.touched.amount
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'amount'}
                  name={'amount'}
                  placeholder={'Anzahl eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.amount}
                />
                {formik.errors.amount && formik.touched.amount ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.amount}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Preis</Label>
                <input
                  type={'number'}
                  className={`form-control${
                    formik.errors.price && formik.touched.price
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'price'}
                  name={'price'}
                  placeholder={'Preis eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.price}
                />
                {formik.errors.price && formik.touched.price ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.price}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Hintergrundfarbe</Label>
                <input
                  type={'text'}
                  className={`form-control${
                    formik.errors.bgColor && formik.touched.bgColor
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'bgColor'}
                  name={'bgColor'}
                  placeholder={'Hintergrundfarbe eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bgColor}
                />
                {formik.errors.bgColor && formik.touched.bgColor ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.bgColor}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Reihenfolge</Label>
                <input
                  type={'number'}
                  className={`form-control${
                    formik.errors.orderNr && formik.touched.orderNr
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'orderNr'}
                  name={'orderNr'}
                  placeholder={'Reihenfolge eingeben'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.orderNr}
                />
                {formik.errors.orderNr && formik.touched.orderNr ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.orderNr}
                  </div>
                ) : (
                  <></>
                )}
              </FormGroup>

              <FormGroup className={'form-check'}>
                <input
                  type={'checkbox'}
                  className={`form-check-input${
                    formik.errors.oneTimePackage &&
                    formik.touched.oneTimePackage
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'oneTimePackage'}
                  name={'oneTimePackage'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.oneTimePackage &&
                formik.touched.oneTimePackage ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.oneTimePackage}
                  </div>
                ) : (
                  <></>
                )}
                <Label className={'form-check-label'}>Einmal-Paket</Label>
              </FormGroup>

              <FormGroup className={'form-check'}>
                <input
                  type={'checkbox'}
                  className={`form-check-input${
                    formik.errors.popular && formik.touched.popular
                      ? ' is-invalid'
                      : ''
                  }`}
                  id={'popular'}
                  name={'popular'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.popular && formik.touched.popular ? (
                  <div className={'invalid-feedback'}>
                    {formik.errors.popular}
                  </div>
                ) : (
                  <></>
                )}
                <Label className={'form-check-label'}>Hervorheben</Label>
              </FormGroup>

              <div className={'d-flex'}>
                <div className={'p-2'}>
                  <LoadingButton
                    isLoading={formik.isSubmitting}
                    caption={'Hinzufügen'}
                    disabled={!formik.isValid}
                    isSubmit={true}
                  />
                </div>
                <div className={'p-2'}>
                  <Button
                    color={'danger'}
                    onClick={() => setIsAddModalOpen(false)}
                  >
                    Abbrechen
                  </Button>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
      <GridToolbarContainer>
        <div className={'p-2'}>
          <Button color={'primary'} onClick={() => setIsAddModalOpen(true)}>
            Neues Paket
          </Button>
        </div>
      </GridToolbarContainer>
    </>
  );
}
