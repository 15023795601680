import React, { Component } from 'react';
import { Col, Collapse, Container, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classname from 'classnames';

// i18n
import { connect } from 'react-redux';

import { openConfirmationUrlGenerator } from '../../store/tools/actions';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({});
    }
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById('navigation');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        console.log('Match for', items[i].pathname);
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <Container fluid>
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      <i className="ri-dashboard-line mr-2"></i> {'Dashboard'}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="topnav-uielement"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-pencil-ruler-2-line mr-2"></i> Animation{' '}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(
                        'dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl',
                        { show: this.state.uiState },
                      )}
                      aria-labelledby="topnav-uielement"
                    >
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Link
                              to="/animation/dialog"
                              className="dropdown-item"
                            >
                              Dialogübersicht
                            </Link>
                            <Link
                              to="/animation/live"
                              className="dropdown-item"
                            >
                              Live Ansicht
                            </Link>
                            <Link
                              to="/animation/messages"
                              className="dropdown-item"
                            >
                              Nachrichten
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="topnav-uielement"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-group-2-line mr-2"></i> {'Moderation'}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(
                        'dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl',
                        { show: this.state.uiState },
                      )}
                      aria-labelledby="topnav-uielement"
                    >
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Link to="/agency" className="dropdown-item">
                              Agenturen
                            </Link>
                            <Link to="/moderators" className="dropdown-item">
                              Moderatoren
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="topnav-uielement"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-profile-line mr-2"></i> {'Benutzer'}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(
                        'dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl',
                        { show: this.state.uiState },
                      )}
                      aria-labelledby="topnav-uielement"
                    >
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Link to="/customers" className="dropdown-item">
                              Kunden
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/presents">
                      <i className="ri-gift-fill mr-2"></i> {'Geschenke'}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/coin-packages">
                      <i className="ri-red-packet-line mr-2"></i>{' '}
                      {'Coin Pakete'}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="tools-menu-element"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-customer-service-2-line mr-2"></i>{' '}
                      Support <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(
                        'dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl',
                        { show: this.state.uiState },
                      )}
                      aria-labelledby="tools-menu-element"
                    >
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Link
                              to="/support/tickets"
                              className="dropdown-item"
                            >
                              Tickets
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="tools-menu-element"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-tools-line mr-2"></i> Tools{' '}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(
                        'dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl',
                        { show: this.state.uiState },
                      )}
                      aria-labelledby="tools-menu-element"
                    >
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Link
                              to="/tools/ip-checker"
                              className="dropdown-item"
                            >
                              IP-Checker
                            </Link>
                            <Link
                              to="/tools/mass-message"
                              className="dropdown-item"
                            >
                              Massen-Nachricht
                            </Link>
                            <Link to="/tools/asa" className="dropdown-item">
                              ASA-Dialoge
                            </Link>

                            <Link
                              to="/tools/pending-voice-descriptions"
                              className="dropdown-item"
                            >
                              Sprachnotizen Freigabe
                            </Link>

                            <Link
                              to="/tools/pending-profile-pictures"
                              className="dropdown-item"
                            >
                              Profilbild Freigabe
                            </Link>
                            <a
                              href={'#'}
                              onClick={() =>
                                this.props.openConfirmationUrlGenerator()
                              }
                              className="dropdown-item"
                            >
                              Bestätigungs URL Generator
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ uiState: !this.state.uiState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="settings-menu-element"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-settings-5-line mr-2"></i> Einstellungen{' '}
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname(
                        'dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu-xl',
                        { show: this.state.uiState },
                      )}
                      aria-labelledby="settings-menu-element"
                    >
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Link
                              to="/payment-methods"
                              className="dropdown-item"
                            >
                              Zahlungsmethoden
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { leftSideBarType, leftSideBarTheme } = state.Layout;
  return { leftSideBarType, leftSideBarTheme };
};

export default withRouter(
  connect(mapStatetoProps, (dispatch) => ({
    openConfirmationUrlGenerator: () =>
      dispatch(openConfirmationUrlGenerator()),
  }))(Navbar),
);
