import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ICustomerPublicInformation } from '../../models/customer';
import {
  getCustomerPublicInformation,
  updateUserPublicInformation,
} from '../../services/customer.service';
import { useAppSelector } from '../../store/hooks';
import LoadingButton from '../../components/Common/LoadingButton';

type CustomerEditPublicInformationParams = {
  userId?: string;
};

export default function CustomerEditPublicInformation() {
  const { userId: userIdStr } =
    useParams<CustomerEditPublicInformationParams>();

  const token = useAppSelector((state) => state.Login.token);
  const history = useHistory();

  const [publicInformation, setPublicInformation] =
    useState<ICustomerPublicInformation | null>(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadPublicInformation = async (userId: number) => {
      const publicInfo = await getCustomerPublicInformation(
        userId,
        token!,
        cancelToken.token,
      );

      setPublicInformation(publicInfo);
    };

    if (userIdStr) {
      loadPublicInformation(parseInt(userIdStr));
    }

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [userIdStr]);

  const [breadcrumbItems] = useState([
    { title: 'Kunde', link: '#' },
    { title: 'Öffentliche Informationen', link: '#' },
  ]);

  useEffect(() => {
    if (!publicInformation || !formik) return;

    formik.setValues({
      PlzArea: publicInformation?.PlzArea ?? '',
      LivingStatus: publicInformation?.LivingStatus ?? '',
      RelationshipStatus: publicInformation?.RelationshipStatus ?? '',
      LastRelationShip: publicInformation?.LastRelationShip ?? '',
      StarSign: publicInformation?.StarSign ?? '',
      Smoker: publicInformation?.Smoker ?? '',
      Education: publicInformation?.Education ?? '',
      Job: publicInformation?.Job ?? '',
      Size: publicInformation?.Size ?? '',
      Weight: publicInformation?.Weight ?? '',
      EyeColor: publicInformation?.EyeColor ?? '',
      HairColor: publicInformation?.HairColor ?? '',
      Income: publicInformation?.Income ?? '',
      Religion: publicInformation?.Religion ?? '',
      AboutMe: publicInformation?.AboutMe ?? '',
    });
  }, [publicInformation]);

  const formik = useFormik({
    initialValues: {
      PlzArea: publicInformation?.PlzArea ?? '',
      LivingStatus: publicInformation?.LivingStatus ?? '',
      RelationshipStatus: publicInformation?.RelationshipStatus ?? '',
      LastRelationShip: publicInformation?.LastRelationShip ?? '',
      StarSign: publicInformation?.StarSign ?? '',
      Smoker: publicInformation?.Smoker ?? '',
      Education: publicInformation?.Education ?? '',
      Job: publicInformation?.Job ?? '',
      Size: publicInformation?.Size ?? '',
      Weight: publicInformation?.Weight ?? '',
      EyeColor: publicInformation?.EyeColor ?? '',
      HairColor: publicInformation?.HairColor ?? '',
      Income: publicInformation?.Income ?? '',
      Religion: publicInformation?.Religion ?? '',
      AboutMe: publicInformation?.AboutMe ?? '',
    },
    onSubmit: async (values) => {
      if (!userIdStr) return;

      const userId = parseInt(userIdStr);

      try {
        await updateUserPublicInformation(
          userId,
          values as ICustomerPublicInformation,
          token!,
        );

        toast.success(
          'Die Öffentlichen Informationen wurden erfolgreich aktualisiert.',
        );
        history.push(`/customers/${userId}`);
      } catch (err) {
        console.error(err);
        toast.error('Beim Speichern ist ein Fehler aufgetreten.');
      }
    },
  });

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Öffentliche Informationen"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <div className={'d-flex flex-wrap flex-fill'}>
                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Ich lebe</label>
                          <Field
                            as={'select'}
                            name={'LivingStatus'}
                            className={`form-control${
                              formik.errors.LivingStatus &&
                              formik.touched.LivingStatus
                                ? ' is-invalid'
                                : ''
                            }`}
                          >
                            <option value="">Bitte auswählen ...</option>
                            <option value={'alleine'}>alleine</option>
                            <option value={'bei meinen Eltern'}>
                              bei meinen Eltern
                            </option>
                            <option value={'Wohngemeinschaft'}>
                              Wohngemeinschaft
                            </option>
                            <option value={'Studenten WG'}>Studenten WG</option>
                            <option value={'mit Partner'}>mit Partner</option>
                            <option value={'anders'}>anders</option>
                          </Field>
                          <ErrorMessage
                            name={'LivingStatus'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Beziehungsstatus</label>
                          <Field
                            as={'select'}
                            name={'RelationshipStatus'}
                            className={`form-control${
                              formik.errors.RelationshipStatus &&
                              formik.touched.RelationshipStatus
                                ? ' is-invalid'
                                : ''
                            }`}
                          >
                            <option value="">Bitte auswählen ...</option>
                            <option value="Allein Erziehend">
                              Allein Erziehend
                            </option>
                            <option value="In einer offenen Beziehung">
                              In einer offenen Beziehung
                            </option>
                            <option value="Kompliziert">Kompliziert</option>
                            <option value="Single">Single</option>
                            <option value="Verheiratet">Verheiratet</option>
                            <option value="Geschieden">Geschieden</option>
                            <option value="Verliebt">Verliebt</option>
                            <option value="Verwitwet">Verwitwet</option>
                            <option value="bleibt mein Geheimnis">
                              bleibt mein Geheimnis
                            </option>
                          </Field>
                          <ErrorMessage
                            name={'RelationshipStatus'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Letzte Beziehung</label>
                          <Field
                            as={'select'}
                            name={'LastRelationShip'}
                            className={`form-control${
                              formik.errors.LastRelationShip &&
                              formik.touched.LastRelationShip
                                ? ' is-invalid'
                                : ''
                            }`}
                          >
                            <option value="">Bitte auswählen ...</option>
                            <option value="kürzlich erst Beendet">
                              kürzlich erst Beendet
                            </option>
                            <option value="schon etwas länger her">
                              schon etwas länger her
                            </option>
                            <option value="recht lange her">
                              recht lange her
                            </option>
                            <option value="noch nie richtig vergeben">
                              noch nie richtig vergeben
                            </option>
                            <option value="schon immer solo">
                              schon immer solo
                            </option>
                            <option value="Jungfrau in jeglicher hinsicht">
                              Jungfrau in jeglicher hinsicht
                            </option>
                            <option value="bleibt mein Geheimnis">
                              bleibt mein Geheimnis
                            </option>
                          </Field>
                          <ErrorMessage
                            name={'LastRelationShip'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>
                      </div>

                      <div className={'d-flex flex-wrap flex-fill'}>
                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Sternzeichen</label>
                          <Field
                            type={'text'}
                            name={'StarSign'}
                            className={`form-control${
                              formik.errors.StarSign && formik.touched.StarSign
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'StarSign'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Raucher</label>
                          <Field
                            as={'select'}
                            name={'Smoker'}
                            className={`form-control${
                              formik.errors.Smoker && formik.touched.Smoker
                                ? ' is-invalid'
                                : ''
                            }`}
                          >
                            <option value="">Bitte auswählen ...</option>
                            <option value="Ja">Ja</option>
                            <option value="Nein">Nein</option>
                            <option value="Gelegentlich">Gelegentlich</option>
                          </Field>
                          <ErrorMessage
                            name={'Smoker'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Ausbildung</label>
                          <Field
                            type={'text'}
                            name={'Education'}
                            className={`form-control${
                              formik.errors.Education &&
                              formik.touched.Education
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'Education'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>
                      </div>

                      <div className={'d-flex flex-wrap flex-fill'}>
                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Job</label>
                          <Field
                            type={'text'}
                            name={'Job'}
                            className={`form-control${
                              formik.errors.Job && formik.touched.Job
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'Job'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Größe</label>
                          <Field
                            type={'text'}
                            name={'Size'}
                            className={`form-control${
                              formik.errors.Size && formik.touched.Size
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'Size'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Gewicht</label>
                          <Field
                            type={'text'}
                            name={'Weight'}
                            className={`form-control${
                              formik.errors.Weight && formik.touched.Weight
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'Weight'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>
                      </div>

                      <div className={'d-flex flex-wrap flex-fill'}>
                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Augenfarbe</label>
                          <Field
                            type={'text'}
                            name={'EyeColor'}
                            className={`form-control${
                              formik.errors.EyeColor && formik.touched.EyeColor
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'EyeColor'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Haarfarbe</label>
                          <Field
                            type={'text'}
                            name={'HairColor'}
                            className={`form-control${
                              formik.errors.HairColor &&
                              formik.touched.HairColor
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'HairColor'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>

                        <FormGroup className={'flex-grow-1 p-2'}>
                          <label>Einkommen</label>
                          <Field
                            type={'text'}
                            name={'Income'}
                            className={`form-control${
                              formik.errors.Income && formik.touched.Income
                                ? ' is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name={'Income'}
                            className={'invalid-feedback'}
                            component={'div'}
                          />
                        </FormGroup>
                      </div>

                      <FormGroup className={'flex-grow-1 p-2'}>
                        <label>Religion</label>
                        <Field
                          type={'text'}
                          name={'Religion'}
                          className={`form-control${
                            formik.errors.Religion && formik.touched.Religion
                              ? ' is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          name={'Religion'}
                          className={'invalid-feedback'}
                          component={'div'}
                        />
                      </FormGroup>

                      <FormGroup className={'flex-grow-1 p-2'}>
                        <label>Über mich</label>
                        <Field
                          as={'textarea'}
                          name={'AboutMe'}
                          rows={5}
                          className={`form-control${
                            formik.errors.AboutMe && formik.touched.AboutMe
                              ? ' is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          name={'AboutMe'}
                          className={'invalid-feedback'}
                          component={'div'}
                        />
                      </FormGroup>

                      <div className={'d-flex flex-wrap'}>
                        <div className={'p-2'}>
                          <LoadingButton
                            isLoading={formik.isSubmitting}
                            caption={'Speichern'}
                            isSubmit={true}
                            disabled={formik.isSubmitting || !formik.isValid}
                          />
                        </div>
                        <div className={'p-2'}>
                          <Button
                            color={'secondary'}
                            onClick={() => {
                              history.push(`/customers/${userIdStr}`);
                            }}
                          >
                            Abbrechen
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
