import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { ICustomer, IOnlineCustomer } from '../../models/customer';
import { getOnlineCustomers } from '../../services/customer.service';
import { getAuthToken } from '../overview/statistic/saga';

import { loadOnlineCustomerFailed, setOnlineCustomer } from './actions';
import { LOAD_ONLINE_CUSTOMERS } from './actionTypes';

function* loadOnlineCustomers(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IOnlineCustomer[] = yield call(getOnlineCustomers, token);

    yield put(setOnlineCustomer(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadOnlineCustomerFailed());
  }
}

export function* watchLoadOnlineCustomers() {
  yield takeEvery<IBaseAction<any>>(LOAD_ONLINE_CUSTOMERS, loadOnlineCustomers);
}

function* onlineCustomerSaga() {
  yield all([fork(watchLoadOnlineCustomers)]);
}

export default onlineCustomerSaga;
