import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';

import { ICustomer } from '../../models/customer';
import { getCustomer } from '../../services/customer.service';
import { useAppSelector } from '../../store/hooks';

import CustomerStatus from './CustomerStatus';

interface ICustomerOptions {
  userId: number;
}

export default function CustomerName(opt: ICustomerOptions) {
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);

  const token = useAppSelector((state) => state.Login.token);
  const [customer, setCustomer] = useState<ICustomer>();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const loadCustomer = async () => {
      try {
        const user = await getCustomer(opt.userId, token!, cancelToken.token);
        setCustomer(user);
      } catch (e) {
      } finally {
        setIsLoadingCustomer(false);
      }
    };

    loadCustomer();

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, []);

  if (isLoadingCustomer) {
    return (
      <>
        <Spinner size={'sm'} />
      </>
    );
  }

  return (
    <div className={'d-flex flex-wrap'}>
      <div>{customer?.Username}</div>
      <div className={'ml-2'}>
        <CustomerStatus customer={customer!} />
      </div>
    </div>
  );
}
