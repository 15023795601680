import React, { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { createPortal } from 'react-dom';
import axios, { CancelTokenSource } from 'axios';

import {
  findCustomers,
  getProfilePictureUrl,
} from '../../services/customer.service';
import LoadingButton from '../Common/LoadingButton';
import { useAppSelector } from '../../store/hooks';
import { ICustomer } from '../../models/customer';

import CustomerName from './CustomerName';

interface ICustomerSelectModalOptions {
  title?: string;
  onlyModerated?: boolean;
  isOpen: boolean;
  toggle: () => void;
  onSelect: (customer: ICustomer) => void;
}

export default function CustomerSelectModal(
  options: ICustomerSelectModalOptions,
) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [customers, setCustomers] = useState<ICustomer[]>([]);

  const initialValues: {
    customer?: ICustomer;
  } = {
    customer: undefined,
  };

  const formik = useFormik({
    initialValues,
    onReset: (values) => {
      values.customer = undefined;
    },
    validateOnMount: true,
    validate: (values) => {
      const err = {} as any;

      if (!values.customer) {
        err.customer = 'Bitte wählen Sie einen Kunden aus';
      }

      return err;
    },
    onSubmit: async (values) => {
      try {
        if (!values.customer) return;

        options.onSelect(values.customer);
        options.toggle();
        formik.resetForm();
      } catch (err) {
        toast.error('Fehler beim Hinzufügen des Geschenks.');
      }
    },
  });

  let cancelToken: CancelTokenSource | undefined;

  const handleSearch = async (query: string) => {
    if (cancelToken) {
      cancelToken.cancel('New search request');
      setCustomers([]);
    }

    cancelToken = axios.CancelToken.source();
    setIsLoadingCustomers(true);

    if (!query) return;

    try {
      let onlyModerated = false;
      if (options.onlyModerated) {
        onlyModerated = options.onlyModerated;
      }

      const customers = await findCustomers(
        query,
        onlyModerated,
        token!,
        cancelToken?.token,
      );

      setCustomers(customers);
    } catch (err) {
      setCustomers([]);
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  useEffect(() => {
    if (!options.isOpen) {
      setCustomers([]);
      setIsLoadingCustomers(false);
      formik.resetForm();
    }
  }, [options.isOpen]);

  return createPortal(
    <Modal
      isOpen={options.isOpen}
      toggle={options.toggle}
      centered={true}
      backdrop={true}
      size={'lg'}
    >
      <ModalHeader toggle={options.toggle}>
        {options.title ?? 'Kunde auswählen'}
      </ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Kunde</Label>
              <AsyncTypeahead
                isLoading={isLoadingCustomers}
                onSearch={handleSearch}
                options={customers}
                multiple={false}
                placeholder={'Nach Kunde suchen ...'}
                filterBy={() => true}
                id={'customer'}
                onChange={(selected) => {
                  if (selected.length <= 0) return;

                  formik.setFieldValue('customer', selected[0]);
                }}
                labelKey={(customer) =>
                  `${customer.Username} (${customer.Email}) - ${
                    customer.Gender === 'female' ? 'weiblich' : 'männlich'
                  }`
                }
                onBlur={() => formik.setTouched({ customer: true })}
                minLength={4}
                isValid={formik.touched.customer && !formik.errors.customer}
                isInvalid={
                  !!(formik.touched.customer && formik.errors.customer)
                }
                className={
                  formik.errors.customer && formik.touched.customer
                    ? 'is-invalid'
                    : ''
                }
                renderMenuItemChildren={(option, props) => (
                  <Fragment>
                    <div className={'d-flex flex-wrap'}>
                      <div>
                        <img
                          src={getProfilePictureUrl(option.UserId)}
                          width={20}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>
                      <div className={'ml-2'}>
                        <CustomerName userId={option.UserId} />
                      </div>
                      <div className={'ml-2'}>({option.Email})</div>
                      <div className={'ml-2'}>
                        {option.Gender === 'female' ? 'weiblich' : 'männlich'}
                      </div>
                    </div>
                  </Fragment>
                )}
              />
              <ErrorMessage
                name={'customer'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <LoadingButton
              isLoading={formik.isSubmitting}
              caption={'Auswählen'}
              isSubmit={true}
              disabled={formik.isSubmitting || !formik.isValid}
            />
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
