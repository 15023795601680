import axios, { CancelToken } from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IPresent } from '../models/Present';
import { ICustomer } from '../models/customer';
import { IPagedResult } from '../models';

export async function getLatestPresents(
  fakeId: number,
  userId: number,
  token: string,
): Promise<IPresent[]> {
  return (
    await axios.get<IPresent[]>(
      `${environment.API_URL}/user/${userId}/${fakeId}/presents`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getTotalPresents(
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/user/${userId}/${fakeId}/total-presents`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPresentPictureUrl(
  presentId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<string> {
  const pictureData = (
    await axios.get(
      `${environment.API_URL}/present/${presentId}/picture`,
      getAxiosOptions(token, {
        responseType: 'blob',
        cancelToken,
      }),
    )
  ).data;

  return URL.createObjectURL(pictureData);
}

export async function addPresent(
  presentId: number,
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/user/${userId}/add-present/${presentId}`,
    null,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function addWishPresent(
  presentId: number,
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/user/${userId}/add-wish-present/${presentId}`,
    null,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function removePresent(
  userPresentId: number,
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  await axios.delete(
    `${environment.API_URL}/user/${userId}/remove-present/${userPresentId}`,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function removeWishPresent(
  userWishPresentId: number,
  userId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  await axios.delete(
    `${environment.API_URL}/user/${userId}/remove-wish-present/${userWishPresentId}`,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function createPresent(
  name: string,
  description: string,
  image: File,
  costs: number,
  isPremium: boolean,
  token: string,
  cancelToken?: CancelToken,
): Promise<IPresent> {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  formData.append('image', image);
  formData.append('isPremium', isPremium ? 'true' : 'false');
  formData.append('costs', costs.toString());

  const present = (
    await axios.post<IPresent>(
      `${environment.API_URL}/present/`,
      formData,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;

  return present;
}

export async function updatePresent(
  presentId: number,
  name: string,
  description: string,
  image: File | null,
  costs: number,
  isPremium: boolean,
  token: string,
  cancelToken?: CancelToken,
): Promise<IPresent> {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  if (image) {
    formData.append('image', image);
  }
  formData.append('isPremium', isPremium ? 'true' : 'false');
  formData.append('costs', costs.toString());

  const present = (
    await axios.put<IPresent>(
      `${environment.API_URL}/present/${presentId}`,
      formData,
      getAxiosOptions(token, {
        cancelToken,
      }),
    )
  ).data;

  return present;
}

export async function deletePresent(
  presentId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  await axios.delete(
    `${environment.API_URL}/present/${presentId}`,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function restorePresent(
  presentId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/present/${presentId}/restore`,
    null,
    getAxiosOptions(token, {
      cancelToken,
    }),
  );
}

export async function getPresent(
  presentId: number,
  token: string,
  cancelToken?: CancelToken,
): Promise<IPresent> {
  return (
    await axios.get<IPresent>(
      `${environment.API_URL}/present/${presentId}`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getPresents(
  page: number,
  size: number,
  name: string | null,
  token: string,
): Promise<IPagedResult<IPresent>> {
  return (
    await axios.get<IPagedResult<IPresent>>(
      `${environment.API_URL}/present`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
          searchTerm: name,
        },
      }),
    )
  ).data;
}
