import { IBaseAction } from '../type';
import { IActiveDialog, IActiveDialogHistory } from '../../models/animation';

import {
  LOAD_ACTIVE_DIALOGS,
  LOAD_ACTIVE_DIALOGS_FAILED,
  LOAD_FINISHED_DIALOGS,
  LOAD_FINISHED_DIALOGS_FAILED,
  LOAD_INS_TODAY,
  LOAD_INS_TODAY_FAILED,
  LOAD_NEW_CUSTOMER_TODAY,
  LOAD_NEW_CUSTOMER_TODAY_FAILED,
  LOAD_OPEN_DIALOGS,
  LOAD_OPEN_DIALOGS_FAILED,
  LOAD_PENDING_DIALOGS,
  LOAD_PENDING_DIALOGS_FAILED,
  LOAD_TOTAL_COINS,
  LOAD_TOTAL_COINS_FAILED,
  SET_ACTIVE_DIALOGS,
  SET_DIALOG_HISTORY,
  SET_FINISHED_DIALOGS,
  SET_INS_TODAY,
  SET_LATEST_MESSAGE_DIALOG,
  SET_NEW_CUSTOMER_TODAY,
  SET_OPEN_DIALOGS,
  SET_PENDING_DIALOGS,
  SET_TOTAL_COINS,
  TRANSFER_DIALOG,
} from './actionTypes';

interface IAnimationState {
  finishedDialogs: number;
  isLoadingFinishedDialogs: boolean;

  pendingDialogs: number;
  isLoadingPendingDialogs: boolean;

  totalCoins: number;
  isLoadingTotalCoins: boolean;

  insToday: number;
  isLoadingInsToday: boolean;

  newCustomerToday: number;
  isLoadingNewCustomerToday: boolean;

  activeDialogs: IActiveDialog[];
  isLoadingActiveDialogs: boolean;

  openDialogs: IActiveDialog[];
  isLoadingOpenDialogs: boolean;

  dialogHistory: IActiveDialogHistory | null;

  transferDialog: number | null;

  latestMessageDialog: {
    userId?: number;
    fakeId?: number;
  };
}

const initialState: IAnimationState = {
  finishedDialogs: 0,
  isLoadingFinishedDialogs: false,

  pendingDialogs: 0,
  isLoadingPendingDialogs: false,

  totalCoins: 0,
  isLoadingTotalCoins: false,

  insToday: 0,
  isLoadingInsToday: false,

  newCustomerToday: 0,
  isLoadingNewCustomerToday: false,

  activeDialogs: [],
  isLoadingActiveDialogs: false,

  openDialogs: [],
  isLoadingOpenDialogs: false,

  dialogHistory: null,
  transferDialog: null,

  latestMessageDialog: {},
};

const Animation = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_FINISHED_DIALOGS:
      state = {
        ...state,
        finishedDialogs: action.payload,
        isLoadingFinishedDialogs: false,
      };
      break;
    case LOAD_FINISHED_DIALOGS:
      state = {
        ...state,
        isLoadingFinishedDialogs: true,
      };
      break;
    case LOAD_FINISHED_DIALOGS_FAILED:
      state = {
        ...state,
        isLoadingFinishedDialogs: false,
      };
      break;

    case SET_PENDING_DIALOGS:
      state = {
        ...state,
        pendingDialogs: action.payload,
        isLoadingPendingDialogs: false,
      };
      break;
    case LOAD_PENDING_DIALOGS:
      state = {
        ...state,
        isLoadingPendingDialogs: true,
      };
      break;
    case LOAD_PENDING_DIALOGS_FAILED:
      state = {
        ...state,
        isLoadingPendingDialogs: false,
      };
      break;

    case SET_TOTAL_COINS:
      state = {
        ...state,
        totalCoins: action.payload,
        isLoadingTotalCoins: false,
      };
      break;
    case LOAD_TOTAL_COINS:
      state = {
        ...state,
        isLoadingTotalCoins: true,
      };
      break;
    case LOAD_TOTAL_COINS_FAILED:
      state = {
        ...state,
        isLoadingTotalCoins: false,
      };
      break;

    case SET_INS_TODAY:
      state = {
        ...state,
        insToday: action.payload,
        isLoadingInsToday: false,
      };
      break;
    case LOAD_INS_TODAY:
      state = {
        ...state,
        isLoadingInsToday: true,
      };
      break;
    case LOAD_INS_TODAY_FAILED:
      state = {
        ...state,
        isLoadingInsToday: false,
      };
      break;

    case SET_NEW_CUSTOMER_TODAY:
      state = {
        ...state,
        newCustomerToday: action.payload,
        isLoadingNewCustomerToday: false,
      };
      break;
    case LOAD_NEW_CUSTOMER_TODAY:
      state = {
        ...state,
        isLoadingNewCustomerToday: true,
      };
      break;
    case LOAD_NEW_CUSTOMER_TODAY_FAILED:
      state = {
        ...state,
        isLoadingNewCustomerToday: false,
      };
      break;

    case SET_ACTIVE_DIALOGS:
      state = {
        ...state,
        activeDialogs: action.payload,
        isLoadingActiveDialogs: false,
      };
      break;
    case LOAD_ACTIVE_DIALOGS:
      state = {
        ...state,
        isLoadingActiveDialogs: true,
      };
      break;
    case LOAD_ACTIVE_DIALOGS_FAILED:
      state = {
        ...state,
        isLoadingActiveDialogs: false,
      };
      break;

    case SET_OPEN_DIALOGS:
      state = {
        ...state,
        openDialogs: action.payload,
        isLoadingOpenDialogs: false,
      };
      break;
    case LOAD_OPEN_DIALOGS:
      state = {
        ...state,
        isLoadingOpenDialogs: true,
      };
      break;
    case LOAD_OPEN_DIALOGS_FAILED:
      state = {
        ...state,
        isLoadingOpenDialogs: false,
      };
      break;

    case SET_DIALOG_HISTORY:
      state = {
        ...state,
        dialogHistory: action.payload,
      };
      break;

    case TRANSFER_DIALOG:
      state = {
        ...state,
        transferDialog: action.payload,
      };
      break;

    case SET_LATEST_MESSAGE_DIALOG:
      state = {
        ...state,
        latestMessageDialog: {
          userId: action.payload.userId,
          fakeId: action.payload.fakeId,
        },
      };
      break;
    default:
      break;
  }

  return state;
};

export default Animation;
