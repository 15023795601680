import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Login from './auth/login/reducer';
import Redirect from './redirect/reducer';
import Statistic from './overview/statistic/reducer';
import OnlineCustomer from './online-customer/reducer';
import Animation from './animation/reducer';
import Moderator from './moderator/reducer';
import Agency from './agency/reducer';
import Customer from './customer/reducer';
import Present from './present/reducer';
import Tools from './tools/reducer';
import PaymentMethods from './payment-methods/reducer';
import CoinPackages from './coin-packages/reducer';
import Support from './support/reducer';

const rootReducer = combineReducers({
  // public
  Layout,

  // Authentication
  Login,

  Redirect,

  Statistic,

  OnlineCustomer,

  Animation,

  Moderator,

  Agency,
  Customer,
  Present,
  Tools,
  PaymentMethods,
  CoinPackages,
  Support,
});

export default rootReducer;
