import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  ConfirmDialog,
  IConfirmDialogOptions,
} from '../../../components/Common/ConfirmDialog';
import { ICoinPackage } from '../../../models/coin-package';
import {
  activateCoinPackage,
  deactivateCoinPackage,
} from '../../../services/coin-package.service';
import { loadCoinPackages } from '../../../store/coin-packages/actions';
import CoinPackageImage from '../../../components/CoinPackage/CoinPackageImage';

import CoinPackageEditPaymentMethodsModal from './CoinPackageEditPaymentMethodsModal';
import CoinPackagesTableToolbar from './CoinPackagesTableToolbar';
import CoinPackageEditModal from './CoinPackageEditModal';

export default function CoinPackagesTable() {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCoinPackage, setEditCoinPackage] = useState<ICoinPackage>();

  const [isEditPaymentMethodsModalOpen, setIsEditPaymentMethodsModalOpen] =
    useState(false);
  const [editPaymentMethodsCoinPackage, setEditPaymentMethodsCoinPackage] =
    useState<ICoinPackage>();

  const token = useAppSelector((state) => state.Login.token);

  const loading = useAppSelector((state) => state.CoinPackages.isLoading);
  const coinPackages = useAppSelector(
    (state) => state.CoinPackages.coinPackages,
  );

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const onEditPaymentMethodsClick = function (row: ICoinPackage) {
    setEditPaymentMethodsCoinPackage(row);
    setIsEditPaymentMethodsModalOpen(true);
  };

  const onEditClick = function (row: ICoinPackage) {
    setEditCoinPackage(row);
    setIsEditModalOpen(true);
  };

  const onDeactivateClick = function (row: ICoinPackage) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'FlirtCoin Paket deaktivieren',
      isOpen: true,
      content: `Wollen Sie das Paket ${row.Name} wirklich deaktivieren?`,
      confirmCallback: async () => {
        await deactivateCoinPackage(row.CoinPackageId, token!);
        dispatch(
          loadCoinPackages({
            page,
            size: pageSize,
          }),
        );
        toast.success('Das Paket wurde erfolgreich deaktiviert.');
      },
    });
  };

  const onActivateClick = function (row: ICoinPackage) {
    setConfirmDialog({
      ...confirmDialog,
      title: 'FlirtCoin Paket aktivieren',
      isOpen: true,
      content: `Wollen Sie das Paket ${row.Name} wirklich aktivieren?`,
      confirmCallback: async () => {
        await activateCoinPackage(row.CoinPackageId, token!);
        toast.success('Das Paket wurde erfolgreich aktiviert.');
        dispatch(
          loadCoinPackages({
            page,
            size: pageSize,
          }),
        );
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'CoinPackageId',
      headerName: '',
      width: 50,
    },
    {
      field: 'ImageUrl',
      headerName: '',
      width: 50,
      renderCell: (cell) => (
        <>
          <CoinPackageImage
            coinPackageId={(cell.row as ICoinPackage).CoinPackageId}
          />
        </>
      ),
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Description',
      headerName: 'Beschreibung',
      flex: 1,
    },
    {
      field: 'Amount',
      headerName: 'Coins',
      flex: 1,
      renderCell: (cell) =>
        `${(cell.value as number).toLocaleString('de-DE')} FlirtCoins`,
    },
    {
      field: 'Price',
      headerName: 'Preis',
      width: 150,
      renderCell: (cell) =>
        (cell.value as number).toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }),
    },
    {
      field: 'OneTimePackage',
      headerName: 'Einmal-Paket',
      flex: 1,
      renderCell: (cell) => {
        const coinPackage = cell.row as ICoinPackage;
        if (!coinPackage.OneTimePackage) {
          return (
            <i className={'ri-close-fill ri-2x'} title={'Kein Einmal-Paket'} />
          );
        }

        return <i className={'ri-check-fill ri-2x'} title={'Einmal-Paket'} />;
      },
    },
    {
      field: 'Popular',
      headerName: 'Hervorgehoben',
      flex: 1,
      renderCell: (cell) => {
        const coinPackage = cell.row as ICoinPackage;
        if (!coinPackage.Popular) {
          return (
            <i
              className={'ri-close-fill ri-2x'}
              title={'Nicht hervorgehoben'}
            />
          );
        }

        return (
          <i
            className={'ri-check-fill ri-2x'}
            title={'Hervorgehobenes Paket'}
          />
        );
      },
    },
    {
      field: 'Deleted',
      headerName: 'Aktiv',
      width: 120,
      renderCell: (cell) => {
        const coinPackage = cell.row as ICoinPackage;
        if (coinPackage.Deleted) {
          return <i className={'ri-close-fill ri-2x'} title={'Deaktiviert'} />;
        }

        return <i className={'ri-check-fill ri-2x'} title={'Aktiviert'} />;
      },
    },
    {
      field: 'DateChange',
      headerName: 'Geändert Am',
      flex: 1,
      renderCell: (cell) => {
        const momentDate = moment(cell.value as string);
        return `${momentDate.format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (cell) => {
        const coinPackage = cell.row as ICoinPackage;

        let StatusToggle = (
          <Button
            color={'success'}
            className={'ml-2 btn-sm btn-rounded'}
            title={'Paket aktivieren'}
            onClick={() => onActivateClick(cell.row as ICoinPackage)}
          >
            <i className={'ri-check-line'} />
          </Button>
        );

        if (!coinPackage.Deleted) {
          StatusToggle = (
            <Button
              color={'danger'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Paket deaktivieren'}
              onClick={() => onDeactivateClick(cell.row as ICoinPackage)}
            >
              <i className={'ri-close-line'} />
            </Button>
          );
        }

        return (
          <div className={'d-flex flex-row'}>
            <Button
              color={'info'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Paket bearbeiten'}
              onClick={() => onEditClick(cell.row as ICoinPackage)}
            >
              <i className={'ri-edit-2-line'} />
            </Button>
            <Button
              color={'warning'}
              className={'ml-2 btn-sm btn-rounded'}
              title={'Zahlungsmethode zuweisen'}
              onClick={() =>
                onEditPaymentMethodsClick(cell.row as ICoinPackage)
              }
            >
              <i className={'ri-money-euro-box-line'} />
            </Button>
            {StatusToggle}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      loadCoinPackages({
        page,
        size: pageSize,
      }),
    );
  }, [pageSize, page]);

  return (
    <Card>
      <CardBody>
        <DataGrid
          rows={coinPackages.Data}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination={true}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          getRowId={(row) => row.CoinPackageId}
          components={{
            Toolbar: CoinPackagesTableToolbar,
          }}
          autoHeight={true}
          rowCount={coinPackages.Total}
          paginationMode={'server'}
          disableSelectionOnClick
          loading={loading}
        />
      </CardBody>
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
      <CoinPackageEditModal
        close={() => {
          setIsEditModalOpen(false);
          dispatch(
            loadCoinPackages({
              page,
              size: pageSize,
            }),
          );
        }}
        isOpen={isEditModalOpen}
        coinPackage={editCoinPackage}
      />
      <CoinPackageEditPaymentMethodsModal
        isOpen={isEditPaymentMethodsModalOpen}
        coinPackage={editPaymentMethodsCoinPackage}
        close={() => {
          setIsEditPaymentMethodsModalOpen(false);
        }}
      />
    </Card>
  );
}
