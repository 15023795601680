import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IModerator } from '../../models/moderator';
import {
  getModerators,
  getOnlineModerators,
} from '../../services/moderator.service';
import { IPagedRequest, IPagedResult } from '../../models';

import {
  loadModeratorsFailed,
  loadOnlineModeratorsFailed,
  setModerators,
  setOnlineModerators,
} from './actions';
import {
  LOAD_MODERATORS,
  LOAD_ONLINE_MODERATORS,
  SET_MODERATORS,
} from './actionTypes';

function* loadModerators(action: IBaseAction<IPagedRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<IModerator> = yield call(
      getModerators,
      action.payload.page,
      action.payload.size,
      token,
    );

    yield put(setModerators(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadModeratorsFailed());
  }
}

function* loadOnlineModerators(action: IBaseAction<any>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IModerator[] = yield call(getOnlineModerators, token);

    yield put(setOnlineModerators(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadOnlineModeratorsFailed());
  }
}

export function* watchLoadModerators() {
  yield takeEvery<IBaseAction<any>>(LOAD_MODERATORS, loadModerators);
}

export function* watchLoadOnlineModerators() {
  yield takeEvery<IBaseAction<any>>(
    LOAD_ONLINE_MODERATORS,
    loadOnlineModerators,
  );
}

function* moderatorSaga() {
  yield all([fork(watchLoadOnlineModerators), fork(watchLoadModerators)]);
}

export default moderatorSaga;
