import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import axios, { CancelTokenSource } from 'axios';
import { Check, Delete } from '@material-ui/icons';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

import {
  approveProfilePicture,
  getPendingProfilePictureURL,
  getPendingProfilePictures,
  rejectProfilePicture,
} from '../../services/customer.service';
import { ICustomerProfilePicture } from '../../models/customer';
import CustomerName from '../Customer/CustomerName';
import { useAppSelector } from '../../store/hooks';

export default function PendingProfilePictures() {
  const [isLoading, setIsLoading] = useState(false);
  const token = useAppSelector((state) => state.Login.token);
  const [pending, setPending] = useState<ICustomerProfilePicture[]>([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let interval: NodeJS.Timeout | null = null;

    const loadPending = async () => {
      await onLoadPending();
    };

    loadPending();

    interval = setInterval(() => loadPending(), 60000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      cancelToken.cancel();
    };
  }, [token]);

  const onLoadPending = async (
    interval?: any,
    cancelToken?: CancelTokenSource,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const _pending = await getPendingProfilePictures(
        token!,
        cancelToken?.token,
      );

      setPending(_pending);
      setIsLoading(false);
    } catch (e) {
      toast.error('Beim Abruf der Profil-Bilder ist ein Fehler aufgetreten.');

      if (interval) {
        clearInterval(interval);
      }
    }
  };

  const onDeleteProfilePicture = async (userId: number): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await rejectProfilePicture(userId, token!);
      await onLoadPending(undefined, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Löschen des Profilbilds');
    }

    setIsLoading(false);
  };

  const onApproveProfilePicture = async (userId: number): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await approveProfilePicture(userId, token!);
      await onLoadPending(undefined, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Freigeben des Profilbilds');
    }

    setIsLoading(false);
  };

  return (
    <>
      <Card>
        <CardHeader>Offene Freigaben</CardHeader>
        <CardBody>
          <LoadingOverlay spinner active={isLoading}>
            <div className={'table-responsive'}>
              <table className={'table table-hover'}>
                <thead>
                  <tr>
                    <th>Benutzer</th>
                    <th>Vorschau</th>
                    <th>Hochgeladen am</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!pending || pending.length <= 0 ? (
                    <tr>
                      <td colSpan={4} className={'text-center'}>
                        Keine Sprachnotizen gefunden
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {pending?.map((picture) => (
                    <>
                      <tr key={picture.UserId}>
                        <td>
                          <Link
                            to={`/customers/${picture.UserId}`}
                            className="dropdown-item"
                          >
                            <CustomerName userId={picture.UserId} />
                          </Link>
                        </td>
                        <td>
                          <img
                            src={getPendingProfilePictureURL(picture.UserId)}
                            className={''}
                            style={{ height: '120px', width: 'auto' }}
                          />
                        </td>
                        <td>{picture.DateCreate}</td>
                        <td>
                          <button
                            className={'btn btn-sm text-success'}
                            onClick={() =>
                              onApproveProfilePicture(picture.UserId)
                            }
                            title={'Bild freigeben'}
                          >
                            <Check />
                          </button>
                          <button
                            className={'btn btn-sm text-danger'}
                            onClick={() =>
                              onDeleteProfilePicture(picture.UserId)
                            }
                            title={'Bild löschen'}
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </>
  );
}
