import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import axios, { CancelTokenSource } from 'axios';
import { Check, Close, Delete, PlayArrow } from '@material-ui/icons';
import { toast } from 'react-toastify';

import {
  approveVoiceDescription,
  deleteVoiceDescription,
  disapproveVoiceDescription,
  getCustomerVoiceDescriptions,
} from '../../../services/customer.service';
import { ICustomerVoiceDescription } from '../../../models/customer';
import { useAppSelector } from '../../../store/hooks';
import CustomerAddVoiceDescriptionModal from '../../../components/Customer/CustomerAddVoiceDescriptionModal';
import CustomerPlayVoiceDescriptionModal from '../../../components/Customer/CustomerPlayVoiceDescriptionModal';

export default function CustomerVoiceDescriptions(opt: { userId: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isPlayModalOpen, setIsPlayModalOpen] = useState(false);
  const [playVoiceDescription, setPlayVoiceDescription] =
    useState<ICustomerVoiceDescription>();
  const [voiceDescriptions, setVoiceDescriptions] = useState<
    ICustomerVoiceDescription[]
  >([]);
  const token = useAppSelector((state) => state.Login.token);

  const loadDescriptions = async (
    userId: number,
    cancelToken: CancelTokenSource,
  ) => {
    setIsLoading(true);
    try {
      const descriptions = await getCustomerVoiceDescriptions(
        userId,
        token!,
        cancelToken.token,
      );

      setVoiceDescriptions(descriptions);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  const onDeleteVoiceDescription = async (
    userVoiceDescriptionId: number,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await deleteVoiceDescription(opt.userId, userVoiceDescriptionId, token!);
      await loadDescriptions(opt.userId, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Löschen der Sprachnotiz');
    }

    setIsLoading(false);
  };

  const onApproveVoiceDescription = async (
    userVoiceDescriptionId: number,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await approveVoiceDescription(opt.userId, userVoiceDescriptionId, token!);
      await loadDescriptions(opt.userId, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Freigeben der Sprachnotiz');
    }

    setIsLoading(false);
  };

  const onDisapproveVoiceDescription = async (
    userVoiceDescriptionId: number,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const cancelToken = axios.CancelToken.source();

      await disapproveVoiceDescription(
        opt.userId,
        userVoiceDescriptionId,
        token!,
      );
      await loadDescriptions(opt.userId, cancelToken);
    } catch (e) {
      toast.error('Fehler beim Sperren der Sprachnotiz');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!opt.userId) return;

    const cancelToken = axios.CancelToken.source();

    loadDescriptions(opt.userId, cancelToken);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [opt.userId]);

  if (isLoading) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Sprach-Notizen</CardHeader>
        <CardBody className={'mx-auto text-center'}>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <CustomerPlayVoiceDescriptionModal
        voiceDescription={playVoiceDescription}
        isOpen={isPlayModalOpen}
        toggle={() => setIsPlayModalOpen(!isPlayModalOpen)}
      />
      <CustomerAddVoiceDescriptionModal
        userId={opt.userId}
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
        onAdded={() => {
          const cancelToken = axios.CancelToken.source();
          loadDescriptions(opt.userId, cancelToken);
        }}
      />
      <Card className={'flex-fill'}>
        <CardHeader>
          <div className={'d-flex flex-wrap'}>
            <div>Sprachnotizen</div>
            <div className={'ml-auto'}>
              {voiceDescriptions.length < 3 ? (
                <a href={'#'} onClick={() => setIsAddModalOpen(true)}>
                  Hinzufügen
                </a>
              ) : (
                <span>Nur 3 Sprachnotizen pro Nutzer erlaubt</span>
              )}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <table className={'table table-hover'}>
            <thead>
              <tr>
                <th>Titel</th>
                <th>Freigegeben</th>
                <th>Hochgeladen am</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!voiceDescriptions || voiceDescriptions.length <= 0 ? (
                <tr>
                  <td colSpan={4} className={'text-center'}>
                    Keine Sprachnotizen gefunden
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {voiceDescriptions?.map((voice) => (
                <>
                  <tr key={voice.UserVoiceDescriptionId}>
                    <td>{voice.Title}</td>
                    <td>{voice.IsApproved ? <Check /> : <Close />}</td>
                    <td>{voice.DateCreate}</td>
                    <td>
                      <button
                        className={'btn btn-sm text-info'}
                        onClick={() => {
                          setPlayVoiceDescription(voice);
                          setIsPlayModalOpen(true);
                        }}
                      >
                        <PlayArrow />
                      </button>
                      {voice.IsApproved ? (
                        <button
                          className={'btn btn-sm text-warning'}
                          onClick={() =>
                            onDisapproveVoiceDescription(
                              voice.UserVoiceDescriptionId,
                            )
                          }
                          title={'Sprachnotiz sperren'}
                        >
                          <Close />
                        </button>
                      ) : (
                        <button
                          className={'btn btn-sm text-success'}
                          onClick={() =>
                            onApproveVoiceDescription(
                              voice.UserVoiceDescriptionId,
                            )
                          }
                          title={'Sprachnotiz freigeben'}
                        >
                          <Check />
                        </button>
                      )}
                      <button
                        className={'btn btn-sm text-danger'}
                        onClick={() =>
                          onDeleteVoiceDescription(voice.UserVoiceDescriptionId)
                        }
                        title={'Sprachnotiz löschen'}
                      >
                        <Delete />
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
}
