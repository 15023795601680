import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';

import TicketsPage from './tickets';

export default function SupportPage() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/tickets`}>
          <TicketsPage />
        </Route>
      </Switch>
    </>
  );
}
