import { AxiosRequestConfig } from 'axios';

export default function getAxiosOptions(
  token: string,
  additionalConfig?: AxiosRequestConfig,
): AxiosRequestConfig {
  return {
    ...additionalConfig,
    withCredentials: true,
    // headers: {
    //     Authorization: "Bearer " + token
    // },
  } as AxiosRequestConfig;
}
