import { createPortal } from 'react-dom';
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import LoadingButton from '../Common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addVoiceDescription } from '../../services/customer.service';

interface ICustomerAddVoiceDescriptionModalOptions {
  userId: number;
  isOpen: boolean;
  toggle: () => void;
  onAdded?: () => void;
}

export default function CustomerAddVoiceDescriptionModal(
  opt: ICustomerAddVoiceDescriptionModalOptions,
) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const [voiceDescriptionFile, setVoiceDescriptionFile] = useState<File>();

  const formik = useFormik({
    initialValues: {
      title: undefined,
    },
    onReset: (values) => {
      values.title = undefined;
    },
    validateOnMount: true,
    validate: (values) => {
      const err = {} as any;

      if (!values.title) {
        err.title = 'Bitte geben Sie einen Titel ein';
      }

      return err;
    },
    onSubmit: async (values) => {
      try {
        if (!values.title) return;
        if (!voiceDescriptionFile) return;

        await addVoiceDescription(
          opt.userId,
          values.title,
          voiceDescriptionFile,
          token!,
        );
        // await modifyCoins(opt.userId, values.coinCount, token!)

        toast.success('Die Sprachnotiz wurde erfolgreich hochgeladen.');

        if (opt.onAdded) opt.onAdded();

        opt.toggle();
        formik.resetForm();
      } catch (err) {
        toast.error('Fehler beim Hochladen der Sprachnotiz.');
      }
    },
  });

  return createPortal(
    <Modal
      isOpen={opt.isOpen}
      toggle={() => opt.toggle()}
      centered={true}
      backdrop={true}
    >
      <ModalHeader toggle={() => opt.toggle()}>
        Sprachnotiz hinzufügen
      </ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Titel</Label>
              <input
                type={'text'}
                className={`form-control${
                  formik.errors.title && formik.touched.title
                    ? ' is-invalid'
                    : ''
                }`}
                id={'title'}
                name={'title'}
                placeholder={'Titel eingeben'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              <ErrorMessage
                name={'title'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <FormGroup>
              <Label>Audio-Datei</Label>
              <input
                type={'file'}
                className={'form-control'}
                id={'file'}
                name={'file'}
                placeholder={'Audio-Datei auswählen'}
                accept={'audio/*'}
                onChange={(ev) => {
                  if (
                    ev.currentTarget &&
                    ev.currentTarget.files &&
                    ev.currentTarget.files.length > 0
                  ) {
                    setVoiceDescriptionFile(ev.currentTarget.files[0]);
                  } else {
                    setVoiceDescriptionFile(undefined);
                  }
                }}
                onBlur={formik.handleBlur}
              />
            </FormGroup>
            <br />
            <LoadingButton
              isLoading={formik.isSubmitting}
              className={'mt-2'}
              caption={'Hochladen'}
              isSubmit={true}
              disabled={
                formik.isSubmitting || !formik.isValid || !voiceDescriptionFile
              }
            />
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
