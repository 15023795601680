import React, { useEffect, useState } from 'react';
import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import {
  getTotalMessages,
  getTotalPictures,
} from '../../../services/message.service';
import { useAppSelector } from '../../../store/hooks';
import { getTotalPresents } from '../../../services/present.service';
import {
  getTotalGreetings,
  getTotalKisses,
} from '../../../services/notification.service';

import ActiveDialogMessages from './ActiveDialogMessages';
import ActiveDialogPictures from './ActiveDialogPictures';
import ActiveDialogPresents from './ActiveDialogPresents';
import ActiveDialogKisses from './ActiveDialogKisses';
import ActiveDialogGreetings from './ActiveDialogGreetings';


export interface IActiveDialogHistory {
  userId: number | undefined;
  fakeId: number | undefined;
}

export default function ActiveDialogHistory(opt: IActiveDialogHistory) {
  const token = useAppSelector((state) => state.Login.token);
  const [activeTab, setActiveTab] = useState('messages');

  const [messageCount, setMessageCount] = useState(0);
  const [picturesCount, setPicturesCount] = useState(0);
  const [greetingsCount, setGreetingsCount] = useState(0);
  const [kissesCount, setKissesCount] = useState(0);
  const [presentsCount, setPresentsCount] = useState(0);

  useEffect(() => {
    let cancel = false;

    if (!opt.userId || !opt.fakeId) {
      setPicturesCount(0);
      setMessageCount(0);
      setKissesCount(0);
      setPresentsCount(0);
      setGreetingsCount(0);
      return;
    }

    const loadMessageCount = async function () {
      const totalMessages = await getTotalMessages(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;
      setMessageCount(totalMessages);
    };

    const loadPicturesCount = async function () {
      const picturesCount = await getTotalPictures(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;
      setPicturesCount(picturesCount);
    };

    const loadPresentsCount = async function () {
      const presentsCount = await getTotalPresents(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;
      setPresentsCount(presentsCount);
    };

    const loadKissesCount = async function () {
      const kissesCount = await getTotalKisses(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;
      setKissesCount(kissesCount);
    };

    const loadGreetingsCount = async function () {
      const greetingsCount = await getTotalGreetings(
        opt.userId!,
        opt.fakeId!,
        token!,
      );

      if (cancel) return;
      setGreetingsCount(greetingsCount);
    };

    loadMessageCount();
    loadPicturesCount();
    loadPresentsCount();
    loadKissesCount();
    loadGreetingsCount();

    return () => {
      cancel = true;
    };
  }, [opt.userId, opt.fakeId, token]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === 'messages' },
              'text-center',
            )}
            onClick={() => toggle('messages')}
          >
            <div className={'d-flex align-items-center justify-content-center'}>
              <div>
                <i className={'ri-message-line'} />
              </div>
              <div className={'ml-2'}>
                <Badge color={'dark'}>{messageCount}</Badge>
              </div>
            </div>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === 'images' },
              'text-center',
            )}
            onClick={() => toggle('images')}
          >
            <div className={'d-flex align-items-center justify-content-center'}>
              <div>
                <i className={'ri-picture-in-picture-line'} />
              </div>
              <div className={'ml-2'}>
                <Badge color={'dark'}>{picturesCount}</Badge>
              </div>
            </div>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === 'greetings' },
              'text-center',
            )}
            onClick={() => toggle('greetings')}
          >
            <div className={'d-flex align-items-center justify-content-center'}>
              <div>
                <i className={'ri-chat-smile-line'} />
              </div>
              <div className={'ml-2'}>
                <Badge color={'dark'}>{greetingsCount}</Badge>
              </div>
            </div>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === 'kisses' },
              'text-center',
            )}
            onClick={() => toggle('kisses')}
          >
            <div className={'d-flex align-items-center justify-content-center'}>
              <div>
                <i className={'mdi mdi-emoticon-kiss-outline'} />
              </div>
              <div className={'ml-2'}>
                <Badge color={'dark'}>{kissesCount}</Badge>
              </div>
            </div>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === 'presents' },
              'text-center',
            )}
            onClick={() => toggle('presents')}
          >
            <div className={'d-flex align-items-center justify-content-center'}>
              <div>
                <i className={'ri-gift-fill'} />
              </div>
              <div className={'ml-2'}>
                <Badge color={'dark'}>{presentsCount}</Badge>
              </div>
            </div>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={'messages'} className={'p-2'}>
          <ActiveDialogMessages userId={opt.userId} fakeId={opt.fakeId} />
        </TabPane>

        <TabPane tabId={'images'} className={'p-2'}>
          <ActiveDialogPictures userId={opt.userId} fakeId={opt.fakeId} />
        </TabPane>

        <TabPane tabId={'presents'} className={'p-2'}>
          <ActiveDialogPresents userId={opt.userId} fakeId={opt.fakeId} />
        </TabPane>

        <TabPane tabId={'kisses'} className={'p-2'}>
          <ActiveDialogKisses userId={opt.userId} fakeId={opt.fakeId} />
        </TabPane>

        <TabPane tabId={'greetings'} className={'p-2'}>
          <ActiveDialogGreetings userId={opt.userId} fakeId={opt.fakeId} />
        </TabPane>
      </TabContent>
    </>
  );
}
