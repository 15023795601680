import { IBaseAction } from '../../type';
import { IRevenue } from '../../../models/statistic';

import {
  LOAD_INCOMING_MESSAGES,
  LOAD_INCOMING_MESSAGES_FAILED,
  LOAD_NEW_CUSTOMERS_COUNT,
  LOAD_NEW_CUSTOMERS_COUNT_FAILED,
  LOAD_ONLINE_CUSTOMERS_COUNT,
  LOAD_ONLINE_CUSTOMERS_COUNT_FAILED,
  LOAD_OUTGOING_MESSAGES,
  LOAD_OUTGOING_MESSAGES_FAILED,
  LOAD_PENDING_ASA_DIALOGS_COUNT,
  LOAD_PENDING_ASA_DIALOGS_COUNT_FAILED,
  LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
  LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT_FAILED,
  LOAD_PENDING_NORMAL_DIALOGS_COUNT,
  LOAD_PENDING_NORMAL_DIALOGS_COUNT_FAILED,
  LOAD_REVENUE,
  LOAD_REVENUE_FAILED,
  LOAD_TODAYS_REVENUE,
  LOAD_TODAYS_REVENUE_FAILED,
  LOAD_TODAY_SENT_MESSAGES,
  LOAD_TODAY_SENT_MESSAGES_FAILED,
  SET_INCOMING_MESSAGES,
  SET_NEW_CUSTOMERS_COUNT,
  SET_ONLINE_CUSTOMERS_COUNT,
  SET_OUTGOING_MESSAGES,
  SET_PENDING_ASA_DIALOGS_COUNT,
  SET_PENDING_NEWCUSTOMER_DIALOGS_COUNT,
  SET_PENDING_NORMAL_DIALOGS_COUNT,
  SET_REVENUE,
  SET_TODAYS_REVENUE,
  SET_TODAY_SENT_MESSAGES,
} from './actionTypes';

interface IStatisticOverviewState {
  revenue: IRevenue[];
  loadingRevenue: boolean;

  todaySentMessages: number;
  loadingTodaySentMessages: boolean;

  onlineCustomerCount: number;
  loadingOnlineCustomerCount: boolean;

  todaysRevenue: number;
  loadingTodaysRevenue: boolean;

  incomingMessages: number;
  loadingIncomingMessages: boolean;

  outgoingMessages: number;
  loadingOutgoingMessages: boolean;

  newCustomersCount: number;
  loadingNewCustomersCount: boolean;

  pendingAsaDialogsCount: number;
  loadingPendingAsaDialogsCount: boolean;

  pendingNormalDialogsCount: number;
  loadingPendingNormalDialogsCounts: boolean;

  pendingNewCustomerDialogsCount: number;
  loadingPendingNewCustomerDialogsCount: boolean;
}

const initialState: IStatisticOverviewState = {
  revenue: [],
  loadingRevenue: false,
  todaySentMessages: 0,
  loadingTodaySentMessages: false,
  onlineCustomerCount: 0,
  loadingOnlineCustomerCount: false,
  todaysRevenue: 0,
  loadingTodaysRevenue: false,
  incomingMessages: 0,
  loadingIncomingMessages: false,
  outgoingMessages: 0,
  loadingOutgoingMessages: false,
  newCustomersCount: 0,
  loadingNewCustomersCount: false,
  pendingAsaDialogsCount: 0,
  loadingPendingAsaDialogsCount: false,
  pendingNormalDialogsCount: 0,
  loadingPendingNormalDialogsCounts: false,
  pendingNewCustomerDialogsCount: 0,
  loadingPendingNewCustomerDialogsCount: false,
};

const Statistic = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_REVENUE:
      state = {
        ...state,
        revenue: action.payload,
        loadingRevenue: false,
      };
      break;
    case LOAD_REVENUE:
      state = {
        ...state,
        loadingRevenue: true,
      };
      break;
    case LOAD_REVENUE_FAILED:
      state = {
        ...state,
        loadingRevenue: false,
      };
      break;
    case SET_TODAY_SENT_MESSAGES:
      state = {
        ...state,
        todaySentMessages: action.payload,
        loadingTodaySentMessages: false,
      };
      break;
    case LOAD_TODAY_SENT_MESSAGES:
      state = {
        ...state,
        loadingTodaySentMessages: true,
      };
      break;
    case LOAD_TODAY_SENT_MESSAGES_FAILED:
      state = {
        ...state,
        loadingTodaySentMessages: false,
      };
      break;
    case SET_ONLINE_CUSTOMERS_COUNT:
      state = {
        ...state,
        onlineCustomerCount: action.payload,
        loadingOnlineCustomerCount: false,
      };
      break;
    case LOAD_ONLINE_CUSTOMERS_COUNT:
      state = {
        ...state,
        loadingOnlineCustomerCount: true,
      };
      break;
    case LOAD_ONLINE_CUSTOMERS_COUNT_FAILED:
      state = {
        ...state,
        loadingOnlineCustomerCount: false,
      };
      break;
    case SET_TODAYS_REVENUE:
      state = {
        ...state,
        todaysRevenue: action.payload,
        loadingTodaysRevenue: false,
      };
      break;
    case LOAD_TODAYS_REVENUE:
      state = {
        ...state,
        loadingTodaysRevenue: true,
      };
      break;
    case LOAD_TODAYS_REVENUE_FAILED:
      state = {
        ...state,
        loadingTodaysRevenue: false,
      };
      break;
    case SET_INCOMING_MESSAGES:
      state = {
        ...state,
        incomingMessages: action.payload,
        loadingIncomingMessages: false,
      };
      break;
    case LOAD_INCOMING_MESSAGES:
      state = {
        ...state,
        loadingIncomingMessages: true,
      };
      break;
    case LOAD_INCOMING_MESSAGES_FAILED:
      state = {
        ...state,
        loadingIncomingMessages: false,
      };
      break;
    case SET_OUTGOING_MESSAGES:
      state = {
        ...state,
        outgoingMessages: action.payload,
        loadingOutgoingMessages: false,
      };
      break;
    case LOAD_OUTGOING_MESSAGES:
      state = {
        ...state,
        loadingOutgoingMessages: true,
      };
      break;
    case LOAD_OUTGOING_MESSAGES_FAILED:
      state = {
        ...state,
        loadingOutgoingMessages: false,
      };
      break;
    case SET_NEW_CUSTOMERS_COUNT:
      state = {
        ...state,
        newCustomersCount: action.payload,
        loadingNewCustomersCount: false,
      };
      break;
    case LOAD_NEW_CUSTOMERS_COUNT:
      state = {
        ...state,
        loadingNewCustomersCount: true,
      };
      break;
    case LOAD_NEW_CUSTOMERS_COUNT_FAILED:
      state = {
        ...state,
        loadingNewCustomersCount: false,
      };
      break;
    case SET_PENDING_ASA_DIALOGS_COUNT:
      state = {
        ...state,
        pendingAsaDialogsCount: action.payload,
        loadingPendingAsaDialogsCount: false,
      };
      break;
    case LOAD_PENDING_ASA_DIALOGS_COUNT:
      state = {
        ...state,
        loadingPendingAsaDialogsCount: true,
      };
      break;
    case LOAD_PENDING_ASA_DIALOGS_COUNT_FAILED:
      state = {
        ...state,
        loadingPendingAsaDialogsCount: false,
      };
      break;
    case SET_PENDING_NEWCUSTOMER_DIALOGS_COUNT:
      state = {
        ...state,
        pendingNewCustomerDialogsCount: action.payload,
        loadingPendingNewCustomerDialogsCount: false,
      };
      break;
    case LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT:
      state = {
        ...state,
        loadingPendingNewCustomerDialogsCount: true,
      };
      break;
    case LOAD_PENDING_NEWCUSTOMER_DIALOGS_COUNT_FAILED:
      state = {
        ...state,
        loadingPendingNewCustomerDialogsCount: false,
      };
      break;
    case SET_PENDING_NORMAL_DIALOGS_COUNT:
      state = {
        ...state,
        pendingNormalDialogsCount: action.payload,
        loadingPendingNormalDialogsCounts: false,
      };
      break;
    case LOAD_PENDING_NORMAL_DIALOGS_COUNT:
      state = {
        ...state,
        loadingPendingNormalDialogsCounts: true,
      };
      break;
    case LOAD_PENDING_NORMAL_DIALOGS_COUNT_FAILED:
      state = {
        ...state,
        loadingPendingNormalDialogsCounts: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default Statistic;
