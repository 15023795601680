import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import LatestMessagesTable from './components/LatestMessagesTable';

export default function LatestMessagesPage() {
  const [breadcrumbItems] = useState([
    { title: 'Animation', link: '#' },
    { title: 'Letzten Nachrichten', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Letzten Nachrichten"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-12'}>
              <LatestMessagesTable />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
