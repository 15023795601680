import { IBaseAction } from '../type';
import { IPagedResult } from '../../models';
import { ICoinPackage } from '../../models/coin-package';

import {
  LOAD_COIN_PACKAGES,
  LOAD_COIN_PACKAGES_FAILED,
  SET_COIN_PACKAGES,
} from './actionTypes';

interface ICoinPackageState {
  coinPackages: IPagedResult<ICoinPackage>;
  isLoading: boolean;
}

const initialState: ICoinPackageState = {
  coinPackages: {
    Total: 0,
    Data: [],
  },
  isLoading: false,
};

const CoinPackages = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_COIN_PACKAGES:
      state = {
        ...state,
        coinPackages: action.payload,
        isLoading: false,
      };
      break;
    case LOAD_COIN_PACKAGES:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOAD_COIN_PACKAGES_FAILED:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default CoinPackages;
