import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  NavLink,
  Row,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import { Divider } from '@material-ui/core';

import { getCustomerPublicInformation } from '../../../services/customer.service';
import { ICustomerPublicInformation } from '../../../models/customer';
import { useAppSelector } from '../../../store/hooks';

interface ICustomerDetailsOptions {
  userId: number | undefined;
}

export default function CustomerPublicInformation(
  options: ICustomerDetailsOptions,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [publicInformation, setPublicInformation] =
    useState<ICustomerPublicInformation | null>(null);
  const token = useAppSelector((state) => state.Login.token);

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    const loadPublicInformation = async (userId: number) => {
      setIsLoading(true);
      try {
        const publicInformation = await getCustomerPublicInformation(
          userId,
          token!,
          cancelToken.token,
        );

        setPublicInformation(publicInformation);
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    loadPublicInformation(options.userId);

    return () => {
      cancelToken.cancel('Component unmounted');
    };
  }, [options.userId]);

  if (isLoading) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Öffentliche Daten</CardHeader>
        <CardBody>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={'flex-fill'}>
      <CardHeader>
        <div className={'d-flex flex-wrap'}>
          <div>Öffentliche Daten</div>
          <div className={'ml-auto'}>
            <a href={`/customers/${options.userId}/public-information`}>
              Bearbeiten
            </a>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <div>
              <strong>Ich lebe</strong>
              <div>
                {!publicInformation?.LivingStatus
                  ? 'keine Angabe'
                  : publicInformation?.LivingStatus}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Beziehungsstatus</strong>
              <div>
                {!publicInformation?.RelationshipStatus
                  ? 'keine Angabe'
                  : publicInformation?.RelationshipStatus}
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Letzte Beziehung</strong>
              <div>
                {!publicInformation?.LastRelationShip
                  ? 'keine Angabe'
                  : publicInformation?.LastRelationShip}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Sternzeichen</strong>
              <div>
                {!publicInformation?.StarSign
                  ? 'keine Angabe'
                  : publicInformation?.StarSign}
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Raucher</strong>
              <div>
                {!publicInformation?.Smoker
                  ? 'keine Angabe'
                  : publicInformation?.Smoker}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Beruf</strong>
              <div>
                {!publicInformation?.Job
                  ? 'keine Angabe'
                  : publicInformation?.Job}
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Ausbildung</strong>
              <div>
                {!publicInformation?.Education
                  ? 'keine Angabe'
                  : publicInformation?.Education}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Einkommen</strong>
              <div>
                {!publicInformation?.Income
                  ? 'keine Angabe'
                  : publicInformation?.Income}
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Religion</strong>
              <div>
                {!publicInformation?.Religion
                  ? 'keine Angabe'
                  : publicInformation?.Religion}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <Divider light={true} />
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Größe</strong>
              <div>
                {!publicInformation?.Size
                  ? 'keine Angabe'
                  : publicInformation?.Size}
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Gewicht</strong>
              <div>
                {!publicInformation?.Weight
                  ? 'keine Angabe'
                  : publicInformation?.Weight}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Augenfarbe</strong>
              <div>
                {!publicInformation?.EyeColor
                  ? 'keine Angabe'
                  : publicInformation?.EyeColor}
              </div>
            </div>
          </Col>

          <Col>
            <div>
              <strong>Haarfarbe</strong>
              <div>
                {!publicInformation?.HairColor
                  ? 'keine Angabe'
                  : publicInformation?.HairColor}
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <Divider light={true} />
          </Col>
        </Row>

        <Row className={'mt-2'}>
          <Col>
            <div>
              <strong>Über mich</strong>
              <div>
                {!publicInformation?.AboutMe
                  ? 'keine Angabe'
                  : publicInformation?.AboutMe}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
