import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import login from 'services/auth.service';

import { redirectTo } from '../../redirect/actions';
import { ILoginResult } from '../../../models/auth';
import { IBaseAction } from '../../type';

import { ICheckLogin, loginSuccess, loginUnsuccess } from './actions';
import { CHECK_LOGIN, LOGOUT } from './actionTypes';

// If user is login then dispatch redux action's are directly from here.
function* loginUser(action: IBaseAction<ICheckLogin>) {
  try {
    const response: ILoginResult = yield call(
      login,
      action.payload.username,
      action.payload.password,
    );

    // const response = yield call(fireBaseBackend.loginUser, user.username, user.password);
    yield put(loginSuccess(response.Admin.Username, response.AccessToken));
    yield delay(1);
    yield put(redirectTo('/dashboard'));
  } catch (error) {
    console.log('error', error);
    yield put(loginUnsuccess());
  }
}

// If user is login then dispatch redux action's are directly from here.
function* logoutUser(action: IBaseAction<any>) {
  yield delay(1);
  yield put(redirectTo('/login'));
}

export function* watchUserLogin() {
  yield takeEvery<IBaseAction<ICheckLogin>>(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery<IBaseAction<any>>(LOGOUT, logoutUser);
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default loginSaga;
