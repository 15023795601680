import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import axios, { CancelToken } from 'axios';
import { toast } from 'react-toastify';

import { getCustomerPresents } from '../../../services/customer.service';
import { useAppSelector } from '../../../store/hooks';
import { ICustomerPresent } from '../../../models/customer';
import Present from '../../../components/Present/Present';
import CustomerAddPresentModal from '../../../components/Customer/CustomerAddPresentModal';
import { removePresent } from '../../../services/present.service';

interface ICustomerPresentOptions {
  userId: number | undefined;
}

export default function CustomerPresents(options: ICustomerPresentOptions) {
  const token = useAppSelector((state) => state.Login.token);
  const [presents, setPresents] = useState<ICustomerPresent[]>();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const loadPresents = async (userId: number, cancelToken?: CancelToken) => {
    const customerPresents = await getCustomerPresents(
      userId,
      token!,
      cancelToken,
    );

    setPresents(customerPresents);
  };

  const deletePresent = async function (userPresentId: number) {
    try {
      await removePresent(userPresentId, options.userId!, token!);
      toast.success('Das Geschenk wurde erfolgreich vom Kunden entfernt.');
      await loadPresents(options.userId!);
    } catch (err) {
      toast.error('Beim Löschen des Geschenks ist ein Fehler aufgetreten.');
    }
  };

  useEffect(() => {
    if (!options.userId) return;

    const cancelToken = axios.CancelToken.source();

    loadPresents(options.userId, cancelToken.token);

    return () => {
      cancelToken.cancel('Component unmounted.');
    };
  }, [options.userId]);

  if (!presents) {
    return (
      <Card className={'flex-fill'}>
        <CardHeader>Geschenke</CardHeader>
        <CardBody>
          <Spinner size={'md'} />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <CustomerAddPresentModal
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
        userId={options.userId!}
        onAdded={() => loadPresents(options.userId!)}
      />
      <Card className={'flex-fill'}>
        <CardHeader>
          <div className={'d-flex flex-wrap'}>
            <div>Geschenke</div>
            <div className={'ml-auto'}>
              <a href={'#'} onClick={() => setIsAddModalOpen(true)}>
                Hinzufügen
              </a>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className={'d-flex flex-wrap'}>
            {presents.length <= 0
              ? 'Der Benutzer besitzt keine Geschenke'
              : null}
            {presents.map((present) => (
              <div
                className={'d-flex flex-column mr-2'}
                key={present.UserPresentId}
              >
                <Present presentId={present.PresentId} />
                <div className={'text-center'}>
                  <Button
                    color={'danger'}
                    className={'btn-sm'}
                    onClick={() => deletePresent(present.UserPresentId)}
                  >
                    Entfernen
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
}
