import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

import { cancelAsa, getLastAsaStatus } from '../../services/tools.service';
import { useAppSelector } from '../../store/hooks';
import { IAsaStatus } from '../../models/tools';
import { ConfirmDialog, IConfirmDialogOptions } from '../Common/ConfirmDialog';

export default function LastAsaStatus() {
  const token = useAppSelector((state) => state.Login.token);
  const [lastAsaStatus, setLastAsaStatus] = useState<IAsaStatus[]>([]);

  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogOptions>({
    isOpen: false,
    close: () => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    content: '',
    title: '',
    confirmCallback: async () => {},
  });

  const stopJob = (id: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'ASA Dialoge abbrechen',
      content: 'Wollen Sie diesen Background-Job wirklich abbrechen?',
      confirmCallback: async () => {
        try {
          await cancelAsa(id, token!);

          toast.success(
            'Die ASA-Dialoge werden in kürze abgebrochen! Bitte drücken Sie nicht erneut.',
          );
        } catch (e) {
          toast.error(
            'Beim Versuch die ASA-Dialoge abzubrechen ist ein Fehler aufgetreten. Bitten versuchen Sie es erneut.',
          );
        }
      },
    });
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let interval: NodeJS.Timeout | null = null;

    const loadAsaStatus = async () => {
      try {
        const _status = await getLastAsaStatus(token!, cancelToken.token);

        setLastAsaStatus(_status);
      } catch (e) {
        toast.error(
          'Beim Abruf des ASA-Dialog-Status ist ein Fehler aufgetreten.',
        );

        if (interval) {
          clearInterval(interval);
        }
      }
    };

    loadAsaStatus();

    interval = setInterval(() => loadAsaStatus(), 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      cancelToken.cancel();
    };
  }, [token]);

  return (
    <>
      <Card>
        <CardHeader>Letzten 5 ASA-Dialoge Status</CardHeader>
        <CardBody>
          <div className={'table-responsive'}>
            <table className={'table table-hover'}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Job-ID</th>
                  <th>User (gesamt)</th>
                  <th>User (hinzugefügt)</th>
                  <th>erstellt am</th>
                  <th>fertiggestellt am</th>
                  <th>abgebrochen am</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {lastAsaStatus.map((status) => (
                  <tr key={status.Id}>
                    <td>{status.Id}</td>
                    <td>{status.JobId}</td>
                    <td>{status.TotalUsers}</td>
                    <td>{status.TotalAdded}</td>
                    <td>
                      {moment(status.DateCreate).format('DD.MM.YYYY HH:mm')}
                    </td>
                    <td>
                      {status.DateFinished
                        ? moment(status.DateFinished).format('DD.MM.YYYY HH:mm')
                        : 'n/a'}
                    </td>
                    <td>
                      {status.DateCancelled
                        ? moment(status.DateCancelled).format(
                            'DD.MM.YYYY HH:mm',
                          )
                        : 'n/a'}
                    </td>
                    <td>
                      {!status.DateFinished && !status.DateCancelled ? (
                        <Button
                          color={'danger'}
                          onClick={() => stopJob(status.Id)}
                        >
                          Abbrechen
                        </Button>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        confirmCallback={confirmDialog.confirmCallback}
        content={confirmDialog.content}
        cancelCallback={confirmDialog.cancelCallback}
        title={confirmDialog.title}
        close={confirmDialog.close}
      />
    </>
  );
}
