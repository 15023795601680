import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';

import { IMessage } from '../../../models/message';
import { useAppSelector } from '../../../store/hooks';
import { getMessages } from '../../../services/message.service';
import { INotification } from '../../../models/notification';
import {
  getLatestGreetings,
  getLatestKisses,
} from '../../../services/notification.service';

interface IActiveDialogKissesOptions {
  userId: number | undefined;
  fakeId: number | undefined;
}

export default function ActiveDialogKisses(opt: IActiveDialogKissesOptions) {
  const token = useAppSelector((state) => state.Login.token);

  const [isLoading, setIsLoading] = useState(false);
  const [kisses, setKisses] = useState<INotification[]>([]);

  useEffect(() => {
    let cancel = false;

    if (!opt.userId || !opt.fakeId) {
      setKisses([]);
      return;
    }

    const loadKisses = async () => {
      setIsLoading(true);

      const kisses = await getLatestKisses(opt.userId!, opt.fakeId!, token!);

      if (cancel) return;

      setKisses(kisses);
      setIsLoading(false);
    };

    loadKisses();

    return () => {
      cancel = true;
    };
  }, [opt.userId, opt.fakeId, token]);

  return (
    <LoadingOverlay spinner active={isLoading}>
      <div className={'d-flex flex-column justify-content-stretch mt-2'}>
        {kisses.map((kiss) => (
          <div key={kiss.NotificationId}>
            <div className={'d-flex flex-row mt-2'}>
              <div className={'d-flex flex-column flex-grow-1'}>
                <strong>
                  {kiss.FromUserId === opt.fakeId ? 'Wir' : 'Kunde'}
                </strong>
                <small>{kiss.Message}</small>
              </div>
              <div className={'ml-auto'}>
                <small>
                  {moment(kiss.DateCreate).format('dddd, DD.MMMM YYYY HH:mm')}
                </small>
              </div>
            </div>
            <hr className={'w-100'} />
          </div>
        ))}
      </div>
    </LoadingOverlay>
  );
}
