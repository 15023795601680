import { createPortal } from 'react-dom';
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import LoadingButton from '../Common/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IPresent } from '../../models/Present';
import { addWishPresent, getPresents } from '../../services/present.service';
import PresentPicture from '../Present/PresentPicture';

interface ICustomerAddWishPresentModalOptions {
  userId: number;
  isOpen: boolean;
  toggle: () => void;
  onAdded?: () => void;
}

export default function CustomerAddWishPresentModal(
  opt: ICustomerAddWishPresentModalOptions,
) {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.Login.token);

  const [isLoadingPresents, setIsLoadingPresents] = useState(false);
  const [options, setOptions] = useState<IPresent[]>([]);

  const formik = useFormik({
    initialValues: {
      presentId: -1,
    },
    onReset: (values) => {
      values.presentId = -1;
    },
    validateOnMount: true,
    validate: (values) => {
      const err = {} as any;

      if (values.presentId <= 0) {
        err.presentId = 'Bitte wählen Sie ein Geschenk aus';
      }

      return err;
    },
    onSubmit: async (values) => {
      try {
        await addWishPresent(values.presentId, opt.userId, token!);
        toast.success('Das Geschenk wurde erfolgreich hinzugefügt.');

        if (opt.onAdded) opt.onAdded();

        opt.toggle();
        formik.resetForm();
      } catch (err) {
        toast.error('Fehler beim Hinzufügen des Geschenks.');
      }
    },
  });

  const handleSearch = async (query: string | null) => {
    setIsLoadingPresents(true);

    const presents = await getPresents(0, 50, query, token!);

    setOptions(presents.Data);
    setIsLoadingPresents(false);
  };

  useEffect(() => {
    handleSearch(null);
  }, []);

  return createPortal(
    <Modal
      isOpen={opt.isOpen}
      toggle={() => opt.toggle()}
      centered={true}
      backdrop={true}
    >
      <ModalHeader toggle={() => opt.toggle()}>
        Wunsch-Geschenk hinzufügen
      </ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label>Geschenk</Label>
              <AsyncTypeahead
                isLoading={isLoadingPresents}
                onSearch={handleSearch}
                options={options}
                multiple={false}
                placeholder={'Nach Geschenk suchen ...'}
                filterBy={() => true}
                id={'presentId'}
                onChange={(selected) => {
                  if (selected.length <= 0) return;

                  formik.setFieldValue('presentId', selected[0].PresentId);
                }}
                labelKey={(present) =>
                  `${present.Name} (${present.Costs.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })})`
                }
                onBlur={() => formik.setTouched({ presentId: true })}
                minLength={0}
                isValid={formik.touched.presentId && !formik.errors.presentId}
                isInvalid={
                  !!(formik.touched.presentId && formik.errors.presentId)
                }
                className={
                  formik.errors.presentId && formik.touched.presentId
                    ? 'is-invalid'
                    : ''
                }
                renderMenuItemChildren={(option, props) => (
                  <Fragment>
                    <div className={'d-flex flex-wrap'}>
                      <PresentPicture
                        presentId={option.PresentId}
                        height={20}
                        key={`present-image${option.PresentId}`}
                      />
                      <div className={'ml-2'}>
                        {option.Name} (
                        {option.Costs.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                        )
                      </div>
                    </div>
                  </Fragment>
                )}
              />
              <ErrorMessage
                name={'presentId'}
                className={'invalid-feedback'}
                component={'div'}
              />
            </FormGroup>

            <LoadingButton
              isLoading={formik.isSubmitting}
              caption={'Hinzufügen'}
              isSubmit={true}
              disabled={formik.isSubmitting || !formik.isValid}
            />
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal>,
    document.body,
  );
}
