import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IPagedRequest, IPagedResult } from '../../models';
import { ICoinPackage } from '../../models/coin-package';
import { getCoinPackages } from '../../services/coin-package.service';

import { LOAD_COIN_PACKAGES } from './actionTypes';
import { loadCoinPackagesFailed, setCoinPackages } from './actions';

function* loadCoinPackages(action: IBaseAction<IPagedRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<ICoinPackage> = yield call(
      getCoinPackages,
      action.payload.page,
      action.payload.size,
      token,
    );

    yield put(setCoinPackages(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadCoinPackagesFailed());
  }
}

export function* watchLoadCoinPackages() {
  yield takeEvery<IBaseAction<any>>(LOAD_COIN_PACKAGES, loadCoinPackages);
}

function* coinPackagesSaga() {
  yield all([fork(watchLoadCoinPackages)]);
}

export default coinPackagesSaga;
