import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import PendingProfilePictures from '../../components/Tools/PendingProfilePictures';

export default function PendingProfilePicturesPage() {
  const [breadcrumbItems] = useState([
    { title: 'Tools', link: '#' },
    { title: 'Profilbilder', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Profilbild Freigabe"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-12'}>
              <PendingProfilePictures />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
