import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { IBaseAction } from '../type';
import { getAuthToken } from '../overview/statistic/saga';
import { IPagedRequest, IPagedResult } from '../../models';
import { IPaymentMethod } from '../../models/payment-methods';
import { getPaymentMethods } from '../../services/payment-method.service';

import { LOAD_PAYMENT_METHODS } from './actionTypes';
import { loadPaymentMethodsFailed, setPaymentMethods } from './actions';

function* loadPaymentMethods(action: IBaseAction<IPagedRequest>) {
  try {
    const token: string = yield select(getAuthToken);
    const response: IPagedResult<IPaymentMethod> = yield call(
      getPaymentMethods,
      action.payload.page,
      action.payload.size,
      token,
    );

    yield put(setPaymentMethods(response));
  } catch (error) {
    console.log('error', error);
    yield put(loadPaymentMethodsFailed());
  }
}

export function* watchLoadModerators() {
  yield takeEvery<IBaseAction<any>>(LOAD_PAYMENT_METHODS, loadPaymentMethods);
}

function* paymentMethodsSaga() {
  yield all([fork(watchLoadModerators)]);
}

export default paymentMethodsSaga;
