import { ICustomer } from '../../models/customer';
import { IBaseAction } from '../type';
import { IPagedResult } from '../../models';

import {
  LOAD_CONFIRMED_CUSTOMERS,
  LOAD_CONFIRMED_CUSTOMERS_FAILED,
  LOAD_DELETED_CUSTOMERS,
  LOAD_DELETED_CUSTOMERS_FAILED,
  LOAD_MODERATED_CUSTOMERS,
  LOAD_MODERATED_CUSTOMERS_FAILED,
  LOAD_UNCONFIRMED_CUSTOMERS,
  LOAD_UNCONFIRMED_CUSTOMERS_FAILED,
  SET_CONFIRMED_CUSTOMERS,
  SET_DELETED_CUSTOMERS,
  SET_MODERATED_CUSTOMERS,
  SET_UNCONFIRMED_CUSTOMERS,
} from './actionTypes';

interface ICustomerState {
  confirmedCustomer: IPagedResult<ICustomer>;
  isLoadingConfirmed: boolean;

  unconfirmedCustomer: IPagedResult<ICustomer>;
  isLoadingUnconfirmed: boolean;

  deletedCustomer: IPagedResult<ICustomer>;
  isLoadingDeleted: boolean;

  moderatedCustomer: IPagedResult<ICustomer>;
  isLoadingModerated: boolean;
}

const initialState: ICustomerState = {
  confirmedCustomer: {
    Data: [],
    Total: 0,
  },
  isLoadingConfirmed: false,

  unconfirmedCustomer: {
    Data: [],
    Total: 0,
  },
  isLoadingUnconfirmed: false,

  deletedCustomer: {
    Data: [],
    Total: 0,
  },
  isLoadingDeleted: false,

  moderatedCustomer: {
    Data: [],
    Total: 0,
  },
  isLoadingModerated: false,
};

const Customer = (state = initialState, action: IBaseAction<any>) => {
  switch (action.type) {
    case SET_CONFIRMED_CUSTOMERS:
      state = {
        ...state,
        confirmedCustomer: action.payload,
        isLoadingConfirmed: false,
      };
      break;
    case LOAD_CONFIRMED_CUSTOMERS:
      state = {
        ...state,
        isLoadingConfirmed: true,
      };
      break;
    case LOAD_CONFIRMED_CUSTOMERS_FAILED:
      state = {
        ...state,
        isLoadingConfirmed: false,
      };
      break;

    case SET_UNCONFIRMED_CUSTOMERS:
      state = {
        ...state,
        unconfirmedCustomer: action.payload,
        isLoadingUnconfirmed: false,
      };
      break;
    case LOAD_UNCONFIRMED_CUSTOMERS:
      state = {
        ...state,
        isLoadingUnconfirmed: true,
      };
      break;
    case LOAD_UNCONFIRMED_CUSTOMERS_FAILED:
      state = {
        ...state,
        isLoadingUnconfirmed: false,
      };
      break;

    case SET_DELETED_CUSTOMERS:
      state = {
        ...state,
        deletedCustomer: action.payload,
        isLoadingDeleted: false,
      };
      break;
    case LOAD_DELETED_CUSTOMERS:
      state = {
        ...state,
        isLoadingDeleted: true,
      };
      break;
    case LOAD_DELETED_CUSTOMERS_FAILED:
      state = {
        ...state,
        isLoadingDeleted: false,
      };
      break;
    case LOAD_MODERATED_CUSTOMERS:
      state = {
        ...state,
        isLoadingModerated: true,
      };
      break;
    case LOAD_MODERATED_CUSTOMERS_FAILED:
      state = {
        ...state,
        isLoadingModerated: false,
      };
      break;

    case SET_MODERATED_CUSTOMERS:
      state = {
        ...state,
        moderatedCustomer: action.payload,
        isLoadingModerated: false,
      };
      break;
    default:
      break;
  }

  return state;
};

export default Customer;
