import axios, { CancelToken, CancelTokenSource } from 'axios';

import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IModerator, IModeratorStatistic } from '../models/moderator';
import { IPagedResult } from '../models';
import { IAgency } from '../models/agency';

export async function getInsTodayPerProfile(
  moderatorId: number,
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/moderator/${moderatorId}/${userId}/${fakeId}/ins-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOutsTodayPerProfile(
  moderatorId: number,
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/moderator/${moderatorId}/${userId}/${fakeId}/outs-today`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getInsTotalPerProfile(
  moderatorId: number,
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/moderator/${moderatorId}/${userId}/${fakeId}/ins-total`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getOutsTotalPerProfile(
  moderatorId: number,
  fakeId: number,
  userId: number,
  token: string,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/moderator/${moderatorId}/${userId}/${fakeId}/outs-total`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getModerator(
  moderatorId: number,
  token: string,
): Promise<IModerator> {
  return (
    await axios.get<IModerator>(
      `${environment.API_URL}/moderator/${moderatorId}`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getModeratorStatistic(
  moderatorId: number,
  token: string,
  cancelToken?: CancelTokenSource,
): Promise<IModeratorStatistic> {
  return (
    await axios.get<IModeratorStatistic>(
      `${environment.API_URL}/moderator/${moderatorId}/statistic`,
      getAxiosOptions(token, {
        cancelToken: cancelToken?.token,
      }),
    )
  ).data;
}

export async function getModeratorTodaysStatistic(
  moderatorId: number,
  token: string,
  cancelToken?: CancelTokenSource,
): Promise<IModeratorStatistic> {
  return (
    await axios.get<IModeratorStatistic>(
      `${environment.API_URL}/moderator/${moderatorId}/statistic-today`,
      getAxiosOptions(token, {
        cancelToken: cancelToken?.token,
      }),
    )
  ).data;
}

export async function getTotalFinishedDialogs(
  moderatorId: number,
  token: string,
  cancelToken?: CancelTokenSource,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/moderator/${moderatorId}/total-finished-dialogs`,
      getAxiosOptions(token, {
        cancelToken: cancelToken?.token,
      }),
    )
  ).data;
}

export async function getTotalUnfinishedDialogs(
  moderatorId: number,
  token: string,
  cancelToken?: CancelTokenSource,
): Promise<number> {
  return (
    await axios.get<number>(
      `${environment.API_URL}/moderator/${moderatorId}/total-unfinished-dialogs`,
      getAxiosOptions(token, {
        cancelToken: cancelToken?.token,
      }),
    )
  ).data;
}

export async function getOnlineModerators(
  token: string,
): Promise<IModerator[]> {
  return (
    await axios.get<IModerator[]>(
      `${environment.API_URL}/moderator/online`,
      getAxiosOptions(token),
    )
  ).data;
}

export async function getModerators(
  page: number,
  size: number,
  token: string,
): Promise<IPagedResult<IModerator>> {
  return (
    await axios.get(
      `${environment.API_URL}/moderator/`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
        },
      }),
    )
  ).data;
}

export async function resetModeratorStatistic(
  moderatorId: number,
  token: string,
): Promise<void> {
  await axios.post(
    `${environment.API_URL}/moderator/${moderatorId}/reset`,
    {},
    getAxiosOptions(token),
  );
}

export async function createModerator(
  agencyId: number,
  username: string,
  password: string,
  email: string,
  skype: string | undefined,
  token: string,
): Promise<IModerator> {
  return (
    await axios.post<IModerator>(
      `${environment.API_URL}/moderator`,
      {
        agencyId,
        username,
        password,
        email,
        skype,
        token,
      },
      getAxiosOptions(token),
    )
  ).data;
}

export async function updateModerator(
  moderatorId: number,
  agencyId: number,
  username: string,
  password: string | undefined,
  email: string,
  skype: string | undefined,
  token: string,
): Promise<IModerator> {
  return (
    await axios.put<IModerator>(
      `${environment.API_URL}/moderator/${moderatorId}`,
      {
        agencyId,
        username,
        password,
        email,
        skype,
      },
      getAxiosOptions(token),
    )
  ).data;
}

export async function deleteModerator(
  moderatorId: number,
  token: string,
): Promise<void> {
  await axios.delete(
    `${environment.API_URL}/moderator/${moderatorId}`,
    getAxiosOptions(token),
  );
}
