import axios, { CancelTokenSource } from 'axios';

import { IAgency } from '../models/agency';
import environment from '../helpers/environment';
import getAxiosOptions from '../helpers/axios_helper';
import { IPagedResult } from '../models';

export async function getAgency(
  agencyId: number,
  token: string,
  cancelToken?: CancelTokenSource,
): Promise<IAgency> {
  return (
    await axios.get(
      `${environment.API_URL}/agency/${agencyId}`,
      getAxiosOptions(token, {
        cancelToken: cancelToken?.token,
      }),
    )
  ).data;
}

export async function getAgencies(
  page: number,
  size: number,
  token: string,
): Promise<IPagedResult<IAgency>> {
  return (
    await axios.get(
      `${environment.API_URL}/agency/`,
      getAxiosOptions(token, {
        params: {
          page,
          size,
        },
      }),
    )
  ).data;
}

export async function createAgency(
  name: string,
  minMessageCharCount: number,
  token: string,
): Promise<IAgency> {
  return (
    await axios.post<IAgency>(
      `${environment.API_URL}/agency`,
      {
        name,
        minMessageCharCount,
      },
      getAxiosOptions(token),
    )
  ).data;
}

export async function updateAgency(
  agencyId: number,
  name: string,
  minMessageCharCount: number,
  token: string,
): Promise<IAgency> {
  return (
    await axios.put<IAgency>(
      `${environment.API_URL}/agency/${agencyId}`,
      {
        name,
        minMessageCharCount,
      },
      getAxiosOptions(token),
    )
  ).data;
}

export async function deleteAgency(
  agencyId: number,
  token: string,
): Promise<void> {
  await axios.delete(
    `${environment.API_URL}/agency/${agencyId}`,
    getAxiosOptions(token),
  );
}
