import React, { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';

// Dashboard
import Dashboard from '../pages/Dashboard';
import AnimationDialog from '../pages/Animation/dialog';
import AnimationLive from '../pages/Animation/live';
import AgencyPage from '../pages/Agency';
import ModeratorPage from '../pages/Moderator';
import CustomerPage from '../pages/Customer';
import PresentPage from '../pages/Present';
import SendMessage from '../pages/Message/send_message';
import ToolsPage from '../pages/Tools';
import PaymentMethodsPage from '../pages/PaymentMethods';
import CoinPackagesPage from '../pages/CoinPackages';
import SupportPage from '../pages/Support';
import LatestMessagesPage from '../pages/Animation/latest_messages';

interface IRoute {
  path: string;
  component: ComponentType;
  exact?: boolean;
}

const authProtectedRoutes: IRoute[] = [
  { path: '/dashboard', component: Dashboard },

  { path: '/animation/dialog', component: AnimationDialog },
  { path: '/animation/live', component: AnimationLive },
  { path: '/animation/messages', component: LatestMessagesPage },

  { path: '/agency', component: AgencyPage },
  { path: '/moderators', component: ModeratorPage },
  { path: '/customers', component: CustomerPage },
  { path: '/presents', component: PresentPage },
  { path: '/tools', component: ToolsPage },
  { path: '/payment-methods', component: PaymentMethodsPage },
  { path: '/coin-packages', component: CoinPackagesPage },
  { path: '/support', component: SupportPage },

  {
    path: '/send-message/:receiverUserId?/:senderUserId?',
    component: SendMessage,
  },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes: IRoute[] = [{ path: '/login', component: Login }];

export { authProtectedRoutes, publicRoutes };
