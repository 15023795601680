import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import PendingVoiceDescriptions from '../../components/Tools/PendingVoiceDescriptions';

export default function PendingVoiceDescriptionsPage() {
  const [breadcrumbItems] = useState([
    { title: 'Tools', link: '#' },
    { title: 'ASA', link: '#' },
  ]);

  return (
    <React.Fragment>
      <Row className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Sprachnotizen Freigabe"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <div className={'col-12'}>
              <PendingVoiceDescriptions />
            </div>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}
