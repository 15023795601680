import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';

import CoinPackages from './overview';

export default function CoinPackagesPage() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}`}>
          <CoinPackages />
        </Route>
      </Switch>
    </>
  );
}
